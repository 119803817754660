import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "lodash/fp";
import cn from "classnames";

import DqLoading from "components/common/feedback/DqLoading";
import SEO from "components/marketing/SEO";
import DqButton from "components/common/elements/DqButton";

import {
  selectors as gift_selectors,
  actions as gift_actions,
} from "redux/modules/gift_info";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

export const selectors = {
  ...gift_selectors,
};
export const actions = {
  ...gift_actions,
};

const mapStateToProps = createStructuredSelector(selectors);

export class GiftClaimView extends React.PureComponent {
  static propTypes = {
    // from router
    match: PropTypes.shape({
      params: PropTypes.shape({
        gift_id: PropTypes.string,
      }).isRequired,
    }).isRequired,
    // from gift_info
    claimProcessed: PropTypes.bool.isRequired,
    claimError: PropTypes.bool.isRequired,
    claimErrorMessage: PropTypes.string.isRequired,
    claimGift: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      checking: true,
    };
  }

  componentDidMount() {
    this.props.claimGift(this.props.match.params.gift_id);
  }

  componentDidUpdate() {
    if (this.props.claimProcessed && this.state.checking) {
      this.setState({ checking: false });
    }
  }

  renderError() {
    const commonClasses =
      "dq-standard-error-box dq-animate-appear dq-max-w-lg dq-mt-0 dq-mb-10 dq-mx-auto";
    if (!this.props.claimError) return null;

    if (this.props.claimErrorMessage) {
      return (
        <div className={commonClasses}>{this.props.claimErrorMessage}</div>
      );
    }
    return (
      <div className={commonClasses}>
        Something broke while trying to claim the gift, please contact{" "}
        <DqButton
          href="mailto:hello@dataquest.io"
          text="customer support"
          variant="text"
          disableTitleCase
        />
        .
      </div>
    );
  }

  renderStatus() {
    if (this.props.claimError) return null;
    if (this.state.checking) {
      return <DqLoading message="Claiming your gift" fullScreen />;
    }
    return (
      <div
        className={cn(
          "dq-flex dq-flex-wrap dq-justify-center dq-bg-white dq-w-1/2 dq-mx-auto",
          "dq-border dq-border-solid dq-border-gray-100",
        )}
      >
        <div className="dq-full dq-p-6 dq-text-base dq-text-center dq-text-green">
          <div className="dq-text-base dq-mb-6 dq-mt-2">
            <IoCheckmarkCircleSharp size="48px" />
          </div>
          <div
            className="dq-text-2xl dq-mb-6"
            data-test-selector="success-message"
          >
            Request processed!
          </div>
          <div className="dq-text-base dq-mb-6">
            Your gift has been successfully claimed. We are honored to be part
            of your learning journey.
          </div>
          <div className="dq-text-base dq-mb-12">
            <DqButton text="Go to your dashboard" to="/dashboard" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="dq-pt-10">
        <SEO title="Claim a gift" />
        {this.renderError()}
        {this.renderStatus()}
      </div>
    );
  }
}

const withAll = compose(
  connect(
    mapStateToProps,
    actions,
  ),
);

export default withAll(GiftClaimView);
