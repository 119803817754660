import React, { lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import lazyRetry from "helpers/lazyRetry";
import RedirectWithParams from "./RedirectWithParams";
import { userIsAuthenticated } from "./helpers";

const TeamMembersView = lazy(() =>
  lazyRetry(() => import("views/TeamMembersView"), "TeamMembersView"),
);

const TeamManagementView = lazy(() =>
  lazyRetry(() => import("views/TeamManagementView"), "TeamManagementView"),
);
const TeamReportsView = lazy(() =>
  lazyRetry(() => import("views/TeamReportsView"), "TeamReportsView"),
);
const TeamInvitationsView = lazy(() =>
  lazyRetry(() => import("views/TeamInvitationsView"), "TeamInvitationsView"),
);
const TeamAcceptInvitationView = lazy(() =>
  lazyRetry(
    () => import("views/TeamAcceptInvitationView"),
    "TeamAcceptInvitationView",
  ),
);
const TeamSettingsView = lazy(() =>
  lazyRetry(() => import("views/TeamSettingsView"), "TeamSettingsView"),
);

export default ({ featureFlags = {} }) => (
  <Switch>
    <RedirectWithParams from="/teams/:page?" to="/team/:page?" />
    <RedirectWithParams
      from="/team/accept_invitation/:team_id?"
      to="/team/accept-invitation/:team_id?"
    />

    <Route path="/team">
      <Switch>
        <Redirect from="/teams/*/billing" to="/team/billing" />
        <Redirect from="/team/billing" to="/account/current-plan" />
        <Route
          path="/team/accept-invitation/:team_id?"
          component={userIsAuthenticated(TeamAcceptInvitationView)}
        />
        <Route
          path="/team/members"
          component={userIsAuthenticated(
            featureFlags?.newTeamAdmin ? TeamManagementView : TeamMembersView,
          )}
        />
        <Route
          path="/team/reports"
          component={userIsAuthenticated(TeamReportsView)}
        />
        <Route
          path="/team/invitations"
          component={userIsAuthenticated(TeamInvitationsView)}
        />
        <Route
          path="/team/settings"
          component={userIsAuthenticated(TeamSettingsView)}
        />
        <Redirect from="/team/api" to="/team/settings" />
        <Redirect from="*" to="/team/members" />
      </Switch>
    </Route>
  </Switch>
);
