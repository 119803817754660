/* eslint-disable global-require */
import Immutable from "seamless-immutable";
import { createSelector } from "reselect";
import { make_simple_selectors, make_reducer_n_actions } from "redux_helpers";
import sortBy from "lodash/sortBy";

// -------
// Initial State
// --------

export const initial_state = {
  // NEED to also update list in
  //   https://github.com/dataquestio/infrastructure/blob/master/cloud-functions/sitemap-generator/src/story_slugs.js
  signupProof: {
    "aaron-melton": {
      name: "Aaron Melton",
      title: "Business Analyst at Aditi Consulting",
      quote:
        "Dataquest starts at the most basic level, so a beginner can understand the concepts. I tried learning to code before, using Codecademy and Coursera. I struggled because I had no background in coding, and I was spending a lot of time Googling. Dataquest helped me actually learn.",
      img_name: require("images/user_stories/aaron3.jpg"),
    },
    "jessica-ko": {
      name: "Jessica Ko",
      title: "Machine Learning Engineer at Twitter",
      quote:
        "I liked the interactive environment on Dataquest. The material was clear and well organized. I spent more time practicing then watching videos and it made me want to keep learning.",
      img_name: require("images/user_stories/jessica.jpg"),
    },
    "victoria-guzik": {
      name: "Victoria E. Guzik",
      title: "Associate Data Scientist at Callisto Media",
      quote:
        "I really love learning on Dataquest. I looked into a couple of other options and I found that they were much too handhold-y and fill in the blank relative to Dataquest’s method. The projects on Dataquest were key to getting my job. I doubled my income!",
      img_name: require("images/user_stories/victoria.jpg"),
    },
  },
  storiesProof: {
    "wes-brooks": {
      name: "Wes Brooks",
      slug: "wes-brooks",
      title: "Data Scientist",
      company: "",
      location: "Lexington, USA",
      blurb:
        "Dataquest taught me everything I needed to know without the $50,000 degree.",
      img_name: require("images/user_stories/wes.jpg"),
      story_image: require("images/user_stories/wes2.jpg"),
      head_image: require("images/user_stories/wes_head.jpg"),
      html: "",
      map_x: "16.30%",
      map_y: "30.81%",
      map_size: 3,
      cta_line: "Want to start your new career in data science?",
      date_added: "Thu Jul 20 13:28:25 2017",
    },
    "yassine-alouini": {
      name: "Yassine Alouini",
      slug: "yassine-alouini",
      title: "Data Scientist at Qucit",
      company: "",
      location: "Bordeaux, France",
      blurb: "Dataquest took me from Zero to Data Analyst.",
      img_name: require("images/user_stories/yassine.jpg"),
      story_image: require("images/user_stories/yassine2.jpg"),
      head_image: require("images/user_stories/yassine_head.jpg"),
      html: "",
      map_x: "45.05%",
      map_y: "23.3%",
      map_size: 4,
      cta_line: "Want to maintain your Data Science skills?",
      date_added: "Mon Sep 12 11:02:44 2016",
    },
    "prerit-anwekar": {
      name: "Prerit Anwekar",
      slug: "prerit-anwekar",
      title: "Data Scientist",
      company: "",
      location: "Madison, USA",
      blurb:
        "Dataquest helped me transition from a software developer to a data scientist.",
      img_name: require("images/user_stories/prerit.jpg"),
      story_image: require("images/user_stories/prerit2.jpg"),
      head_image: require("images/user_stories/prerit_head.jpg"),
      html: "",
      map_x: "16.30%",
      map_y: "24.7%",
      map_size: 3,
      cta_line: "Want to become a data scientist?",
      date_added: "Tue Jul 25 08:26:07 2017",
    },
    "harry-robinson": {
      name: "Harry Robinson",
      slug: "harry-robinson",
      title: "Data Strategist",
      company: "",
      location: "London, UK",
      blurb: "Dataquest helped me start my career in data.",
      img_name: require("images/user_stories/harry.jpg"),
      story_image: require("images/user_stories/harry2.jpg"),
      head_image: require("images/user_stories/harry_head.jpg"),
      html: "",
      map_x: "45.05%",
      map_y: "20.30%",
      map_size: 4,
      cta_line: "Want to start your career in data?",
      date_added: "Tue Jul 11 16:41:33 2017",
    },
    "luiz-zanini": {
      name: "Luiz Zanini",
      slug: "luiz-zanini",
      title: "Data Scientist",
      company: "About Fun",
      location: "Prague, CZ",
      blurb: "Dataquest helped me learn on my own schedule",
      img_name: require("images/user_stories/luiz.jpg"),
      story_image: require("images/user_stories/luiz2.jpg"),
      head_image: require("images/user_stories/luiz_head.jpg"),
      html: "",
      map_x: "48%",
      map_y: "21%",
      map_size: 3,
      cta_line: "Want to change your career?",
      date_added: "Wed Jan 10 16:21:21 2018",
    },
    "aaron-melton": {
      name: "Aaron Melton",
      slug: "aaron-melton",
      title: "Business Intelligence Analyst",
      company: "VBO",
      location: "Columbia, SC, USA",
      blurb: "Dataquest broke things down in ways I could understand.",
      img_name: require("images/user_stories/aaron.jpg"),
      story_image: require("images/user_stories/aaron2.jpg"),
      head_image: require("images/user_stories/aaron_head.jpg"),
      html: "",
      map_x: "18%",
      map_y: "33.5%",
      map_size: 3,
      cta_line: "Grow the skills you need for a new career.",
      date_added: "Mon Feb 5 17:17:38 2018",
    },
  },
  stories: {
    "eric-salesdeandrade": {
      name: "Eric",
      slug: "eric-salesdeandrade",
      title: "Data Scientist and Engineer",
      company: "Intelematics",
      location: "London, UK",
      blurb: "I wanted something practical.",
      img_name: require("images/user_stories/eric.jpg"),
      story_image: require("images/user_stories/eric2.jpg"),
      head_image: require("images/user_stories/eric_head.jpg"),
      html: "",
      map_x: "46%",
      map_y: "20.30%",
      map_size: 4,
      cta_line: "Want to transform your career?",
      date_added: "Wed Apr 25 2018 12:00:46",
    },
    "christian-lheureux": {
      name: "Christian",
      slug: "christian-lheureux",
      title: "Analyst",
      company: "Blizzard",
      location: "Irvine, CA",
      blurb: "Consistency is key.",
      img_name: require("images/user_stories/christian.jpg"),
      story_image: require("images/user_stories/christian2.jpg"),
      head_image: require("images/user_stories/christian_head.jpg"),
      html: "",
      map_x: "6.5%",
      map_y: "37%",
      map_size: 3,
      cta_line: "Grow your skills",
      date_added: "Wed Apr 25 2018 12:00:46",
    },
    "mike-roberts": {
      name: "Mike",
      slug: "mike-roberts",
      title: "Data Engineer",
      company: "",
      location: "Bristol, UK",
      blurb: "I wanted to grow my skills.",
      img_name: require("images/user_stories/mike.jpg"),
      story_image: require("images/user_stories/mike2.jpg"),
      head_image: require("images/user_stories/mike_head.jpg"),
      html: "",
      map_x: "44%",
      map_y: "20%",
      map_size: 3,
      cta_line: "Begin a new career.",
      date_added: "Wed Apr 25 2018 12:00:46",
    },
    "wes-brooks": {
      name: "Wes",
      slug: "wes-brooks",
      title: "Data Scientist",
      company: "",
      location: "Lexington, USA",
      blurb:
        "Dataquest taught me everything I needed to know without the $50,000 degree.",
      img_name: require("images/user_stories/wes.jpg"),
      story_image: require("images/user_stories/wes2.jpg"),
      head_image: require("images/user_stories/wes_head.jpg"),
      html: "",
      map_x: "16.30%",
      map_y: "30.81%",
      map_size: 3,
      cta_line: "Want to start your new career in data science?",
      date_added: "Thu Jul 20 13:28:25 2017",
    },
    "franco-sosa": {
      name: "Franco",
      slug: "franco-sosa",
      title: "Data Scientist",
      company: "",
      location: "Guatemala City, Guatemala",
      blurb: "Dataquest helped me become a Data Scientist.",
      img_name: require("images/user_stories/franco.jpg"),
      story_image: require("images/user_stories/franco2.jpg"),
      head_image: require("images/user_stories/franco_head.jpg"),
      html: "",
      map_x: "13.30%",
      map_y: "47.30%",
      map_size: 3,
      cta_line: "Want to take your career to the next level?",
      date_added: "Wed Sep 14 08:14:44 2016",
    },
    "patrick-kennedy": {
      name: "Patrick",
      slug: "patrick-kennedy",
      title: "Data Scientist",
      company: "",
      location: "Austin, USA",
      blurb:
        "I used Dataquest to leave my career in real estate and break into Data Science.",
      img_name: require("images/user_stories/patrick.jpg"),
      story_image: require("images/user_stories/patrick2.jpg"),
      head_image: require("images/user_stories/patrick_head.jpg"),
      html: "",
      map_x: "11.75%",
      map_y: "35.3%",
      map_size: 3,
      cta_line: "Want to break into data science?",
      date_added: "Mon Jul 11 17:12:30 2016",
    },
    "yassine-alouini": {
      name: "Yassine",
      slug: "yassine-alouini",
      title: "Data Scientist at Qucit",
      company: "",
      location: "Bordeaux, France",
      blurb: "Dataquest helps me keep my Data Science skills sharp.",
      img_name: require("images/user_stories/yassine.jpg"),
      story_image: require("images/user_stories/yassine2.jpg"),
      head_image: require("images/user_stories/yassine_head.jpg"),
      html: "",
      map_x: "45.05%",
      map_y: "23.3%",
      map_size: 4,
      cta_line: "Want to maintain your Data Science skills?",
      date_added: "Mon Sep 12 11:02:44 2016",
    },
    "vicknesh-mano": {
      name: "Vicknesh",
      slug: "vicknesh-mano",
      title: "Data Scientist",
      company: "Accenture",
      location: "Singapore",
      blurb:
        "Dataquest helped me transition from a Science Teacher to a Data Scientist.",
      img_name: require("images/user_stories/vicknesh.jpg"),
      story_image: require("images/user_stories/vicknesh2.jpg"),
      head_image: require("images/user_stories/vicknesh_head.jpg"),
      html: "",
      map_x: "79.90%",
      map_y: "57.80%",
      map_size: 4,
      cta_line: "Want to become a data scientist?",
      date_added: "Wed Dec 28 12:22:42 2016",
    },
    "dong-zhou": {
      name: "Dong",
      slug: "dong-zhou",
      title: "Scientific Software Developer",
      company: "",
      location: "New York, USA",
      blurb: "Dataquest helped me get a job I love.",
      img_name: require("images/user_stories/dong.jpg"),
      story_image: require("images/user_stories/dong2.jpg"),
      head_image: require("images/user_stories/dong_head.jpg"),
      html: "",
      map_x: "20.80%",
      map_y: "27.80%",
      map_size: 4,
      cta_line: "Want to get a job you love?",
      date_added: "Tue Dec 20 16:28:21 2016",
    },
    "jennifer-thomas": {
      name: "Jennifer",
      slug: "jennifer-thomas",
      title: "Chief of Staff",
      company: "Citibank",
      location: "New York, USA",
      blurb: "I used Dataquest to help me manage Data Science Projects.",
      img_name: require("images/user_stories/jennifer.jpg"),
      story_image: require("images/user_stories/jennifer2.jpg"),
      head_image: require("images/user_stories/jennifer_head.jpg"),
      html: "",
      map_x: "20.80%",
      map_y: "27.80%",
      map_size: 4,
      cta_line: "Do you need to understand data to do your job?",
      date_added: "Tue Jul 25 16:32:25 2017",
    },
    "kyle-stewart": {
      name: "Kyle",
      slug: "kyle-stewart",
      title: "Business Systems Analyst",
      company: "SpaceX",
      location: "Los Angeles, USA",
      blurb: "Dataquest helped me get into the tech industry.",
      img_name: require("images/user_stories/kyle.jpg"),
      story_image: require("images/user_stories/kyle2.jpg"),
      head_image: require("images/user_stories/kyle_head.jpg"),
      html: "",
      map_x: "6.48%",
      map_y: "35.3%",
      map_size: 4,
      cta_line: "Want to transform your career?",
      date_added: "Sun Apr 09 11:05:27 2017",
    },
    "prerit-anwekar": {
      name: "Prerit",
      slug: "prerit-anwekar",
      title: "Data Scientist",
      company: "",
      location: "Madison, USA",
      blurb:
        "Dataquest helped me transition from a software developer to a data scientist.",
      img_name: require("images/user_stories/prerit.jpg"),
      story_image: require("images/user_stories/prerit2.jpg"),
      head_image: require("images/user_stories/prerit_head.jpg"),
      html: "",
      map_x: "16.30%",
      map_y: "24.7%",
      map_size: 3,
      cta_line: "Want to become a data scientist?",
      date_added: "Tue Jul 25 08:26:07 2017",
    },
    "harry-robinson": {
      name: "Harry",
      slug: "harry-robinson",
      title: "Data Strategist",
      company: "",
      location: "London, UK",
      blurb: "Dataquest helped me start my career in data.",
      img_name: require("images/user_stories/harry.jpg"),
      story_image: require("images/user_stories/harry2.jpg"),
      head_image: require("images/user_stories/harry_head.jpg"),
      html: "",
      map_x: "45.05%",
      map_y: "20.30%",
      map_size: 4,
      cta_line: "Want to start your career in data?",
      date_added: "Tue Jul 11 16:41:33 2017",
    },
    "luiz-zanini": {
      name: "Luiz",
      slug: "luiz-zanini",
      title: "Data Scientist",
      company: "About Fun",
      location: "Prague, CZ",
      blurb: "Dataquest helped me learn on my own schedule",
      img_name: require("images/user_stories/luiz.jpg"),
      story_image: require("images/user_stories/luiz2.jpg"),
      head_image: require("images/user_stories/luiz_head.jpg"),
      html: "",
      map_x: "48%",
      map_y: "21%",
      map_size: 3,
      cta_line: "Want to change your career?",
      date_added: "Wed Jan 10 16:21:21 2018",
    },
    "priya-iyer": {
      name: "Priya",
      slug: "priya-iyer",
      title: "Senior Analytics Lead",
      company: "Genentech",
      location: "San Francisco, USA",
      blurb: "Dataquest helped me help others.",
      img_name: require("images/user_stories/priya.jpg"),
      story_image: require("images/user_stories/priya2.jpg"),
      head_image: require("images/user_stories/priya_head.jpg"),
      html: "",
      map_x: "5.70%",
      map_y: "29.25%",
      map_size: 4,
      cta_line: "Want to change lives with data science?",
      date_added: "Wed Jan 10 16:21:21 2018",
    },
    "aaron-melton": {
      name: "Aaron",
      slug: "aaron-melton",
      title: "Business Intelligence Analyst",
      company: "VBO",
      location: "Columbia, SC, USA",
      blurb: "Dataquest broke things down in ways I could understand.",
      img_name: require("images/user_stories/aaron.jpg"),
      story_image: require("images/user_stories/aaron2.jpg"),
      head_image: require("images/user_stories/aaron_head.jpg"),
      html: "",
      map_x: "18%",
      map_y: "33.5%",
      map_size: 3,
      cta_line: "Grow the skills you need for a new career.",
      date_added: "Mon Feb 5 17:17:38 2018",
    },
  },
  user_concentrations: [
    { size: 4, x: "71.55%", y: "50.30%" },
    { size: 4, x: "82.90%", y: "27.75%" },
    { size: 4, x: "85.10%", y: "35.40%" },
    { size: 2, x: "17.11%", y: "26.22%" },
    { size: 1, x: "17.85%", y: "27.70%" },
    { size: 1, x: "18.59%", y: "24.70%" },
    { size: 1, x: "15.0%", y: "24.70%" },
    { size: 1, x: "14.83%", y: "23.32%" },
    { size: 2, x: "19.34%", y: "23.32%" },
    { size: 1, x: "22.36%", y: "26.20%" },
    { size: 1, x: "23.00%", y: "195.08%" },
    { size: 1, x: "18.60%", y: "30.80%" },
    { size: 1, x: "17.86%", y: "32.42%" },
    { size: 1, x: "17.30%", y: "38.40%" },
    { size: 1, x: "10.25%", y: "38.43%" },
    { size: 1, x: "9.50%", y: "41.20%" },
    { size: 2, x: "10.26%", y: "44.30%" },
    { size: 1, x: "12.58%", y: "29.25%" },
    { size: 1, x: "11.05%", y: "27.7%" },
    { size: 1, x: "9.50%", y: "21.69%" },
    { size: 1, x: "10.29%", y: "18.70%" },
    { size: 1, x: "21.60%", y: "83.20%" },
    { size: 2, x: "26.89%", y: "83.20%" },
    { size: 2, x: "30.72%", y: "75.70%" },
    { size: 3, x: "28.42%", y: "77.05%" },
    { size: 1, x: "97.05%", y: "91.80%" },
    { size: 1, x: "99.81%", y: "87.70%" },
    { size: 2, x: "95.08%", y: "84.73%" },
    { size: 1, x: "96.57%", y: "80.20%" },
    { size: 2, x: "92.01%", y: "87.76%" },
    { size: 1, x: "90.49%", y: "83.10%" },
    { size: 1, x: "83.62%", y: "83.10%" },
    { size: 1, x: "86.70%", y: "47.25%" },
    { size: 1, x: "79.10%", y: "48.81%" },
    { size: 2, x: "82.18%", y: "50.24%" },
    { size: 1, x: "80.65%", y: "42.70%" },
    { size: 3, x: "82.90%", y: "42.70%" },
    { size: 1, x: "79.10%", y: "35.34%" },
    { size: 1, x: "80.63%", y: "32.25%" },
    { size: 2, x: "90.46%", y: "32.25%" },
    { size: 1, x: "86.70%", y: "30.88%" },
    { size: 1, x: "86.18%", y: "41.40%" },
    { size: 1, x: "81.40%", y: "62.15%" },
    { size: 1, x: "75.30%", y: "41.36%" },
    { size: 2, x: "71.53%", y: "44.30%" },
    { size: 3, x: "71.53%", y: "39.80%" },
    { size: 3, x: "69.30%", y: "42.80%" },
    { size: 3, x: "68.49%", y: "35.25%" },
    { size: 2, x: "73.03%", y: "53.30%" },
    { size: 1, x: "73.81%", y: "18.70%" },
    { size: 1, x: "60.95%", y: "41.25%" },
    { size: 2, x: "63.19%", y: "30.80%" },
    { size: 1, x: "55.65%", y: "27.70%" },
    { size: 1, x: "53.35%", y: "24.73%" },
    { size: 1, x: "51.88%", y: "29.14%" },
    { size: 1, x: "51.10%", y: "84.80%" },
    { size: 2, x: "54.13%", y: "78.80%" },
    { size: 2, x: "45.05%", y: "53.20%" },
    { size: 1, x: "47.30%", y: "51.70%" },
    { size: 2, x: "57.18%", y: "59.24%" },
    { size: 2, x: "54.90%", y: "38.21%" },
    { size: 3, x: "60.21%", y: "17.30%" },
    { size: 1, x: "52.60%", y: "14.30%" },
    { size: 1, x: "50.39%", y: "14.30%" },
    { size: 1, x: "48.10%", y: "14.30%" },
    { size: 1, x: "42.75%", y: "20.25%" },
    { size: 3, x: "46.50%", y: "20.28%" },
    { size: 1, x: "44.33%", y: "17.28%" },
    { size: 1, x: "48.83%", y: "18.70%" },
    { size: 1, x: "51.90%", y: "21.67%" },
    { size: 2, x: "49.61%", y: "21.68%" },
    { size: 2, x: "48.05%", y: "23.20%" },
    { size: 1, x: "48.85%", y: "24.73%" },
    { size: 2, x: "46.54%", y: "24.79%" },
    { size: 1, x: "49.63%", y: "27.80%" },
    { size: 1, x: "43.55%", y: "27.70%" },
  ],
};

// -------
// Selectors
// --------
const BASE = "user_story_info";
export { BASE as BASE_SELECTOR_PATH };

const simple_selectors = make_simple_selectors(initial_state, BASE);
const shorts = createSelector(
  simple_selectors.stories,
  stories =>
    sortBy(stories, ({ date_added }) => new Date(date_added).getTime() * -1),
);

export const selectors = {
  ...simple_selectors,
  shorts,
};

// ------------------------------------
// Reducer and Actions
// ------------------------------------
const action_types_prefix = "user_story_info/";

const public_handlers = {};

const private_handlers = {
  set_story: (state, { payload }) =>
    state.merge({ stories: payload }, { deep: true }),
};

export const {
  reducer,
  private_actions,
  actions,
  ACTION_TYPES,
} = make_reducer_n_actions({
  public_handlers,
  private_handlers,
  action_types_prefix,
  initial_state,
  Immutable,
});
export default reducer;
