/**
 * list of events to record in this format:
 *  format white_list[action.type] = {
 *    send_check: (getState, action) => true/false
 *    event: 'string' OR (getState, action) => '',
 *    custom_properties: (getState, action) => {}, // of properties OR just action will be passed
 *  }
 *  }
 */
import get from "lodash/get";
import dayjs from "dayjs";
import totalPrice from "helpers/price_helpers";
import { DEFAULT_CURRENCY } from "helpers/constants";

let has_sent_signup = false;
export const clear_after_tests = () => {
  // eslint-disable-next-line no-unused-vars
  has_sent_signup = false;
};

const get_utm_codes = getState => get(getState(), "utm_codes.codes", {});

const add_label_for_ga = properties => {
  const label = JSON.stringify(properties);
  return {
    label,
    ...properties,
  };
};

export default {
  "onboarding_info/onboarding_complete": {
    event: "onboarding-complete",
    custom_properties: getState => add_label_for_ga(get_utm_codes(getState)),
  },
  "user_info/set_info": [
    {
      send_check: (getState, { payload: { is_new_user } }) => !is_new_user,
      event: () => "accounts-logged-in",
      custom_properties: (getState, _action) =>
        add_label_for_ga({
          plan: getState().user_info.plan_name,
          is_yearly: getState().user_info.plan_is_yearly,
          createdAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          ...get_utm_codes(getState),
        }),
    },
    {
      send_check: (getState, { payload: { is_new_user } }) => is_new_user,
      event: () => "Signup",
      custom_properties: (getState, _action) =>
        add_label_for_ga({
          createdAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          ...get_utm_codes(getState),
        }),
    },
  ],
  "payment_info/setSuccess": [
    {
      event: () => "user-subscription",
      custom_properties: (getState, action) =>
        add_label_for_ga({
          plan: getState().plan_info.selected_plan,
          period: getState().plan_info.selectedPeriod,
          is_yearly: getState().plan_info.is_yearly,
          discount_code: get(getState().plan_info, "discount_state.code", ""),
          new: get(action, "payload.changed", false) !== true,
          ...get_utm_codes(getState),
        }),
    },
    {
      event: "Order Completed",
      send_check: (getState, { payload }) => get(payload, "order_id", false),
      custom_properties: (getState, { payload }) => {
        const { selected_plan, selectedPeriod } = getState().plan_info;
        const selectedPlan = getState().plan_info.plans[selected_plan];
        let total = selectedPlan[selectedPeriod].discounted;
        let discount = selectedPlan[selectedPeriod].by_month - total;

        if (getState().plan_info.is_yearly) {
          total = totalPrice(total);
          discount = totalPrice(discount);
        }
        const coupon = discount
          ? get(getState().plan_info, "discount_state.code", "")
          : "";

        return {
          checkout_id: payload.order_id,
          order_id: payload.order_id,
          affiliation: "dataquest",
          total,
          discount,
          coupon,
          currency: DEFAULT_CURRENCY,
          products: [
            {
              name: `${selectedPlan.plan_name} ${selectedPeriod}`,
              price: total,
              coupon,
              quantity: 1,
            },
          ],
        };
      },
    },
  ],
};
