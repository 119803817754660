import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import DqButton from "components/common/elements/DqButton";
import makeLessonUrl from "helpers/make_lesson_url";

import {
  selectors as catalog_selectors,
  actions as catalog_actions,
} from "redux/modules/catalog_info";

import { selectors as user_selectors } from "redux/modules/user_info";

export const selectors = {
  currentLesson: catalog_selectors.currentLesson,
  user_id: user_selectors.user_id,
};
export const actions = {
  fetchCurrentLesson: () => catalog_actions.fetchCurrentLesson(), // for mocking in stories
};

const mapStateToProps = createStructuredSelector(selectors);

export class ContinueLessonButton extends React.Component {
  static propTypes = {
    slugs: PropTypes.shape({
      course_slug: PropTypes.string,
      path_slug: PropTypes.string,
    }),
    currentLesson: PropTypes.shape({
      title: PropTypes.string,
      sequence: PropTypes.string,
    }).isRequired,
    fetchCurrentLesson: PropTypes.func.isRequired,
    color: PropTypes.string,
    user_id: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    color: "blue",
    slugs: {},
  };

  componentDidMount() {
    this.props.fetchCurrentLesson(this.props.slugs);
  }

  goToLesson = () => {
    const url = makeLessonUrl(this.props.currentLesson);
    this.props.history.push(url);
  };

  render() {
    const { currentLesson, ...pass } = this.props; // eslint-disable-line no-unused-vars
    if (!this.props.currentLesson.title) return null;

    return (
      <DqButton
        text={`Continue ${currentLesson.title} Lesson`}
        onClick={this.goToLesson}
        {...pass}
      />
    );
  }
}
export default withRouter(
  connect(
    mapStateToProps,
    actions,
  )(ContinueLessonButton),
);
