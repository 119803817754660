// @flow
import type { AbstractComponent, Node } from "react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import DqFooter from "components/unique/DqFooter";
import { selectors as userSelectors } from "redux/modules/user_info";
import { selectors as paymentSelectors } from "redux/modules/payment_info";
import { SuccessStep } from "./SuccessStep";

const selectors = {
  subscriptionId: paymentSelectors.subscriptionId,
  verifiedEmail: userSelectors.verified_email,
};

const mapStateToProps = createStructuredSelector(selectors);

type Props = {
  subscriptionId: string,
  verifiedEmail: boolean,
};

// TODO extract layout shell for PaymentView and PaymentSuccess
const PaymentSuccess = ({ subscriptionId, verifiedEmail }: Props): Node => {
  const [rfsnTracked, setRfsnTracked] = useState(false);

  useEffect(() => {
    if (window._rfsn_ready && subscriptionId && !rfsnTracked) {
      setRfsnTracked(true);
      window._rfsn._setSource("CHARGEBEE");
      window._refersion(() => {
        window._rfsn._addCart(subscriptionId);
      });
    }
  }, [rfsnTracked, subscriptionId]);

  return (
    <div
      className="dq-bg-white dq-flex dq-flex-col"
      data-test-selector="PaymentSuccess"
    >
      <SuccessStep verified_email={verifiedEmail} />
      <div className="dq-mt-auto">
        <DqFooter />
      </div>
    </div>
  );
};

export default (connect(
  mapStateToProps,
  null,
)(PaymentSuccess): AbstractComponent<Props>);
