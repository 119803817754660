// @flow
import type { Node } from "react";
import type { dqBackdropT } from "components/common/overlays/DqBackdrop";
import React, { useState } from "react";
import cn from "classnames";
import {
  IoWarningSharp,
  IoInformationCircleSharp,
  IoCheckmarkCircleSharp,
} from "react-icons/io5";
import DqButton from "components/common/elements/DqButton";
import DqInput from "components/common/forms/DqInput";
import DqDialog from "./DqDialog";

type DqAlertDialogProps = {
  /**
   * Customizes cancel button text
   */
  actionsCancelText?: string,
  /**
   * Customizes confirm button text
   */
  actionsConfirmText?: string,
  /**
   * Hide Cancel button
   */
  hideCancelBtn?: boolean,
  /**
   * Whether to ask to type confirmation to activate action button
   */
  askConfirmation?: boolean,
  /**
   * Confirmation text to type
   */
  confirmationText?: string,
  /**
   * Sets the maximum width of the dialog window
   */
  maxWidth?: "sm" | "md" | "lg" | "xl" | "2xl",
  /**
   * Add a message text
   */
  message?: string,
  /**
   * Add a title text
   */
  title: string,
  /**
   * Toggle component that controls wheter the dialog is visible or not
   */
  toggleEl?: any,
  /**
   * Sets the maximum width of the dialog window
   */
  type?: "error" | "warning" | "info" | "success",
  /**
   * DqBackdrop properties
   */
  backdropProps?: dqBackdropT,
  /**
   * Function to execute when the dialog opens
   */
  handleOnOpen?: any => void,
  /**
   * Function to execute when closing the dialog clicking the backdrop or
   * the cancel button if handleOnCancel is not available
   */
  handleOnClose?: any => void,
  /**
   * Function to execute when closing the dialog clicking the cancel button
   */
  handleOnCancel?: any => void,
  /**
   * Function to execute when clicking the save button
   */
  handleOnConfirm: any => void,
  /**
   * Optional children to allow custom content
   */
  children?: any,
  /**
   * Optional show property to override interal show/hide logic
   */
  show?: boolean,
  /**
   * Optional data test ID property
   */
  dataTestSelector?: string,
};

// eslint-disable-next-line complexity
const DqAlertDialog = ({
  actionsCancelText,
  actionsConfirmText,
  hideCancelBtn,
  children,
  message,
  maxWidth = "md",
  confirmationText,
  askConfirmation,
  title,
  toggleEl,
  type = "error",
  backdropProps,
  handleOnCancel,
  handleOnOpen,
  handleOnClose,
  handleOnConfirm,
  show = undefined,
  dataTestSelector = undefined,
}: DqAlertDialogProps): Node => {
  const [showAlertDialog, setShowAlertDialog] = useState(show || !toggleEl);
  const [confirmationTextValue, setConfirmationTextValue] = useState("");
  const iconBgClasses = {
    error: "dq-bg-red-100",
    warning: "dq-bg-orange-100",
    info: "dq-bg-blue/30",
    success: "dq-bg-green-100",
  };
  const iconClasses = {
    error: "dq-text-red",
    warning: "dq-text-orange-300",
    info: "dq-text-blue",
    success: "dq-text-green",
  };
  const icon = {
    error: (
      <IoWarningSharp className={cn("dq-h-6 dq-w-6", iconClasses[type])} />
    ),
    warning: (
      <IoWarningSharp className={cn("dq-h-6 dq-w-6", iconClasses[type])} />
    ),
    info: (
      <IoInformationCircleSharp
        className={cn("dq-h-6 dq-w-6", iconClasses[type])}
      />
    ),
    success: (
      <IoCheckmarkCircleSharp
        className={cn("dq-h-6 dq-w-6", iconClasses[type])}
      />
    ),
  };

  const handleAlertOnClose = e => {
    if (e) e.stopPropagation();
    setShowAlertDialog(false);
    setConfirmationTextValue("");
    if (handleOnClose) {
      handleOnClose();
    }
  };

  const handleAlertOnCancel = e => {
    if (e) e.stopPropagation();
    setShowAlertDialog(false);
    setConfirmationTextValue("");
    if (handleOnCancel) {
      handleOnCancel();
    }
  };

  const handleAlertOnConfirm = e => {
    if (e) e.stopPropagation();
    setShowAlertDialog(false);
    setConfirmationTextValue("");
    handleOnConfirm();
  };

  const handleToggleOnClick = e => {
    if (e) e.stopPropagation();
    setShowAlertDialog(true);
  };

  return (
    <>
      {toggleEl &&
        React.cloneElement(toggleEl, { onClick: handleToggleOnClick })}

      {((show === undefined && showAlertDialog) || show) && (
        <DqDialog
          maxWidth={maxWidth}
          handleOnClose={handleAlertOnClose}
          handleOnOpen={handleOnOpen}
          backdropProps={backdropProps}
        >
          <div className="dq-px-4 dq-pt-5 dq-pb-4 sm:dq-p-6 sm:dq-pb-4">
            <div className="sm:dq-flex sm:dq-items-start">
              <div
                className={cn(
                  "dq-mx-auto dq-shrink-0 dq-flex dq-items-center dq-justify-center dq-h-12 dq-w-12 dq-rounded-full sm:dq-mx-0 sm:dq-h-10 sm:dq-w-10",
                  iconBgClasses[type],
                )}
              >
                {icon[type]}
              </div>
              <div className="dq-mt-3 dq-text-center sm:dq-mt-0 sm:dq-ml-4 sm:dq-text-left">
                <h3 className="dq-text-lg dq-leading-6" id="dialog-title">
                  {title}
                </h3>
                {(message || children) && (
                  <div className="dq-mt-2">
                    <p className="dq-text-gray-400">{message}</p>
                    {children}
                  </div>
                )}

                {askConfirmation && (
                  <DqInput
                    placeholder={confirmationText}
                    className="dq-w-full !dq-font-normal dq-my-4"
                    value={confirmationTextValue}
                    onChange={e => setConfirmationTextValue(e.target.value)}
                    id="search"
                  />
                )}
              </div>
            </div>

            <div className="dq-mt-5 sm:dq-mt-4 sm:dq-flex sm:dq-flex-row-reverse">
              {!hideCancelBtn && (
                <DqButton
                  variant="outline"
                  size="small"
                  color="gray"
                  text={actionsCancelText}
                  className="dq-w-full sm:dq-ml-3 sm:dq-w-auto"
                  onClick={
                    !handleOnCancel ? handleAlertOnClose : handleAlertOnCancel
                  }
                />
              )}

              <DqButton
                size="small"
                color={type}
                disabled={
                  askConfirmation &&
                  !!confirmationText &&
                  confirmationText.toLowerCase() !==
                    confirmationTextValue.toLowerCase()
                }
                text={actionsConfirmText}
                data-test-selector={dataTestSelector}
                className="dq-w-full dq-mt-3 sm:dq-ml-3 sm:dq-mt-0 sm:dq-w-auto"
                onClick={handleAlertOnConfirm}
              />
            </div>
          </div>
        </DqDialog>
      )}
    </>
  );
};

DqAlertDialog.defaultProps = {
  actionsCancelText: "Cancel",
  actionsConfirmText: "Confirm",
  backdropProps: ({}: dqBackdropT),
  hideCancelBtn: false,
  message: "",
  children: undefined,
  maxWidth: "md",
  toggleEl: undefined,
  type: "error",
  askConfirmation: false,
  confirmationText: "",
  handleOnClose: () => {},
  handleOnOpen: () => {},
  handleOnCancel: () => {},
  show: undefined,
  dataTestSelector: undefined,
};

export type { DqAlertDialogProps };

export default DqAlertDialog;
