// @flow
import type { Node } from "react";
import React from "react";
import get from "lodash/get";
import Helmet from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { titleCase } from "text-title-case";

type Props = {
  canonical?: string,
  title?: string,
  description?: string,
  robots?: string,
  courses?: {
    url: string,
  }[],
  course?: {
    name: string,
    description: string,
  },
  lesson?: {
    title: string,
    description: string,
  },
  seoPaywall?: boolean,
  imageUrl?: string,
  extraTag?: string,
  localSEO?: boolean,
};

export const SEO = (props: Props): Node => {
  const getTitle = () => {
    if (!props.title) return null;
    return `${titleCase(props.title)} | Dataquest`;
  };

  const getMeta = () => {
    const meta = [
      {
        name: "twitter:site",
        content: "@dataquestio",
      },
      {
        name: "twitter:title",
        content: getTitle(),
      },
      {
        name: "og:title",
        content: getTitle(),
      },
      {
        name: "og:site_name",
        content: "Dataquest",
      },
      {
        name: "fb:app_id",
        content: "1068949113131184",
      },
      {
        name: "twitter:card",
        content: "summary",
      },
    ];
    if (props.description) {
      meta.push({
        name: "description",
        content: props.description,
      });
      meta.push({
        name: "twitter:description",
        content: props.description,
      });
      meta.push({
        name: "og:description",
        content: props.description,
      });
    }

    if (props.imageUrl) {
      meta.push({
        name: "og:image",
        content: props.imageUrl,
      });
      meta.push({
        name: "twitter:image",
        content: props.imageUrl,
      });
    }

    if (props.robots) {
      meta.push({
        name: "ROBOTS",
        content: props.robots,
      });
    }

    if (props.canonical) {
      const domain = props.localSEO ? "app" : "www";
      const href = `https://${domain}.dataquest.io${props.canonical}`;
      meta.push({
        name: "og:url",
        content: href,
      });
    }

    return meta.map(metaInfo => (
      <meta
        key={metaInfo.name}
        name={metaInfo.name}
        content={metaInfo.content}
      />
    ));
  };

  const getLink = () => {
    const link = [];
    if (props.canonical) {
      const domain = props.localSEO ? "app" : "www";
      const href = `https://${domain}.dataquest.io${props.canonical}`;
      link.push({
        rel: "canonical",
        href,
      });
    }

    return link;
  };

  const getStructuredData = (entry, entryType) => {
    let name = get(entry, "name");
    if (!name && get(entry, "title")) name = get(entry, "title");
    const { description } = entry;
    let structuredData = {
      "@context": "http://schema.org",
      "@type": entryType,
      name,
      description,
      provider: {
        "@type": "Organization",
        name: "Dataquest",
        sameAs: "https://www.dataquest.io",
      },
      hasPart: undefined,
    };
    const extraData = get(entry, ["metadata", "extra_structured_data"]);

    if (extraData) {
      structuredData = { ...structuredData, ...extraData };
    }

    if (props.seoPaywall) {
      structuredData.hasPart = [
        {
          "@type": "WebPageElement",
          isAccessibleForFree: "False",
          cssSelector: ".dq-paywall",
        },
      ];
    }
    return structuredData;
  };

  const getScript = () => {
    const script = [
      {
        type: "application/ld+json",
        innerHTML: JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Organization",
          url: "https://www.dataquest.io",
          logo: "https://dq-email.s3.amazonaws.com/brand/dq-logo.svg",
        }),
      },
    ];

    if (props.courses && props.courses.length > 0) {
      const itemList = [];
      props.courses.forEach((course, index) => {
        itemList.push({
          position: index + 1,
          "@type": "ListItem",
          url: course.url,
        });
      });
      const listMarkup = {
        "@context": "http://schema.org",
        "@type": "ItemList",
        itemListElement: itemList,
      };

      script.push({
        type: "application/ld+json",
        innerHTML: JSON.stringify(listMarkup),
      });
    }

    if (props.course && Object.keys(props.course).length > 0) {
      script.push({
        type: "application/ld+json",
        innerHTML: JSON.stringify(getStructuredData(props.course, "Course")),
      });
    }

    if (props.lesson && Object.keys(props.lesson).length > 0) {
      script.push({
        type: "application/ld+json",
        innerHTML: JSON.stringify(getStructuredData(props.lesson, ["Course"])),
      });
    }

    return script;
  };

  return (
    <Helmet
      defaultTitle="Learn data science interactively online | Dataquest"
      title={getTitle()}
      link={getLink()}
      script={getScript()}
    >
      {getMeta()}
      {ReactHtmlParser(props.extraTag)}
    </Helmet>
  );
};

SEO.defaultProps = {
  canonical: "",
  title: "Learn data science interactively online",
  description: "",
  robots: "",
  courses: [],
  course: {},
  lesson: {},
  imageUrl: "",
  extraTag: null,
  seoPaywall: false,
};

export default SEO;
