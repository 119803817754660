import Immutable from "seamless-immutable";
import { make_simple_selectors, make_reducer_n_actions } from "redux_helpers";
import defaultTo from "lodash/defaultTo";
import toString from "lodash/toString";
import { pickCamelCase } from "helpers/converter";

// -------
// Initial State
// --------

const profileInfo = {
  fullname: "",
  email: "",
  location: "",
  imageUrl: "",
  dateJoined: "",
  githubUrl: "",
  linkedinUrl: "",
  websiteUrl: "",
  isPublic: false,
  timezone: "",
  workStatus: "",
  universityName: "",
  companyName: "",
  jobTitle: "",
  user_id: "",
  profile_id: "",
  canEdit: false,
  canDownloadSourceCode: false,
  isLoading: true,
  saveErrors: {},
  profileNotFound: false,
};

const initial_state = {
  ...profileInfo,
  requestedProfile: { ...profileInfo },
  productTourShown: true,
  productTourInProgress: false,
};

// -------
// Selectors
// --------
const BASE = "profile_info";
export { BASE as BASE_SELECTOR_PATH };

const simple_selectors = make_simple_selectors(initial_state, BASE);

export const selectors = {
  ...simple_selectors,
};

// ------------------------------------
// Reducer and Actions
// ------------------------------------
const action_types_prefix = "profile_info/";

const public_handlers = {
  reset: () => Immutable(initial_state),
  clearErrors: state => state.set("saveErrors", {}),
};

const getProfileData = ({ payload }) => ({
  ...pickCamelCase(payload.profile, [
    "image_url",
    "location",
    "github_url",
    "linkedin_url",
    "website_url",
    "age",
    "work_status",
    "university_name",
    "last_seen",
    "product_tour_shown",
    "timezone",
  ]),
  dateJoined: defaultTo(payload.date_joined, ""),
  fullname: `${payload.first_name} ${payload.last_name}`,
  jobTitle: payload.profile.job_title || "",
  companyName: payload.profile.company_name || "",
  email: payload.email || "",
  username: payload.username || "",
  user_id: toString(payload.id),
  profile_id: toString(payload.profile.id),
  isPublic: payload.profile.public || false,
  canEdit: payload.profile.permissions.can_edit || false,
  canDownloadSourceCode: payload.permissions.can_download_source_code || false,
  saveErrors: {},
  isLoading: false,
  profileNotFound: false,
});

const private_handlers = {
  updateProfile: state => state, // used in rewards
  savingProfile: state => state.set("isLoading", true),
  setSaveErrors: (state, { payload }) =>
    state.merge({
      saveErrors: payload,
      isLoading: false,
    }),
  setProfile: (state, { payload }) => state.merge(getProfileData({ payload })),
  setPublicProfile: (state, { payload }) =>
    state.merge({
      requestedProfile: getProfileData({ payload }),
    }),
  setProductTourInProgress: (state, { payload }) => state.merge(payload),
  setProductTourShown: state => state.set("productTourShown", true),
  setLoading: (state, { payload }) => state.set("isLoading", payload),
  setProfileNotFound: (state, { payload }) =>
    state.merge({ requestedProfile: { profileNotFound: payload } }),
};

export const {
  reducer,
  private_actions,
  actions,
  ACTION_TYPES,
} = make_reducer_n_actions({
  public_handlers,
  private_handlers,
  action_types_prefix,
  initial_state,
  Immutable,
});
export default reducer;
