/**
 * events tracker reporter middleware
 * @param  {object} white_list) has a key for each action event type to be recorded
 *  format white_list[action.type] = {
 *    event: 'string'
 *    custom_properties: (store, action) => {}, // of properties OR just action will be passed
 *  }
 */
import isArray from "lodash/isArray";
import isString from "lodash/isString";
import get from "lodash/get";
import { LOCATION_CHANGE } from "connected-react-router";
import EventsTracker, {
  getTrackingActiveProperty,
} from "helpers/events_tracker";
import { window_location } from "helpers/window_location";

const default_properties_generator = (store, action) => action.payload;
const default_send_check = () => true;
const FEATURE_FLAG_RETRIEVED = "user_info/setFeatureFlagsRetrieved";

function sent_events({ white_list, store, action }) {
  let props = white_list[action.type];
  if (!props) return;

  if (!isArray(props)) {
    props = [props];
  }

  props.forEach(p => send_track_event({ props: p, store, action }));
}

function send_track_event({ props, store, action }) {
  const {
    event,
    custom_properties = default_properties_generator,
    send_check = default_send_check,
  } = props;

  if (!send_check(store.getState, action)) return; // do not sent

  const event_name = isString(event) ? event : event(store.getState, action);
  if (event_name == null) return; // do not sent

  const properties = custom_properties(store.getState, action);

  EventsTracker.track(event_name, properties, {});

  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.log({
      event: event_name,
      ...properties,
    });
  }
}

/**
 * Redux middleware that sends whitelisted events to EventsTracker.
 *
 * The middlewware is added to the Redux store at initialization and will report
 * events based on the actions in the *white_list*. In addition, we are emitting
 * a specific "page" event for every location change that occurs regardless of
 * the white_list.
 *
 * The white listed actions are represented as properties that indicate which
 * action should be sent to events tracker as events. Each of the actions has the
 * corresponding event name and custom properties defined in its corresponding
 * object. Here's an example whitelist:
 *
 *     {
 *         "steps_info/change_to_step": {
 *             event: "my-event-name"
 *             customProperties: (getState, {payload}) => {
 *                 return {my_custom_property: "12.32"}
 *             }
 *         },
 *         ... // additional action/event mappings
 *     }
 *
 * @param {Object} white_list - Whitelisted actions for events tracker.
 */
// eslint-disable-next-line complexity
const events_reporter = white_list => store => next => action => {
  const userJoinedAt = get(store.getState(), "user_info.date_joined", false);
  if (action.type === FEATURE_FLAG_RETRIEVED && !window_location.isIframe()) {
    const event = window.scheduledPageEvent;
    if (event) {
      event.properties.source_survey_required = get(
        store.getState(),
        "user_info.featureFlags.signup_question_required",
        false,
      );
      event.properties.trackingActive = getTrackingActiveProperty(userJoinedAt);
      EventsTracker.page(event.pathname, event.properties);
      window.scheduledPageEvent = undefined;
    }
  }
  if (action.type === LOCATION_CHANGE && !window_location.isIframe()) {
    const hasForcedSignupSource = get(
      store.getState(),
      "user_info.featureFlags.signup_question_required",
      false,
    );
    const hasFeatureFlagsRetrieved = get(
      store.getState(),
      "user_info.featureFlagsRetrieved",
      false,
    );
    // Defensive checks to detect mobile device.
    const hasMatchMedia =
      window && window.matchMedia && typeof window.matchMedia === "function";
    const isMobile =
      hasMatchMedia &&
      window.matchMedia("only screen and (max-width: 768px)").matches;
    const { pathname } = action.payload.location;
    const properties = {
      url: window.location.origin + pathname,
      path: pathname,
      is_mobile: isMobile,
    };
    if (/signup|login/.test(pathname)) {
      properties.source_survey_required = hasForcedSignupSource;
    }
    properties.trackingActive = getTrackingActiveProperty(userJoinedAt);

    if ("source_survey_required" in properties && !hasFeatureFlagsRetrieved)
      window.scheduledPageEvent = {
        pathname,
        properties,
      };
    else EventsTracker.page(pathname, properties);
  }
  const result = next(action);

  sent_events({ white_list, store, action });

  return result;
};

export default events_reporter;
