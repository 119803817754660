import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import DqCard from "components/common/layout/DqCard";

import { IoCheckmarkCircleSharp } from "react-icons/io5";

require("rc-collapse/assets/index.css");

import DqButton from "components/common/elements/DqButton";

export const SuccessStep = ({ verified_email }) => (
  <div
    className="dq-max-w-xl dq-mx-auto dq-my-6 dq-px-4 md:dq-my-16 md:dq-px-0"
    data-test-selector="SuccessView"
  >
    <DqCard>
      <div className="dq-text-center">
        <div className="dq-mb-6 dq-mt-2">
          <IoCheckmarkCircleSharp
            size="48px"
            className="dq-text-green dq-mx-auto"
          />
        </div>
        <div
          className="dq-text-2xl dq-mb-6"
          data-test-selector="success-message"
        >
          Request processed!
        </div>
        <div className="dq-mb-6">
          Thank you. A record of your payment will be sent via email. We are
          honored to be part of your learning journey.
        </div>
        {!verified_email && (
          <div className="dq-mb-6">
            Please verify your email address to stay up to date with your
            subscription changes.
          </div>
        )}
        <DqButton text="Go to your dashboard" to="/dashboard" />
      </div>
    </DqCard>
  </div>
);

SuccessStep.propTypes = {
  verified_email: PropTypes.bool,
};
SuccessStep.defaultProps = {
  verified_email: true,
};

export default SuccessStep;
