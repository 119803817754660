import { window_location } from "helpers/window_location";

const forceFeatureFlagFromUrl = (flagName = "") => {
  const forceFlagParam = window_location.get_query_variable(
    `force-flag-${flagName}`,
  );

  return typeof forceFlagParam !== "undefined" && forceFlagParam !== null
    ? Number(forceFlagParam)
    : undefined;
};

/**
 * `default_value` should always be an integer so we can
 *    gracefully manage multi-variate testing
 *
 * `0` is assumed to be the control group (or feature disabled)
 * `1` is the test group in an A/B test and is the assumed desired state when opting users in to features
 */
export default {
  /**
   * Copy this template to make a new feature configuration
   */
  example_feature: {
    google_optimize_key: "",
    allowed_users: [],
    blocked_users: [],
    default_value: 0,

    /**
     * Add code to this to determine if the user should see the feature or not
     *
     * @ABTestUserData user_data
     * @returns {number}
     */
    custom_case(_user_data) {
      return 0;
    },
  },

  socket_info_debug: {
    allowed_users: [],
    blocked_users: [],
    default_value: 0,
  },

  // New socket transport
  https_socket: {
    // google_optimize_key: null,
    allowed_users: [
      // 397095, // Seb (app)
      // 470231, // Wes (app)
    ],
    blocked_users: [],
    default_value: 0,
    /**
     *
     * 100% of all connections
     *
     * @returns {boolean}
     */
    custom_case() {
      return true;
    },
  },

  /**
   * Weekly Agenda
   */
  weekly_agenda: {
    google_optimize_key: "",
    allowed_users: [],
    blocked_users: [],
    default_value: 0,

    /**
     * Add code to this to determine if the user should see the feature or not
     *
     * @ABTestUserData user_data
     * @returns {number}
     */
    custom_case(_user_data) {
      return 0;
    },
  },

  /**
   * Userhub v2 Redesign
   */
  userhub_v2: {
    google_optimize_key: "",
    allowed_users: [],
    blocked_users: [],
    default_value: 0,
    custom_case(_user_data) {
      const force = forceFeatureFlagFromUrl("userhub_v2");
      if (typeof force !== "undefined") return force;
      return 0;
    },
  },
};
