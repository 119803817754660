/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery } from "@apollo/client";

import {
  REFERRAL_REWARDS_QUERY,
  USER_REFERRAL_REWARDS_QUERY,
  REDEEM_REWARD_MUTATION,
} from "./queries";

export const useUserReferralRewardsQuery = (opts = {}) =>
  useQuery(USER_REFERRAL_REWARDS_QUERY, opts);

export const useReferralRewardsQuery = (opts = {}) =>
  useQuery(REFERRAL_REWARDS_QUERY, opts);

export const useRedeemRewardMutation = (opts = {}) =>
  useMutation(REDEEM_REWARD_MUTATION, opts);
