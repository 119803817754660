import flatMap from "lodash/flatMap";
import keys from "lodash/keys";
import map from "lodash/map";
import Immutable from "seamless-immutable";
import countryJs from "country-js";

import dq_fetch, { dq_graphql } from "redux/utils/dq_fetch";
import get_uuid from "helpers/get_uuid";
import { private_actions, selectors } from "./plan_info_simple";

export const checkForTimedDiscount = () => (dispatch, getState) => {
  const timedDiscount = selectors.timed_discount(getState());
  if (!timedDiscount.start) {
    dispatch(private_actions.noTimedDiscount());
    return Promise.resolve();
  }

  const times = {
    now: Date.now(),
    start: Date.parse(timedDiscount.start),
    extended: Date.parse(timedDiscount.extended),
  };
  if (times.now < times.start || times.now > times.extended) {
    dispatch(private_actions.noTimedDiscount());
    return Promise.resolve();
  }
  dispatch(private_actions.prefillCode(timedDiscount));

  const planNames = keys(selectors.plans(getState()));

  return verifyDiscountCodeForPlans(planNames)(dispatch, getState);
};

export const verifyDiscountCodeForPlans = (
  planNames,
  limitedOffer,
  scholarship,
) => (dispatch, getState) => {
  const { code, couponInit, promoCode } = selectors.discount_state(getState());

  const toRequest = flatMap(planNames, plan_name => ({
    code: code || couponInit || promoCode,
    plan_name,
    limited_offer: !!limitedOffer,
    scholarship: !!scholarship,
  }));

  const url = "/api/v1/accounts/discounts/";
  const uid = get_uuid();
  dispatch(private_actions.checkingDiscount({ uid }));

  const promises = map(toRequest, get_params =>
    dq_fetch(url, { get_params })
      .then(info => ({ [get_params.plan_name]: info }))
      .catch(() => ({ [get_params.plan_name]: { valid: false } })),
  );

  return Promise.all(promises).then(results => {
    const info = Immutable.merge({}, results, { deep: true });
    const { recentUid } = selectors.discount_state(getState());
    if (recentUid === uid) dispatch(private_actions.setDiscounts(info));
  });
};

export const fetchPaymentFeatures = () => (dispatch, _getState) => {
  const graphql = `{
    payment_value_proposition {
      page_type,
      page_tagline,
      features,
      end,
    }
  }`;

  return dq_graphql(graphql).then(data => {
    const paymentFeaturesList = data.data.payment_value_proposition;
    dispatch(private_actions.updatePaymentFeatures(paymentFeaturesList || []));
  });
};

export const fetchPlanPricing = () => (dispatch, _getState) => {
  const graphql = `{
    currencies {
      code,
      default
    },
    pricing_plans {
      name,
      verbose_name,
      plan_id,
      app_label,
      currency,
      price,
      by_month,
      by_month_full,
      billing_period,
      period_months,
      is_lifetime
    }
  }`;

  return dq_graphql(graphql).then(data => {
    if (data.data.pricing_plans)
      dispatch(private_actions.updatePlanInfo(data.data));
    if (data.data.currencies) {
      dispatch(private_actions.setCurrencyList(data.data));
      if (__PROD__) {
        // We want to store country code for LCO banners
        const countryCode = window?.localStorage?.getItem(
          "dq-clientCountryCode",
        );
        if (!countryCode || !countryCode.length)
          dq_fetch(
            `https://extreme-ip-lookup.com/json/?key=${process.env.EXTREME_IP_LOOKUP_API_KEY}`,
          )
            .then(response => {
              window?.localStorage?.setItem(
                "dq-clientCountryCode",
                response.countryCode,
              );
            })
            .catch(() => {});
      }
    }
  });
};
