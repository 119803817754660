import { createSelector } from "reselect";
import Immutable from "seamless-immutable";
import assign from "lodash/assign";
import get from "lodash/get";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
import pick from "lodash/pick";
import reduce from "lodash/reduce";
import toString from "lodash/toString";
import trim from "lodash/trim";

import {
  make_simple_selectors,
  make_reducer_n_actions,
  make_simple_reducer,
} from "redux_helpers";

import {
  SUBSCRIPTION_TYPE_FREE,
  SUBSCRIPTION_TYPE_PREMIUM,
  SUBSCRIPTION_PERIOD_ANNUAL,
} from "helpers/constants";

// -------
// Initial State
// --------

const initial_state = {
  email: "",
  first_name: "",
  last_name: "",
  username: "",
  current_path_name: "",
  currentPath: "",
  plan_name: "",
  plan_full_name: SUBSCRIPTION_TYPE_FREE,
  plan_end_date: null,
  plan_is_yearly: false,
  subscription_period: "",
  paused_months: 1,
  pause_end_date: null,
  if_pause_end_date: null,
  plan_paused: false,
  plan_canceled: false,
  subscription_changes_scheduled: false,
  starting_page: "",
  referralCode: "",
  referralUrl: "",
  isReferee: false,
  lifetimeAccessUnlocked: null,
  hasScholarship: false,
  verified_email: true,
  display_verification_warning: false,
  mfa_enabled: "",
  mfa_uri: "",
  mfaErrors: "",
  mfaUpdated: false,

  elevio_user_hash: "",
  intercom_id: "",
  intercom_active: false,
  intercom_user_hash: "",

  user_id: "",
  is_subscribed: false,
  onFreePlan: false,
  last_active_date: new Date(),
  is_moderator: false,
  askingForUser: true,
  hasTeamAdminAccess: false,
  team_id: "",
  team_plan_id: "",
  team_card_id: "",
  team_has_plan: false,
  team_name: "",
  team_banner_url: "",
  team_banner_name: "",
  hasIndividualPlan: false,
  hasUnpaidPremium: false,
  isLifetimeSubscriber: false,

  // login related`
  displayMFARequest: false,
  login_errors: {},
  signup_errors: {},
  forgot_errors: {},
  reset_errors: {},
  change_errors: {},
  requesting_login: false,
  requesting_signup: false,
  is_new_user: false, // a user who just signup
  forgot_sent: false,
  password_reset: false,
  is_staff: false,
  onboarding_status: "",
  date_joined: "",
  impersonation: false,
  skipOnboarding: null,
  featureFlagsRetrieved: false,
  featureFlagsUpdated: false,
  featureFlags: {
    ffNotes: {},
  },
  subscribeCTASettings: {},
  subscribeCTASettingsRetrieved: false,
  verificationEmailSent: false,
  authData: {},
  displayUMUXSurvey: false,
  displayWelcomeKit: false,
  isLoadingWelcomeKit: false,
  welcomeKitSteps: [],
  mobileWarningDisplayed: false,
  displayCourseContext: "",

  // Chandra chat related
  chatMessages: [],
  partialChatResponse: "",
  isChatOpen: false,
};

// -------
// Selectors
// --------
const BASE = "user_info";
export { BASE as BASE_SELECTOR_PATH };

const activity_timeout = 60000; // 10 minutes

const simple_selectors = make_simple_selectors(initial_state, BASE);

const last_active_date_selector = state => state[BASE].last_active_date;
const user_name = createSelector(
  simple_selectors.username,
  simple_selectors.first_name,
  (username, first) => first || username,
);
const upgrade_available = createSelector(
  simple_selectors.plan_name,
  plan => plan !== SUBSCRIPTION_TYPE_PREMIUM,
);

const is_logged_in = createSelector(
  simple_selectors.username,
  username => Boolean(username),
);

const is_active = createSelector(
  last_active_date_selector,
  last_active_date => Boolean(new Date() - last_active_date < activity_timeout),
);

const is_there_a_request = createSelector(
  simple_selectors.requesting_login,
  simple_selectors.requesting_signup,
  (login, signup) => login || signup,
);

const fullname = createSelector(
  simple_selectors.first_name,
  simple_selectors.last_name,
  (first, last) => trim(`${first} ${last}`),
);
const is_teammember = createSelector(
  simple_selectors.team_id,
  team_id => team_id !== "",
);

const is_staff = state => state[BASE].is_staff;

const user_id = state => state[BASE].user_id;

const date_joined = state => state[BASE].date_joined;

export const selectors = {
  ...omit(simple_selectors, ["email", "username"]),
  user_name,
  user_email: simple_selectors.email,
  user_username: simple_selectors.username,
  date_joined,
  upgrade_available,
  is_logged_in,
  is_active,
  last_active_date: last_active_date_selector,
  is_there_a_request,
  fullname,
  is_teammember,
  user_id,
  is_staff,
  onboarding_status: simple_selectors.onboarding_status,
};

// ------------------------------------
// Reducer and Actions
// ------------------------------------

const action_types_prefix = "user_info/";

const public_handlers = {
  clearMFAErrors: state => state.set("mfaErrors", ""),
  clearMFAUpdated: state => state.set("mfaUpdated", false),
  storeAuthData: (state, { payload }) => state.set("authData", payload),
  update_last_active: (state, { payload }) => {
    const last_active_date = get(payload, "last_active_date", new Date());

    return state.set("last_active_date", last_active_date);
  },
  setChatOpen: (state, { payload }) => state.set("isChatOpen", payload),
  setChatMessages: (state, { payload }) => state.set("chatMessages", payload),
  setPartialChatResponse: (state, { payload }) =>
    state.set("partialChatResponse", payload),
  set_login_error: (state, { payload }) => state.set("login_errors", payload),
  set_forgot_error: (state, { payload }) => state.set("forgot_errors", payload),
  set_signup_error: (state, { payload }) =>
    state.set("signup_errors", {
      general: payload,
    }),
  set_reset_error: (state, { payload }) => state.set("reset_errors", payload),
  set_forgot_sent: (state, { payload }) =>
    state.merge({
      forgot_sent: payload,
      forgot_errors: {},
    }),
  request_login: state => state.set("requesting_login", true),
  request_signup: state => state.set("requesting_signup", true),
  cancel_request: state =>
    state.merge({
      requesting_login: false,
      requesting_signup: false,
    }),
  set_names: (state, { payload }) =>
    state.merge(pick(payload, ["first_name", "last_name"])),
  update_onboarding_status: (state, { payload }) =>
    state.set("onboarding_status", payload),
  setMobileWarningDisplayed: (state, { payload }) =>
    state.set("mobileWarningDisplayed", payload),
  setDisplayCourseContext: (state, { payload }) =>
    state.set("displayCourseContext", payload),
};

function is_canceled(profile) {
  if (profile.plan.toLowerCase() === SUBSCRIPTION_TYPE_FREE) return false;
  if (profile.has_paused_premium) return false;
  return !profile.premium_invoices_active;
}

const private_handlers = {
  reset: _state => Immutable(initial_state),
  // from xhr
  login_errors: (state, { payload }) => state.set("login_errors", payload),
  signup_errors: (state, { payload }) => {
    const cleaned = reduce(payload, assign, {});
    return state.set("signup_errors", cleaned);
  },
  forgot_errors: (state, { payload }) =>
    state.set("forgot_errors", {
      general: payload.error,
    }),
  reset_errors: (state, { payload }) => {
    if (payload && typeof payload.error === "object") {
      const error_object = { ...payload.error };
      return state.set("reset_errors", error_object);
    }
    if (payload && typeof payload.error === "string") {
      return state.set("reset_errors", payload);
    }

    return state.set("reset_errors", {
      general: "We couldn't validate your reset request.",
    });
  },
  change_errors: (state, { payload }) =>
    state.set("change_errors", mapValues(payload, messages => messages[0])),
  set_password_reset: state => state.set("password_reset", true),
  setDisplayMFA: (state, { payload }) =>
    state.set("displayMFARequest", payload),
  setMFAErrors: (state, { payload }) => state.set("mfaErrors", payload),
  setMFAUri: (state, { payload }) => state.set("mfa_uri", payload),
  setMFAEnabled: (state, { payload }) =>
    state.merge({
      mfa_enabled: payload,
      mfaUpdated: true,
    }),
  set_info: (state, { payload }) => {
    const { info, is_new_user = false } = payload;

    const cleaned = {
      ...pick(info, [
        "email",
        "username",
        "first_name",
        "last_name",
        "mfa_enabled",
        "intercom_id",
        "intercom_user_hash",
        "elevio_user_hash",
      ]),
      user_id: toString(info.id),
      referralCode: info.profile.referral_code,
      referralUrl: `${window.location.origin}/referral-signup/${info.profile.referral_code}/`,
      isReferee: info.profile.is_referee,
      lifetimeAccessUnlocked: info.profile.lifetime_access,
      hasScholarship: info.profile.has_scholarship_subscription,
      starting_page: info.profile.starting_page,
      plan_name: info.profile.plan.toLowerCase(),
      plan_end_date: info.profile.premium_end_date
        ? new Date(info.profile.premium_end_date)
        : null,
      plan_is_yearly:
        info.profile.subscription_period === SUBSCRIPTION_PERIOD_ANNUAL,
      subscription_period: info.profile.subscription_period,
      pause_end_date: info.profile.premium_unpause_date
        ? new Date(info.profile.premium_unpause_date)
        : null,
      plan_paused: Boolean(info.profile.has_paused_premium),
      plan_canceled: is_canceled(info.profile),
      subscription_changes_scheduled:
        info.profile.subscription_changes_scheduled,
      is_subscribed: info.profile.plan.toLowerCase() !== SUBSCRIPTION_TYPE_FREE,
      onFreePlan: info.profile.plan.toLowerCase() === SUBSCRIPTION_TYPE_FREE,
      is_moderator: info.profile.is_moderator,
      login_errors: {},
      signup_errors: {},
      forgot_errors: {},
      reset_errors: {},
      change_errors: {},
      requesting_login: false,
      requesting_signup: false,
      team_id: toString(get(info.profile, "team_id", "")),
      hasTeamAdminAccess: get(info.profile, "has_team_admin_access", false),
      team_has_plan: get(info.profile, "has_group_subscription"),
      team_name: get(info.profile, "team_name"),
      team_banner_url: get(info.profile, "team_banner_url"),
      team_banner_name: get(info.profile, "team_banner_name"),
      hasIndividualPlan: get(info.profile, "has_individual_plan"),
      is_new_user,
      current_path_name: info.profile.current_path_name,
      currentPath: info.profile.current_path,
      askingForUser: false,
      is_staff: info.is_staff,
      onboarding_status: info.onboarding_status,
      date_joined: info.date_joined,
      verified_email: get(info.profile, "verified_email"),
      display_verification_warning: get(
        info.profile,
        "display_verification_warning",
      ),
      displayWelcomeKit: info.profile.display_welcome_kit || false,
      displayUMUXSurvey: info.profile.display_usability_survey || false,
      isLifetimeSubscriber: get(info.profile, "has_lifetime_subscription"),
      hasUnpaidPremium: get(info.profile, "has_unpaid_premium"),
    };
    cleaned.plan_full_name = `${cleaned.plan_name} ${
      cleaned.subscription_period ? cleaned.subscription_period : ""
    }`;
    if (!cleaned.is_subscribed) cleaned.plan_full_name = SUBSCRIPTION_TYPE_FREE;

    return state.merge(cleaned);
  },
  set_intercom_active: state => state.set("intercom_active", true),
  setImpersonationMode: state => state.set("impersonation", true),
  setAskingForUser: make_simple_reducer("askingForUser"),
  set_paused_months: make_simple_reducer("paused_months"),
  set_if_pause_end_date: (state, { payload: { end_date } }) =>
    state.set("if_pause_end_date", new Date(end_date)),
  setVerificationEmailSent: state => state.set("verificationEmailSent", true),
  setFeatureFlagsRetrieved: (state, { payload }) =>
    state.set("featureFlagsRetrieved", payload),
  setFeatureFlagsUpdated: (state, { payload }) =>
    state.set("featureFlagsUpdated", payload),
  setFeatureFlags: (state, { payload }) =>
    state.set("featureFlags", {
      ...state.featureFlags,
      ...payload,
    }),
  setSubscribeCTASettingsRetrieved: state =>
    state.set("subscribeCTASettingsRetrieved", true),
  setSubscribeCTASettings: (state, { payload }) => {
    const data = payload.data.subscribe_cta;
    if (data)
      return state.set("subscribeCTASettings", {
        btnText: data.button_text,
        btnColor: data.button_color,
      });
    return state;
  },
  setLoadingWelcomeKit: (state, { payload }) =>
    state.set("isLoadingWelcomeKit", payload),
  setWelcomeKitSteps: (state, { payload }) =>
    state.set("welcomeKitSteps", payload),
};

export const {
  reducer,
  private_actions,
  actions,
  ACTION_TYPES,
} = make_reducer_n_actions({
  public_handlers,
  private_handlers,
  action_types_prefix,
  initial_state,
  Immutable,
});
export default reducer;
