import { actions as user_actions } from "redux/modules/user_info";

import { dq_graphql } from "redux/utils/dq_fetch";
import { private_actions } from "./gift_info_simple";

// eslint-disable-next-line import/prefer-default-export
export const claimGift = gift_id => (dispatch, _getState) => {
  const mutation = `mutation{claim_gift(gift_id:"${gift_id}"){success, error_message}}`;

  return dq_graphql(mutation)
    .then(data => {
      const { error_message } = data.data.claim_gift;
      if (!error_message) {
        dispatch(private_actions.setClaimSuccess());
        return dispatch(user_actions.refresh_user());
      }
      dispatch(private_actions.setClaimErrorMessage(error_message));
    })
    .catch(() => dispatch(private_actions.setClaimError()));
};
