// @flow
import type { Node } from "react";
import type { dqBackdropT } from "components/common/overlays/DqBackdrop/";
import React, { useEffect } from "react";
import cn from "classnames";
import Draggable from "react-draggable";
import { useKeyPressEvent } from "react-use";
import FocusLock from "react-focus-lock";
import { ResizableBox } from "react-resizable";

import DqBackdrop from "components/common/overlays/DqBackdrop";
import DqPortal from "components/common/utils/DqPortal";

require("react-resizable/css/styles.css");

const STARTING_WIDGET_WIDTH = 500;

type Props = {
  /**
   * Sets the maximum width of the dialog window
   */
  maxWidth?: "sm" | "md" | "lg" | "xl" | "2xl",
  /**
   * Enable vertical scroll
   */
  scroll?: boolean,
  /**
   * Enable resizable behavior
   */
  resizable?: boolean,
  /**
   * Set styles for the dialog window.
   */
  dialogStyle?: any,
  className?: string,
  /**
   * Content of the dialog
   */
  children: any,
  /**
   * DqBackdrop properties
   */
  backdropProps?: dqBackdropT | Object,
  /**
   * Function to execute when the dialog opens
   */
  handleOnOpen?: any => void,
  /**
   * Function to execute when closing the dialog clicking on the backdrop or pressing the ESC key
   */
  handleOnClose: any => void,
  resizeCallback?: any,
};

/**
 * Dialog window
 */
const DqDialog = ({
  dialogStyle,
  className,
  maxWidth = "md",
  scroll,
  resizable,
  children,
  backdropProps,
  handleOnOpen,
  handleOnClose,
  resizeCallback,
}: Props): Node => {
  useKeyPressEvent("Escape", () => {
    if (handleOnClose) handleOnClose();
  });

  useEffect(() => {
    if (handleOnOpen) {
      handleOnOpen();
    }
  }, [handleOnOpen]);

  // Workaround to avoid purge of this classes on build
  const maxWidthClass = {
    sm: "dq-max-w-sm",
    md: "dq-max-w-md",
    lg: "dq-max-w-lg",
    xl: "dq-max-w-xl",
    "2xl": "dq-max-w-2xl",
  };

  return (
    <DqPortal className="root-dialog">
      <DqBackdrop {...backdropProps} onClick={handleOnClose}>
        <Draggable handle=".handle">
          <div className="dq-h-full dq-flex dq-items-center dq-justify-center">
            <div
              role="dialog"
              aria-labelledby="dialog-title"
              className={cn(
                `dq-relative dq-flex dq-flex-col dq-bg-white`,
                {
                  "dq-max-h-full dq-overflow-y-auto": scroll,
                  "dq-w-full": !resizable,
                  "dq-w-md": resizable,
                },
                resizable ? "" : maxWidthClass[maxWidth],
                className,
              )}
              style={dialogStyle}
            >
              <div role="presentation" onClick={e => e.stopPropagation()}>
                <FocusLock autoFocus returnFocus>
                  {resizable ? (
                    <ResizableBox
                      className="box"
                      onResize={resizeCallback}
                      axis="x"
                      width={Math.min(STARTING_WIDGET_WIDTH, window.innerWidth)}
                      minConstraints={[300, 300]}
                      maxConstraints={[window.innerWidth, window.innerHeight]}
                      resizeHandles={
                        dialogStyle?.right
                          ? ["w"]
                          : dialogStyle?.left
                          ? ["e"]
                          : []
                      }
                    >
                      <div>{children}</div>
                    </ResizableBox>
                  ) : (
                    children
                  )}
                </FocusLock>
              </div>
            </div>
          </div>
        </Draggable>
      </DqBackdrop>
    </DqPortal>
  );
};

DqDialog.defaultProps = {
  backdropProps: ({}: dqBackdropT),
  dialogStyle: ({}: any),
  className: "",
  maxWidth: "md",
  scroll: false,
  resizable: false,
  handleOnOpen: () => {},
  resizeCallback: () => {},
};

export default DqDialog;
