/* eslint-disable no-underscore-dangle */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "./history";

export const make_root_reducer = asyncReducers =>
  combineReducers({
    router: connectRouter(history),
    ...asyncReducers,
  });

export const inject_reducers = reducer_map => {
  const store = window.__STORE__ || {};

  store.asyncReducers = {
    ...store.asyncReducers,
    ...reducer_map,
  };
  if (__TEST__) store.asyncReducers = reducer_map;

  const reducer = make_root_reducer(store.asyncReducers);

  if (!__TEST__) {
    store.replaceReducer(reducer);
  }
  return reducer;
};
