import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { make_root_reducer } from "store/inject_reducers";
import events_reporter from "redux/middlewares/events_reporter";
import events_definitions from "redux/middlewares/events_reporter/events_definitions";
import authenticationValidator from "redux/middlewares/authenticationValidator";
import eventsToExclude from "redux/middlewares/authenticationValidator/eventsToExclude";
import * as Sentry from "@sentry/react";
import actionsBlacklist from "./redux_extension_blacklist.json";

import history from "./history";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    const SENSITIVE_ACTIONS = [
      "user_info/login",
      "user_info/signup",
      "user_info/reset_password",
    ];

    if (SENSITIVE_ACTIONS.indexOf(action.type) !== -1) {
      return {
        ...action,
        password: null,
      };
    }

    return action;
  },
});

export default (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [
    thunk,
    events_reporter(events_definitions),
    authenticationValidator(eventsToExclude),
    routerMiddleware(history),
  ];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [sentryReduxEnhancer];
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionsBlacklist,
      })
    : compose;

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const init_reducers = {};
  const store = createStore(
    make_root_reducer(init_reducers),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers),
  );
  store.asyncReducers = init_reducers;
  store.history = history;
  return store;
};
