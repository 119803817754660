import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import AuthLayout from "layouts/AuthLayout";
import Login from "components/auth/Login";
import SEO from "components/marketing/SEO";

import { selectors as user_selectors } from "redux/modules/user_info";

export const selectors = {
  featureFlags: user_selectors.featureFlags,
};
export const actions = {};

const mapStateToProps = createStructuredSelector(selectors);

export class LoginView extends React.PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      hash: PropTypes.string,
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string,
        uid: PropTypes.string,
      }).isRequired,
    }),

    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    history: undefined,
    location: undefined,
    match: undefined,
  };

  render() {
    return (
      <AuthLayout>
        <SEO
          title="Log in"
          description="Login to Dataquest and start learning data science today."
        />
        <Login />
      </AuthLayout>
    );
  }
}

export default connect(
  mapStateToProps,
  actions,
)(LoginView);
