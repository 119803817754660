import { useMutation, useQuery } from "@apollo/client";

import {
  SAVE_PROGRESS_NOTIFICATION_SHOWN_QUERY,
  USER_SETTINGS_QUERY,
  USER_SETTINGS_MUTATION,
} from "queries/settings/queries";

export const useSaveProgressNotificationShownQuery = (
  opts = { fetchPolicy: "cache-only" },
) => useQuery(SAVE_PROGRESS_NOTIFICATION_SHOWN_QUERY, opts);

export const useUserSettingsQuery = (opts = {}) =>
  useQuery(USER_SETTINGS_QUERY, opts);

export const useUserSettingsMutation = (opts = {}) =>
  useMutation(USER_SETTINGS_MUTATION, opts);
