/* eslint-disable complexity */
import { defineAbility } from "@casl/ability";

// manage and all are special keywords in CASL.
// manage represents any action and all represents any subject.

export default user =>
  defineAbility((can, cannot) => {
    if (user.isLoggedIn) {
      can("manage", "Session");

      if (
        user.hasIndividualPlan ||
        user.hasAnySubscription ||
        !user.isTeammember
      ) {
        can("view", "Subscription");
      }

      if (
        (user.hasIndividualPlan ||
          user.hasAnySubscription ||
          !user.isTeammember) &&
        !user.hasUnpaidPremium
      ) {
        can("manage", "Subscription");
      }

      if (!user.hasIndividualPlan && !user.isTeammember) {
        can("buy", "Subscription");
      } else {
        cannot("buy", "Subscription");
      }

      if (
        user.hasIndividualPlan &&
        !user.isTeammember &&
        !user.isLifetimeSubscriber
      ) {
        can("win", "Lifetime Access");
      }

      if (!user.isTeammember) {
        can("refer", "Friend");
      }

      if (user.canEditProfile) {
        can("manage", "Profile");
      }

      // Team permissions
      if (user.isTeamOwner) {
        can("manage", "Team Members");
        can("manage", "Team Subscription");
        can("manage", "Team Settings");
      }

      if (user.isTeamAdmin) {
        can("view", "Team Members");
        can("invite", "Team Members");
        can("manage", "Team Settings");
        can("view", "Team Subscription");
      }

      if (user.isTeammember) {
        can("view", "Team Members");
        can("view", "Team Subscription");
      }
    }
  });
