/* eslint-disable import/prefer-default-export */
// Helper for Refersion tracking

import { window_location } from "./window_location";

export const setRefersionTracking = () => {
  let refersionToken;
  if (window._rfsn_ready && window._rfsn) {
    refersionToken = window_location.get_query_variable("rfsn");
    if (refersionToken) {
      window._rfsn._setSource("CHARGEBEE");
    }
  }
  return refersionToken;
};
