// @flow
import type { Node } from "react";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { titleCase } from "text-title-case";

import DqSpinner from "components/common/feedback/DqSpinner";
import { ACTION_TIMEOUT } from "helpers/constants";

type DqButtonProps = {
  /**
   * Text of the button
   */
  text?: string,
  /**
   * Add additional style classes
   */
  className?: string,
  /**
   * Icon placed before text
   */
  startIcon?: Node,
  /**
   * Icon placed after text
   */
  endIcon?: Node,
  /**
   * Default behavior of the button
   */
  type?: "button" | "reset" | "submit",
  /**
   * Color of the button
   */
  color?:
    | "blue"
    | "white"
    | "gray"
    | "light-gray"
    | "purple"
    | "error"
    | "warning"
    | "info"
    | "success",
  /**
   * Size of the button
   */
  size?: "xsmall" | "small" | "regular" | "large",
  /**
   * Variant of the button
   */
  variant?: "solid" | "outline" | "text",
  /**
   * Disable interactions with the button
   */
  disabled?: boolean,
  /**
   * Display loading indicator. If `true` will also disable interactions with the button
   */
  loading?: boolean,
  /**
   * By default text is transformed to title case. If `true` display text as specified in prop
   */
  disableTitleCase?: boolean,
  /**
   * Copy text to clipboard when button is clicked
   */
  copyOnClick?: boolean,
  /**
   * Text to copy to clipboard when button is clicked
   */
  copyValue?: string,
  /**
   * URL for the link when button is clicked, an `a` element will be rendered
   */
  href?: string,
  /**
   * App URL for the link when button is clicked, an `Link` element from the `react-router-dom` library will be rendered
   */
  to?: string | { pathname: string, search?: any, state?: any },
  /**
   * Function to execute when clicking the button
   */
  onClick?: Function,
  /**
   * Button element ref
   */
  innerRef?: Object | null,
  /**
   * Additional class for the text element
   */
  textClassName: string | null,
};

const DqButton = ({
  text,
  className,
  startIcon,
  endIcon,
  type = "button",
  color = "blue",
  size = "regular",
  variant = "solid",
  disabled = false,
  loading = false,
  disableTitleCase = false,
  copyOnClick = false,
  copyValue = "",
  href,
  to,
  onClick,
  innerRef = null,
  textClassName = null,
  ...otherProps
}: DqButtonProps): Node => {
  const [copyState, setCopyState] = useState(false);
  const commonClasses =
    "dq-inline-flex dq-items-center dq-justify-center dq-space-x-2 dq-min-w-32 dq-border-2 dq-border-transparent dq-transition-colors focus:dq-outline-none focus:dq-ring-2 focus:dq-ring-offset-2 focus:dq-ring-blue";
  const colorClasses = {
    solid: {
      blue: "dq-bg-blue dq-text-white",
      purple: "dq-bg-purple dq-text-white",
      white: "dq-bg-white dq-text-gray-800",
      gray:
        "dq-bg-gray-800 dq-text-white dark:dq-bg-white dark:dq-text-gray-800",
      "light-gray":
        "dq-bg-gray-100 dq-text-gray-500 dark:dq-bg-gray-600 dark:dq-text-gray-100",
      error: "dq-bg-red dq-text-white",
      warning: "dq-bg-orange-200 dq-text-white",
      info: "dq-bg-blue dq-text-white",
      success: "dq-bg-green dq-text-white",
    },
    outline: {
      blue: "!dq-border-blue dq-text-blue",
      purple: "!dq-border-purple dq-text-purple",
      white: "!dq-border-white dq-text-white",
      gray:
        "!dq-border-gray-800 dq-text-gray-800 dark:dq-border-white dark:dq-text-white",
      "light-gray":
        "!dq-border-gray-100 dq-text-gray-100 dark:dq-border-gray-600 dark:dq-text-gray-600",
      error: "!dq-border-red dq-text-red",
      warning: "!dq-border-orange-200 dq-text-orange-200",
      info: "!dq-border-blue dq-text-blue",
      success: "!dq-border-green dq-text-green",
    },
    text: {
      blue: "dq-text-blue",
      purple: "dq-text-purple",
      white: "dq-text-white",
      gray: "dq-text-gray-800 dark:dq-text-white",
      "light-gray": "dq-text-gray-400",
      error: "dq-text-red",
      warning: "dq-text-orange-200",
      info: "dq-text-blue",
      success: "dq-text-green",
    },
  };
  const hoverClasses = {
    solid: {
      blue: "hover:dq-bg-blue-hover hover:dq-text-white",
      purple: "hover:dq-bg-purple-500 hover:dq-text-purple",
      white: "hover:dq-bg-gray-50 hover:dq-text-gray-800",
      gray: "hover:dq-bg-gray-400 hover:dq-text-white",
      "light-gray": "hover:dq-bg-gray-100/70",
      error: "hover:dq-bg-red-hover hover:dq-text-white",
      warning: "hover:dq-bg-orange-300 hover:dq-text-white",
      info: "hover:dq-bg-blue-hover hover:dq-text-white",
      success: "hover:dq-bg-green-hover hover:dq-text-white",
    },
    outline: {
      blue: "hover:dq-bg-blue hover:dq-text-white",
      purple: "hover:dq-bg-purple hover:dq-text-white",
      white: "hover:dq-bg-white hover:dq-text-gray-800",
      gray: "hover:dq-bg-gray-800 hover:dq-text-white",
      "light-gray": "hover:dq-text-gray-400 hover:!dq-border-gray-400",
      error: "hover:dq-bg-red hover:dq-text-white",
      warning: "hover:dq-bg-orange-200 hover:dq-text-white",
      info: "hover:dq-bg-blue hover:dq-text-white",
      success: "hover:dq-bg-green hover:dq-text-white",
    },
    text: {
      blue: "hover:dq-text-blue-hover",
      purple: "hover:dq-text-purple",
      white: "hover:dq-text-blue-hover",
      gray: "hover:dq-text-gray-400",
      "light-gray": "hover:dq-text-gray-400/70",
      error: "hover:dq-text-red-hover",
      warning: "hover:dq-bg-orange-200 hover:dq-text-white",
      info: "hover:dq-text-blue-hover",
      success: "hover:dq-text-green-hover",
    },
  };
  const fontSizeClasses = {
    xsmall: "dq-text-sm",
    small: "dq-text-sm",
    regular: "dq-text-base",
    large: "dq-text-lg",
  };
  const sizeClasses = {
    xsmall: "dq-p-0",
    small: "dq-px-2.5 dq-py-1.5",
    regular: "dq-px-4 dq-py-2",
    large: "dq-px-6 dq-py-3",
  };
  const loadingSizeClasses = {
    xsmall: "dq-h-4 dq-w-4",
    small: "dq-h-5 dq-w-5",
    regular: "dq-h-6 dq-w-6",
    large: "dq-h-7 dq-w-7",
  };
  const disabledClasses = "dq-opacity-50 dq-cursor-not-allowed";

  const styleClasses = cn(
    commonClasses,
    colorClasses[variant][color],
    fontSizeClasses[size],
    variant === "text" ? "dq-p-0 !dq-border-0 !dq-min-w-0" : sizeClasses[size],
    disabled || loading ? disabledClasses : hoverClasses[variant][color],
    className,
  );

  const copyToClipboard = useCallback(() => {
    const data = [
      // $FlowFixMe
      new ClipboardItem({
        "text/plain": Promise.resolve(
          new Blob([copyValue], { type: "text/plain" }),
        ),
      }),
    ];

    // $FlowFixMe
    navigator.clipboard.write(data).then(
      () => {
        // Let know user that the URL has been copied,
        // and after 1 seconds update button text.
        setCopyState(true);
        setTimeout(() => {
          setCopyState(false);
        }, ACTION_TIMEOUT);
      },
      e => {
        console.error(e);
      },
    );
  }, []);

  const buttonContent = loading ? (
    <DqSpinner className={loadingSizeClasses[size]} />
  ) : (
    <>
      {startIcon}
      {copyState && "Copied!"}
      {!copyState && text && (
        <span {...(textClassName ? { className: textClassName } : {})}>
          {disableTitleCase ? text : titleCase(text)}
        </span>
      )}
      {endIcon}
    </>
  );

  if (href)
    return (
      <a {...otherProps} href={href} className={styleClasses}>
        {buttonContent}
      </a>
    );

  if (to)
    return (
      <Link {...otherProps} to={to} className={styleClasses}>
        {buttonContent}
      </Link>
    );

  return (
    <button
      {...otherProps}
      ref={innerRef}
      type={type}
      disabled={disabled || loading}
      className={styleClasses}
      onClick={copyOnClick ? copyToClipboard : onClick}
    >
      {buttonContent}
    </button>
  );
};

DqButton.defaultProps = {
  text: "",
  className: "",
  startIcon: null,
  endIcon: null,
  type: "button",
  color: "blue",
  size: "regular",
  variant: "solid",
  disabled: false,
  loading: false,
  disableTitleCase: false,
  href: "",
  to: "",
  onClick: () => {},
  innerRef: null,
  textClassName: null,
  copyOnClick: false,
  copyValue: "",
};

export type { DqButtonProps };

export default DqButton;
