// @flow
import type { AbstractComponent, Node } from "react";
import React from "react";
// $FlowFixMe
import { useLazyQuery } from "@apollo/client";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectors as userInfoSelectors } from "redux/modules/user_info";

import { USER_SETTINGS_QUERY } from "queries/settings/queries";
import {
  useUserSettingsMutation,
  useSaveProgressNotificationShownQuery,
} from "queries/settings/hooks";

type UserSettings = {
  autocomplete: boolean,
  text_theme: string,
  editor_theme: string,
  sound_notifications: boolean,
  appstream_notifications: boolean,
  remote_app_sessions: boolean,
  display_answers: boolean,
  display_solutions: boolean,
  content_skip: boolean,
};

type CachedSettings = {
  saveProgressNotificationShown: boolean,
};

type ContextProps = {
  userSettings: UserSettings,
  userSettingsLoading: boolean,
  refetchUserSettings: Function,
  updateUserSettings: Function,
  cachedSettings: CachedSettings,
};

const initialState: ContextProps = {
  userSettings: {
    autocomplete: true,
    text_theme: "light",
    editor_theme: "dark",
    sound_notifications: true,
    appstream_notifications: true,
    remote_app_sessions: true,
    display_answers: true,
    display_solutions: true,
    content_skip: true,
    loaded: false,
  },
  userSettingsLoading: true,
  refetchUserSettings: () => null,
  updateUserSettings: () => null,
  cachedSettings: {
    saveProgressNotificationShown: false,
  },
};

export const UserSettingsContext: Object = React.createContext<ContextProps>({
  ...initialState,
});

const selectors = {
  isLoggedIn: userInfoSelectors.is_logged_in,
};

const mapStateToProps = createStructuredSelector(selectors);

type Props = {
  isLoggedIn: boolean,
  children: Node,
};

const UserSettingsProvider = ({ isLoggedIn, children }: Props) => {
  const [userSettings, setUserSettings] = React.useState<UserSettings>({
    ...initialState.userSettings,
  });

  const [getSettings, { loading, data, error }] = useLazyQuery(
    USER_SETTINGS_QUERY,
    {
      fetchPolicy: "network-only",
    },
  );

  const [updateSettingsMutation] = useUserSettingsMutation();

  const {
    data: saveProgressQueryData,
  } = useSaveProgressNotificationShownQuery();

  const saveProgressNotificationShown = saveProgressQueryData
    ? saveProgressQueryData.cachedSettings?.saveProgressNotificationShown
    : false;

  const cachedSettings = React.useMemo(
    () => ({ saveProgressNotificationShown }),
    [saveProgressNotificationShown],
  );

  React.useEffect(() => {
    if (isLoggedIn) getSettings();
  }, [isLoggedIn, getSettings]);

  React.useEffect(() => {
    if (data && !error) setUserSettings({ ...data.settings, loaded: true });
  }, [data, error, setUserSettings]);

  const refetchUserSettings = React.useCallback(() => getSettings(), [
    getSettings,
  ]);

  const updateUserSettings = React.useCallback(
    settings => {
      updateSettingsMutation({ variables: settings }).then(() => {
        setUserSettings({ ...userSettings, ...settings, loaded: true });
        refetchUserSettings();
      });
    },
    [userSettings, updateSettingsMutation, setUserSettings],
  );

  return (
    <UserSettingsContext.Provider
      value={{
        userSettings,
        userSettingsLoading: loading,
        refetchUserSettings,
        updateUserSettings,
        cachedSettings,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export default (connect(
  mapStateToProps,
  {},
)(UserSettingsProvider): AbstractComponent<Props>);
