/* eslint-disable global-require */
import React from "react";
import PropTypes from "prop-types";
import MarketingInfoBlock from "components/marketing/MarketingInfoBlock";
import ContinueLessonButton from "components/catalog/ContinueLessonButton";
import DqButton from "components/common/elements/DqButton";
import { MARKETING_SITE_CATALOG_URL } from "helpers/constants";

import notFound from "./404.svg";
import rover from "./rover.svg";
import sky from "./stars.svg";
import surface from "./surface.png";

export class NotThereSplash extends React.PureComponent {
  static propTypes = {
    is_logged_in: PropTypes.bool.isRequired,
    missing_page: PropTypes.bool,
    missing_catalog_topic: PropTypes.string,
  };

  static defaultProps = {
    missing_page: false,
    missing_catalog_topic: "",
  };

  render_next_text() {
    if (this.props.is_logged_in) {
      return (
        <p className="dq-text-2xl">
          How about continuing
          <br />
          where you left off?
        </p>
      );
    }
    return (
      <p className="dq-text-2xl">
        Looks like we will have to search on another planet
      </p>
    );
  }

  render_text() {
    const first_line = this.props.missing_page ? (
      <h1 className="dq-text-4xl">Page not found</h1>
    ) : (
      <h1 className="dq-text-4xl">
        This{" "}
        <span className="dq-font-bold">{this.props.missing_catalog_topic}</span>{" "}
        was not found
      </h1>
    );

    return (
      <MarketingInfoBlock centered className="dq-max-w-md">
        {first_line}
        {this.render_next_text()}
      </MarketingInfoBlock>
    );
  }

  render_button() {
    if (this.props.is_logged_in) {
      return (
        <div>
          <ContinueLessonButton />
          <p>
            <DqButton
              text="or go to your dashboard"
              to="/dashboard"
              variant="text"
              size="large"
              disableTitleCase
            />
          </p>
        </div>
      );
    }
    return (
      <DqButton
        text="Explore Dataquest"
        href={MARKETING_SITE_CATALOG_URL}
        data-test-selector="NotThereSplash-catalog"
      />
    );
  }

  render() {
    return (
      <div className="dq-flex dq-flex-col dq-justify-start dq-text-white dq-bg-gray-800">
        <div
          className="dq-flex dq-flex-col dq-justify-end dq-pt-24 dq-text-center dq-bg-cover"
          style={{
            backgroundImage: `url(${sky}), radial-gradient(circle at 50% 108%, #83a2ac, #3e4e64)`,
          }}
        >
          <img className="dq-mx-auto" src={notFound} alt="" />
          {this.render_text()}
          <div
            className="dq-relative dq-w-full dq-h-72 dq-pt-12 dq-bg-cover"
            style={{ backgroundImage: `url(${surface})` }}
          >
            <img
              className="dq-absolute dq-bottom-40"
              src={rover}
              alt=""
              style={{ left: "13%" }}
            />
            {this.render_button()}

            <div className="dq-text-s dq-mt-16">
              <p className="dq-text-lg">Looking for something?</p>
              <DqButton
                text="Explore our catalog"
                size="large"
                href={MARKETING_SITE_CATALOG_URL}
                variant="text"
                disableTitleCase
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotThereSplash;
