import { gql } from "@apollo/client";

export const VALUE_PROPOSITIONS_LOGIN_QUERY = gql`
  query ValuePropositionLoginQuery {
    login_value_proposition {
      image_url
      alt_text
      header
      subheader
      hide_logo
    }
  }
`;

export const VALUE_PROPOSITIONS_SIGNUP_QUERY = gql`
  query ValuePropositionSignupQuery {
    signup_value_proposition {
      image_url
      alt_text
      header
      subheader
      hide_logo
    }
  }
`;
