/* eslint-disable import/prefer-default-export */

// eslint-disable-next-line no-underscore-dangle
const checkRecaptcha = recaptchaRef =>
  __PROD__ && recaptchaRef && recaptchaRef.current;

// eslint-disable-next-line no-underscore-dangle
const skipRecaptcha = displayMFARequest =>
  __DEV__ || __STAGE__ || displayMFARequest;

export { checkRecaptcha, skipRecaptcha };
