// Helper for using Segment
// For now, primarly a way to simplify defensive checks

import dayjs from "dayjs";
import dq_fetch from "../redux/utils/dq_fetch";

const SEGMENT_SPRIG_CUTOFF_DAYS = 5;

export const getTrackingActiveProperty = userJoinedAt => {
  const joinedDate = dayjs(userJoinedAt);
  if (!userJoinedAt) return false;
  return dayjs(Date.now()).diff(joinedDate, "days") > SEGMENT_SPRIG_CUTOFF_DAYS;
};

/**
 * A thin wrapper around the events tracking logic
 *
 * We are currently using Segment/Rudderstack
 */

const getOptions = traits => {
  const options = {
    integrations: {
      All: true,
      Sprig: false,
      "Sprig Cloud": false,
      UserLeap: false,
    },
  };
  if (
    getTrackingActiveProperty(traits?.userJoinedAt) ||
    traits?.trackingActive
  ) {
    options.integrations = {};
  }
  return options;
};

class EventsTracker {
  page = (pathname, ...args) => {
    if (args)
      args[0].dq_session_uid = window?.localStorage?.getItem("dq-session-uid");
    const options = getOptions(args ? args[0] : {});
    window.analytics.page(pathname, ...args, options);
  };

  identify = (id, ...args) => {
    if (args) {
      args[0].dq_session_uid = window?.localStorage?.getItem("dq-session-uid");
    }
    const options = getOptions(args ? args[0] : {});
    window.analytics.identify(id, ...args, options);
  };

  ready = callback => {
    window.analytics.ready(callback);
  };

  trackBackend = (event, properties) => {
    dq_fetch("/api/v2/accounts/track/", {
      post_obj: { event_name: event, event_data: properties },
      type: "POST",
    });
  };

  track = (
    event,
    properties,
    meta = { version: 1, options: {}, callback: null },
  ) => {
    // eslint-disable-next-line no-underscore-dangle
    if (window._DATADOG_SYNTHETICS_BROWSER !== undefined) {
      return;
    }
    if (
      window &&
      window.analytics &&
      typeof window.analytics.track === "function"
    ) {
      const options = {
        ...meta.options,
        ...getOptions(properties),
      };
      properties.dq_session_uid = window?.localStorage?.getItem(
        "dq-session-uid",
      );
      properties.dq_tracking_version = meta.version;
      if (meta.version === 2) {
        // V2 events names must always end with `_frontend`
        if (!event.endsWith("_frontend")) {
          event = `${event}_frontend`;
        }
      }
      window.analytics.track(event, properties, options, meta.callback);
    } else {
      let events = window?.localStorage?.getItem("dq-segment-events");
      events = events ? JSON.parse(events) : [];
      events.push({
        event,
        properties,
        meta,
        timestamp: Date.now(),
      });
      window?.localStorage?.setItem(
        "dq-segment-events",
        JSON.stringify(events),
      );
      console.warn("analytics.track not available");
    }
  };

  trackPending = () => {
    if (
      window &&
      window.analytics &&
      typeof window.analytics.track === "function"
    ) {
      const errEvents = [];
      let events = window?.localStorage?.getItem("dq-segment-events");
      events = events ? JSON.parse(events) : [];
      events.forEach(evt => {
        if (evt.timestamp) evt.properties.original_timestamp = evt.timestamp;
        try {
          window.analytics.track(
            evt.event,
            evt.properties,
            evt.meta ? evt.meta.options : undefined,
            evt.meta ? evt.meta.callback : undefined,
          );
        } catch (e) {
          // In case if window reference has changed but not to the correct one
          errEvents.push(evt);
        }
      });
      window?.localStorage?.removeItem("dq-segment-events");
      if (errEvents.length) {
        window?.localStorage?.setItem(
          "dq-segment-events",
          JSON.stringify(errEvents),
        );
      }
    }
  };

  trackContentCompletion = (pathSequence, courseSequence, lessonSequence) =>
    dq_fetch("/api/v2/progress/track-completion/", {
      type: "POST",
      post_obj: {
        path_sequence: pathSequence,
        course_sequence: courseSequence,
        lesson_sequence: lessonSequence,
      },
    });
}

export default new EventsTracker();
