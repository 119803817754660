const places = 2;
const MONTHS = {
  monthly: 1,
  quarterly: 3,
  annual: 12,
  lifetime: 1,
};

export default function totalPrice(price, planDetails) {
  if (planDetails && planDetails.is_lifetime) {
    return Number(price).toString();
  }
  if (planDetails && planDetails.period_months)
    return Number(price * planDetails.period_months)
      .toFixed(places)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  let period = "annual";
  if (planDetails && planDetails.billing_period)
    period = planDetails.billing_period;
  return Number(price * MONTHS[period])
    .toFixed(places)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Apply referral discount to a monthly price for a selected period
 * @param {number} monthlyPrice     monthly base price for selected plan period
 * @param {object} discount         discount info object
 * @param {string} selectedPeriod   value must be defined in MONTHS object above
 * @returns {number}                monthly discounted price for plan period
 */
export function applyReferralDiscount(monthlyPrice, discount, selectedPeriod) {
  let calculatedPrice = monthlyPrice;
  const months = MONTHS[selectedPeriod.split("-")[0]];
  if (
    discount &&
    (discount.subscription_period === null ||
      discount.subscription_period === selectedPeriod)
  ) {
    let price = monthlyPrice * months;
    if (discount.amount_off) price -= discount.amount_off;
    else if (discount.percent_off) price *= 1 - discount.percent_off / 100;
    calculatedPrice = price / months;
  }

  return calculatedPrice;
}

export function makePrettyNumber(number, cleanDigits = true) {
  let num = number === null ? 0 : number;
  if (typeof num === "string") {
    num = num.replace(/,/g, "");
    num = parseFloat(num);
  }
  const fix_places = 2;
  const match = ".00";
  const as_string = num.toFixed(fix_places);
  if (!cleanDigits) return as_string;
  return as_string.endsWith(match)
    ? as_string.substr(0, as_string.length - match.length)
    : as_string;
}
