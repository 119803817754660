/* eslint-disable import/prefer-default-export */
/**
 * This basic utility function is used to format string values for
 * graphql operations. It basically replaces " characters with
 * escaped versions.
 *
 * @param {string} value
 * @returns {string}
 */
export const escapeGraphQLString = value => {
  if (value) return `${value}`.replace(/"/g, '\\"');
  return value;
};
