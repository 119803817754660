/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const USER_REFERRAL_REWARDS_QUERY = gql`
  query ReferralRewards {
    rewards: referral_rewards {
      rewardReferrer: reward_referrer {
        type
        units
        requiredSubs: required_subs_count
      }
      rewardReferee: reward_referee {
        type
        units
      }
      rewardEarned: reward_earned
      end_date
      balance: reward_balance {
        balance
        redeemed
        total
      }
      invites: referral_invites
    }
  }
`;

export const REFERRAL_REWARDS_QUERY = gql`
  query ReferreeRewards {
    rewards: referral_rewards {
      rewardReferrer: reward_referrer {
        type
        units
        requiredSubs: required_subs_count
      }
      rewardReferee: reward_referee {
        type
        units
      }
    }
  }
`;

export const REDEEM_REWARD_MUTATION = gql`
  mutation REDEEM_REWARD($amount: Int!) {
    withdraw_referral_balance(amount: $amount) {
      success
    }
  }
`;
