import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import map from "lodash/map";
import {
  IoLogoFacebook,
  IoLogoGithub,
  IoLogoLinkedin,
  IoLogoGoogle,
} from "react-icons/io5";
import DqBackdrop from "components/common/overlays/DqBackdrop";

const EXISTING_USER_REDIRECT_URI = "/api/v1/accounts/social_auth_done/";
const NEW_USER_REDIRECT_URI = "/api/v1/accounts/social_auth_new_user/";

const socialNetworkIcons = {
  google: <IoLogoGoogle />,
  facebook: <IoLogoFacebook />,
  github: <IoLogoGithub />,
  linkedin: <IoLogoLinkedin />,
};

export class SocialAuthButton extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(["google", "facebook", "github", "linkedin"])
      .isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
    // dependences
    // eslint-disable-next-line react/forbid-prop-types
    the_window: PropTypes.any,

    // specification
    full_width_button: PropTypes.bool,
  };

  static defaultProps = {
    the_window: window,
    full_width_button: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      authOpen: false,
    };
  }

  componentWillUnmount() {
    this.closeAuth();
  }

  getPopupOptions = () => {
    const min_size = 500;
    const half = 2;
    const less_than_half = 2.5;
    const { the_window } = this.props;
    const width = Math.min(the_window.innerWidth, min_size);
    const height = Math.min(the_window.innerHeight, min_size);
    const options = {
      width,
      height,
      left: the_window.screenX + (the_window.outerWidth - width) / half,
      top:
        the_window.screenY + (the_window.outerHeight - height) / less_than_half,
    };
    return map(options, (value, key) => `${key}=${value}`).join(",");
  };

  getUrl = () => {
    let oauthUrl;
    switch (this.props.type) {
      case "google":
        oauthUrl = "google-oauth2";
        break;
      case "linkedin":
        oauthUrl = "linkedin-oauth2";
        break;
      default:
        oauthUrl = this.props.type;
    }

    return `/api/v1/accounts/login/${oauthUrl}`;
  };

  authSuccess = isNew => {
    this.closeAuth();
    this.props.onSuccess(isNew);
  };

  authFailure = message => {
    this.closeAuth();
    this.props.onFailure(message);
  };

  closeAuth = () => {
    this.setState({ authOpen: false });
    if (this.popup && this.popup.close) {
      this.popup.close();
    }
    this.popup = undefined;
  };

  thePoll = () => {
    if (!this.state.authOpen) {
      window.clearInterval(this.intervalId);
      return;
    }

    try {
      const current_url = get(this.popup, ["location", "pathname"], "");

      if (current_url === EXISTING_USER_REDIRECT_URI) {
        window.clearInterval(this.intervalId);
        this.authSuccess(false);
        return; // done!
      }
      if (current_url === NEW_USER_REDIRECT_URI) {
        this.authSuccess(true);
        return;
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    if (!this.popup) {
      window.clearInterval(this.intervalId);
      this.authFailure("Provider Popup Blocked");
    } else if (this.popup.closed !== false) {
      window.clearInterval(this.intervalId);
      this.authFailure("Authorization Failed");
    }
  };

  setupPollingListeners = () => {
    const delay = 100;
    this.intervalId = window.setInterval(this.thePoll, delay);
  };

  openAuthWindow = () => {
    this.setState({ authOpen: true });
    this.popup = window.open(
      this.getUrl(),
      this.props.type,
      this.getPopupOptions(),
    );

    this.setupPollingListeners();
    if (this.popup && this.popup.focus) {
      this.popup.focus();
    }
  };

  renderShield() {
    if (!this.state.authOpen) {
      return null;
    }
    return <DqBackdrop onClick={this.closeAuth} />;
  }

  render() {
    const { type } = this.props;

    return (
      <div
        onClick={this.openAuthWindow}
        className={this.props.full_width_button ? "dq-flex-grow" : "dq-p-2 "}
      >
        <button
          type="button"
          className="dq-w-full dq-inline-flex dq-items-center dq-justify-center dq-border-2 dq-border-gray-800 dq-text-gray-800 dq-px-4 dq-py-2 dq-text-base dq-transition-colors focus:dq-outline-none focus:dq-ring-2 focus:dq-ring-offset-2 focus:dq-ring-indigo-500"
        >
          {socialNetworkIcons[type]}
        </button>
        {this.renderShield()}
      </div>
    );
  }
}

export default SocialAuthButton;
