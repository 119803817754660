// @flow
import dq_fetch, { dq_graphql } from "redux/utils/dq_fetch";
import { private_actions } from "./payment_info_simple";
import type { State } from "./payment_info_simple";

type Dispatch = (action: Action | ThunkAction | PromiseAction) => any;
type GetState = () => State;
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
type Action = () => ThunkAction;
type PromiseAction = Promise<Action>;

export const fetchReferralDiscount: Action = () => (
  dispatch: Dispatch,
  _getState: GetState,
) => {
  const graphql: string = `{
    referral_discount {
      amount,
      type,
      subscription_period
    }
  }`;

  return dq_graphql(graphql)
    .then(data => {
      dispatch(private_actions.setReferralDiscount(data.data));
    })
    .catch(console.warn);
};

export const getPaymentKeys: Action = (payment_success: ?boolean) => (
  dispatch: Dispatch,
  _getState: GetState,
) =>
  dq_fetch("/api/v2/accounts/payment_info/")
    .then(data => {
      const newData = { ...data };
      if (payment_success) newData.payment_success = payment_success;
      dispatch(private_actions.setInfo(newData));
    })
    .catch(console.warn);

export const getPortalSession: Action = () => (
  _dispatch: Dispatch,
  _getState: GetState,
) =>
  dq_fetch("/api/v2/accounts/portal_session/")
    .then(data => JSON.parse(data.portal_session))
    .catch(console.warn);
