import React from "react";
import PropTypes from "prop-types";
import get_uuid from "helpers/get_uuid";
import { connect } from "react-redux";

import { actions as user_actions } from "redux/modules/user_info";

import DqAlertDialog from "components/common/feedback/dialog/DqAlertDialog";
import { window_location } from "helpers/window_location";

export const actions = {
  ...user_actions,
};

const DISCOURSE_UUID_KEY = "discourse-sso-login-lock";

export class DiscourseSSOView extends React.PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    send_sso_request: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showAlertDialog: false,
      alertDialogProps: {},
    };
  }

  componentDidMount() {
    const uuid = get_uuid();
    window?.localStorage.setItem(DISCOURSE_UUID_KEY, uuid);
    this.props
      .send_sso_request(window.location.search)
      .then(data => {
        if (window?.localStorage.getItem(DISCOURSE_UUID_KEY) !== uuid) return;

        if (data.unauthorized) {
          this.showUnauthorizedWarning();
        } else {
          window_location.redirect(data.url);
        }
      })
      .catch(() => this.showProblem());
  }

  showProblem() {
    this.setState({
      showAlertDialog: true,
      alertDialogProps: {
        hideCancelBtn: true,
        title: "Problem with Logging in",
        message:
          "There was a problem logging in to the community - Please try again",
        actionsConfirmText: "OK",
        handleOnConfirm: () => this.props.history.push("/"),
      },
    });
  }

  showUnauthorizedWarning() {
    this.setState({
      showAlertDialog: true,
      alertDialogProps: {
        hideCancelBtn: true,
        title: "Problem with Logging in",
        message:
          "You don't have access to our Community - please subscribe to join!",
        actionsConfirmText: "OK",
        handleOnConfirm: () => this.props.history.push("/"),
      },
    });
  }

  render() {
    const { showAlertDialog, alertDialogProps } = this.state;

    return showAlertDialog && <DqAlertDialog {...alertDialogProps} />;
  }
}

export default connect(
  null,
  actions,
)(DiscourseSSOView);
