import kebabCase from "lodash/kebabCase";
import toLower from "lodash/toLower";

export const make_url_from_slug_or_title = ({ title, slug }) => {
  if (slug) {
    return slug;
  }

  return kebabCase(toLower(title));
};

// eslint-disable-next-line complexity
export default (lessonObj, step = {}, loggedIn = true, pathInfo = null) => {
  if (!lessonObj || !lessonObj.sequence) return null;

  if (!step && lessonObj.type === "assessment")
    return `/m/assessment-intro/${lessonObj.sequence}`;
  let url = `/m/${lessonObj.sequence}/${make_url_from_slug_or_title(
    lessonObj,
  )}`;

  if (lessonObj.course_sequence && loggedIn)
    url = `/c/${lessonObj.course_sequence}${url}`;

  // if (step.current_step && step.anonymousView) url += `/${step.current_step}`;
  if (step.step_index != null) {
    url += `/${step.step_index + 1}`;
    if (step.step_title) {
      url += `/${make_url_from_slug_or_title({ title: step.step_title })}`;
    }
  }

  if (pathInfo?.sequence || pathInfo?.slug) {
    const searchInfo = new URLSearchParams();
    const sequenceName = pathInfo?.isCourse ? "course" : "path";
    if (pathInfo.sequence) searchInfo.append(sequenceName, pathInfo.sequence);
    if (pathInfo.slug) searchInfo.append("slug", pathInfo.slug);
    if (pathInfo.version) searchInfo.append("version", pathInfo.version);
    url += `?${searchInfo.toString()}`;
  }
  return url;
};
