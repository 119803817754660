import { useQuery } from "@apollo/client";

import {
  VALUE_PROPOSITIONS_LOGIN_QUERY,
  VALUE_PROPOSITIONS_SIGNUP_QUERY,
} from "queries/valuePropositions/queries";

export const useValuePropositionsLoginQuery = (opts = {}) =>
  useQuery(VALUE_PROPOSITIONS_LOGIN_QUERY, opts);

export const useValuePropositionsSignupQuery = (opts = {}) =>
  useQuery(VALUE_PROPOSITIONS_SIGNUP_QUERY, opts);
