import React from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectors as user_selectors } from "redux/modules/user_info";
import { DqFeatureFlagService } from "./DqFeatureFlagService";
import { ABTestUserData } from "./ABTestUserData";
import feature_config from "./feature_config";

export const ABTestContext = React.createContext(null);

const actions = {};

const selectors = {
  user_name: user_selectors.user_name,
  is_staff: user_selectors.is_staff,
  user_id: user_selectors.user_id,
};

class ABTestProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.element.isRequired,
    user_name: PropTypes.string.isRequired,
    is_staff: PropTypes.bool.isRequired,
    user_id: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {
      client: {
        get: () => 0,
      },
      // allows for blocking rendering until all data is initialized
      // useful for testing variants of the whole page -- prevents "flashing" of a default case
      ready: false,
    };
  }

  componentDidUpdate(prevProps) {
    const propsToCompare = ["is_staff", "user_name", "user_id"];

    if (
      !isEqual(
        pick(prevProps, propsToCompare),
        pick(this.props, propsToCompare),
      )
    ) {
      this.initialize(this.props);
    }
  }

  initialize = nextProps => {
    const { is_staff, user_id, user_name } = nextProps;
    const user_data = new ABTestUserData(is_staff, user_name, user_id);

    try {
      const ab_test_client = new DqFeatureFlagService(feature_config);

      const client = {
        get(experiment_name) {
          return ab_test_client.get(experiment_name, user_data);
        },
      };

      this.setState({ client, ready: true });
    } catch (e) {
      console.error("error loading a/b test client", e);
      // we need to set ready to true to prevent a case where some component blocks rendering until ready === true
      // with an exception in the try block, user would'd get an empty page
      this.setState({ ready: true });
    }
  };

  render() {
    return (
      <ABTestContext.Provider value={this.state}>
        {this.props.children}
      </ABTestContext.Provider>
    );
  }
}

export default connect(
  createStructuredSelector(selectors),
  actions,
)(ABTestProvider);
