import EventsTracker from "helpers/events_tracker";

const OnboardingTracking = {
  startOnboarding({ featureFlags }) {
    EventsTracker.track(
      "onboarding_start",
      {
        featureFlags,
      },
      {
        version: 2,
      },
    );
  },
  completeOnboarding({
    mission_sequence,
    path_sequence,
    path_name,
    featureFlags,
  }) {
    EventsTracker.track(
      "onboarding_complete",
      {
        starting_mission_sequence: mission_sequence,
        path_sequence,
        path_name,
        featureFlags,
      },
      {
        version: 3,
      },
    );
  },
};

export default OnboardingTracking;
