import Immutable from "seamless-immutable";
import { createSelector } from "reselect";
import {
  make_simple_selectors,
  make_reducer_n_actions,
  make_simple_reducer,
} from "redux_helpers";
import get from "lodash/get";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import toString from "lodash/toString";
import find from "lodash/find";

// -------
// Initial State
// --------

const initial_state = {
  team_name: "",
  owner_id: "",
  admin_ids: [],
  teammembers: [],
  team_loaded: false,
  invitationsLoaded: false,
  invitations: [],
  assignments: [],
  inviteResponse: {
    sent: false,
    not_found: false,
    already_sent: false,
    already_member: false,
    error_message: null,
  },
  inviteMultiResponse: {
    invited: [],
    failed: [],
    error_message: null,
  },
  removeResponse: {
    success: false,
    error_message: null,
  },
  removeMultiResponse: {
    removed: [],
    failed: [],
    error_message: null,
  },
  invoices: [],
  reports: [],
  settings: {},
  plan_info: {
    plan_name: "",
    active: true,
    cc_last_4: "----",
    plan_end_date: null,
    member_count: 0,
  },
  team_plan_name: null,
  new_team_error: "",
  acceptError: false,
  acceptErrorMessage: "",

  api_key_generated: false,
  api_key_last_updated: null,
  api_key_last_four: null,
  saml_idp_id: null,
  saml_idp_url: null,
  saml_idp_cert: null,
  saml_begin_url: null,
  saml_recipient_url: null,
  saml_metadata_url: null,
  saml_audience: "https://www.dataquest.io",

  co_branding: false,
  logo_url: null,
  signature_url: null,
  signature_name: null,
  signature_title: null,
  brand_banner: null,
  hide_team_name: null,
};

// -------
// Selectors
// --------
import { selectors as user_selectors } from "redux/modules/user_info";
import { SUBSCRIPTION_TYPE_FREE } from "../../../helpers/constants";

const BASE = "team_info";
export { BASE as BASE_SELECTOR_PATH };

const simple_selectors = make_simple_selectors(initial_state, BASE);

const isOwner = createSelector(
  state => user_selectors.user_id(state),
  simple_selectors.owner_id,
  (user_id, owner_id) => Boolean(user_id && owner_id && user_id === owner_id),
);

const isAdmin = createSelector(
  state => user_selectors.user_id(state),
  simple_selectors.admin_ids,
  (user_id, admin_ids) => {
    const userId =
      typeof user_id === "string" ? parseInt(user_id, 10) : user_id;
    return Boolean(user_id && admin_ids && admin_ids.indexOf(userId) >= 0);
  },
);

const teammembers = createSelector(
  simple_selectors.teammembers,
  raw => sortBy(raw, "fullname"),
);

export const selectors = {
  ...simple_selectors,
  isOwner,
  isAdmin,
  teammembers,
};

// ------------------------------------
// Reducer and Actions
// ------------------------------------
const action_types_prefix = "team_info/";

const public_handlers = {
  reset: () => Immutable(initial_state),
  clearResponse: state =>
    state.merge({
      inviteResponse: initial_state.inviteResponse,
      removeResponse: initial_state.removeResponse,
      inviteMultiResponse: initial_state.inviteMultiResponse,
      removeMultiResponse: initial_state.removeMultiResponse,
    }),
};

function basic_info(user) {
  return {
    user_id: toString(user.id),
    user_name: user.username,
    fullname: user.fullname,
    email: user.email,
    updated: new Date(user.updated),
    start_date: user.start_date ? new Date(user.start_date) : null,
  };
}

const private_handlers = {
  set_loaded: make_simple_reducer("team_loaded"),
  set_team_info: (state, { payload }) =>
    state.merge({
      team_name: payload.name,
      owner_id: toString(payload.owner_id),
      admin_ids: payload.admin_ids || [],
      settings: payload.settings || {},
      plan_info: {
        plan_name: get(payload, "plan.plan_service", SUBSCRIPTION_TYPE_FREE),
        active: get(payload, "plan.active", true),
        cc_last_4: get(payload, "plan.card_last4", "----"),
        plan_end_date: get(payload, "plan.plan_end_date")
          ? new Date(payload.plan.plan_end_date)
          : null,
        member_count: payload.member_count,
        licenses: payload.licenses,
        remainingLicenses: payload.remaining_licenses,
        assignedLicenses: payload.licenses
          ? payload.licenses - payload.remaining_licenses
          : 0,
      },
      team_plan_name: get(payload, "plan.plan_service", SUBSCRIPTION_TYPE_FREE),
      api_key_generated: get(payload, "api_key_generated", false),
      api_key_last_updated: get(payload, "api_key_last_updated", null),
      api_key_last_four: get(payload, "api_key_last_four", null),
      saml_idp_id: get(payload, "saml_idp_id", null),
      saml_idp_url: get(payload, "saml_idp_url", null),
      saml_idp_cert: get(payload, "saml_idp_cert", null),
      saml_begin_url: get(payload, "saml_begin_url", null),
      saml_recipient_url: get(payload, "saml_recipient_url", null),
      saml_metadata_url: get(payload, "saml_metadata_url", null),
      brand_banner: get(payload, "brand_banner", false),
      hide_team_name: get(payload, "hide_team_name", false),
      co_branding: get(payload, "co_branding", false),
      logo_url: get(payload, "logo_url", null),
      signature_url: get(payload, "signature_url", null),
      signature_name: get(payload, "signature_name", null),
      signature_title: get(payload, "signature_title", null),
    }),
  setTeammembers: (state, { payload }) =>
    state.merge({
      teammembers: map(payload.members, member => ({
        ...basic_info(member),
        courseProgress: member.current_course_progress,
        hasContentAccess: find(
          payload?.members_access || [],
          memberAccess =>
            parseInt(memberAccess.user_id, 10) === parseInt(member.id, 10),
        )?.hasContentAccess,
        hoursCurrentWeek: member.hours_current_week,
        hoursTotal: member.hours_total,
        paths: map(member.path_progress, path => ({
          title: path.name,
          progress: path.progress,
        })),
        password_reset_url: member.password_reset_url,
      })),
    }),
  setInvitations: (state, { payload }) =>
    state.merge({
      invitations: map(payload, invite => ({
        ...basic_info(invite.user),
        updated: new Date(invite.updated),
        isAdmin: invite.is_admin || false,
        hasContentAccess: invite.content_access || false,
      })),
      invitationsLoaded: true,
    }),
  setInvitationsLoaded: (state, {}) =>
    state.merge({
      invitationsLoaded: true,
    }),
  setInviteResponse: (state, { payload }) =>
    state.merge({
      inviteResponse: {
        ...initial_state.inviteResponse,
        ...payload,
      },
    }),
  setInviteMultiResponse: (state, { payload }) =>
    state.merge({
      inviteMultiResponse: {
        ...initial_state.inviteMultiResponse,
        ...payload,
      },
    }),
  setRemoveResponse: (state, { payload }) =>
    state.merge({
      removeResponse: {
        ...initial_state.removeResponse,
        ...payload,
      },
    }),
  setRemoveMultiResponse: (state, { payload }) =>
    state.merge({
      removeMultiResponse: {
        ...initial_state.removeMultiResponse,
        ...payload,
      },
    }),
  setAcceptError: state => state.merge({ acceptError: true }),
  setAcceptErrorMessage: (state, { payload }) =>
    state.merge({ acceptErrorMessage: payload, acceptError: true }),
  setAssignments: (state, { payload }) => state.merge({ assignments: payload }),
};

export const {
  reducer,
  private_actions,
  actions,
  ACTION_TYPES,
} = make_reducer_n_actions({
  public_handlers,
  private_handlers,
  action_types_prefix,
  initial_state,
  Immutable,
});
export default reducer;
