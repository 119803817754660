import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";
import Cookies from "js-cookie";
import get from "lodash/get";
import {
  BETA_PATH_PART_SLUGS,
  BETA_PATH_SEQUENCES,
  BETA_COURSE_SEQUENCES,
} from "helpers/constants";

const httpLink = createHttpLink({
  uri: "/api/graphql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = get(Cookies.getJSON("token"), ["token"], "");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Token ${token}` : "",
    },
  };
});

// setup apollo cache
export const cache = new InMemoryCache({
  possibleTypes: {
    PathType: ["PathType", "TrackType"],
    PathPartType: ["PathStepType", "TrackStepType"],
  },
  typePolicies: {
    MissionType: {
      keyFields: ["sequence"],
      fields: {
        screenCount: {
          read(screenCount = 0) {
            return screenCount;
          },
        },
      },
    },
    CourseType: {
      keyFields: ["sequence"],
      fields: {
        outlineOpen: {
          read(outlineOpen = false) {
            return outlineOpen;
          },
        },
        beta: {
          read(_, { readField }) {
            return (
              BETA_COURSE_SEQUENCES.indexOf(`${readField("sequence")}`) !== -1
            );
          },
        },
        current: {
          read(current = false) {
            return current;
          },
        },
      },
    },
    PathType: {
      keyFields: ["sequence", "version"],
      fields: {
        dashboardOutlineOpen: {
          read(dashboardOutlineOpen = false) {
            return dashboardOutlineOpen;
          },
        },
        beta: {
          read(_, { readField }) {
            if (!readField || !readField("sequence")) return false;
            return (
              BETA_PATH_SEQUENCES.indexOf(`${readField("sequence")}`) !== -1
            );
          },
        },
      },
    },
    PathPartType: {
      keyFields: ["sequence", "name", "slug"],
      fields: {
        dashboardOutlineOpen: {
          read(dashboardOutlineOpen = false) {
            return dashboardOutlineOpen;
          },
        },
        beta: {
          read(_, { readField }) {
            if (!readField || !readField("sequence")) return false;
            return BETA_PATH_PART_SLUGS.indexOf(`${readField("name")}`) !== -1;
          },
        },
        current: {
          read(current = false) {
            return current;
          },
        },
      },
    },
    ProgressType: {
      merge: true,
    },
    AgendaType: {
      keyFields: [],
    },
    CachedSettingsType: {
      keyFields: [],
      fields: {
        saveProgressNotificationShown: {
          read(saveProgressNotificationShown = false) {
            return saveProgressNotificationShown;
          },
        },
      },
    },
  },
});

// await before instantiating ApolloClient, else queries might run before the cache is persisted
export const cachePersistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});

export const restoreApolloCache = async () => {
  await cachePersistor.restore();
};

// create apollo client instance
const createClient = () =>
  new ApolloClient({
    link: authLink?.concat(httpLink),
    cache,
    connectToDevTools: process.env.NODE_ENV === "development",
  });

export default createClient;
