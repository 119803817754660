// @flow
import type { Node } from "react";
import React from "react";
import cn from "classnames";
import { IoCloseSharp } from "react-icons/io5";

type Props = {
  /**
   * The text to display within the badge. When passing a string the component will render a span HTML tag, otherwise it will wrap the content in a div.
   */
  text: Node | string,
  /**
   * Add additional style classes
   */
  className?: string,
  /**
   * Color of the badge
   */
  color?: "blue" | "gray" | "gold" | "white" | "purple" | "light-purple",
  /**
   * Display the badge as selected
   */
  selected?: boolean,
  /**
   * Size of the badge
   */
  size?: "sm" | "md" | "lg",
  /**
   * Function to execute when clicking the badge. When passed the component will render an HTML button tag instead of a span
   */
  onClick?: any,
  /**
   * Function to execute when clicking the remove icon
   */
  handleRemoveOnClick?: any,
};

/**
 * DqBadge component for displaying badges with different colors and styles.
 */
const DqBadge = ({
  color = "gray",
  selected = false,
  size = "sm",
  text,
  onClick = null,
  handleRemoveOnClick = null,
  className,
}: Props): Node => {
  const TagElement = typeof text === "string" ? "span" : "div";
  const commonClasses = "dq-inline-flex dq-items-center";
  const sizeClasses = {
    sm: "dq-px-2.5 dq-py-0.5 dq-text-sm",
    md: "dq-px-4 dq-py-0.5",
    lg: "dq-space-x-1 dq-px-4 dq-py-1",
  };
  const colorClasses = {
    blue: "dq-bg-blue dq-text-white",
    purple: "dq-bg-purple dq-text-white",
    gray: "dq-bg-gray-100 dq-text-gray-800",
    gold: "dq-bg-gold dq-text-gray-800",
    white: "dq-border dq-border-gray-100 dq-text-gray-400",
    "light-purple": "dq-bg-purple-500",
  };

  if (onClick) {
    return (
      <button
        type="button"
        className={cn(
          commonClasses,
          sizeClasses[size],
          colorClasses[color],
          {
            "dq-bg-blue-200 dq-text-blue": selected && color === "gray",
            "dq-cursor-pointer": onClick,
            "hover:dq-text-blue": onClick && color === "gray",
          },
          className,
        )}
        onClick={selected ? handleRemoveOnClick : onClick}
      >
        <span>{text}</span>
        {selected && handleRemoveOnClick && <IoCloseSharp />}
      </button>
    );
  }

  return (
    <TagElement
      className={cn(
        commonClasses,
        sizeClasses[size],
        colorClasses[color],
        {
          "dq-bg-blue-200 dq-text-blue": selected && color === "gray",
        },
        className,
      )}
    >
      {text}
    </TagElement>
  );
};

DqBadge.defaultProps = {
  className: "",
  color: "gray",
  selected: false,
  size: "sm",
  onClick: null,
  handleRemoveOnClick: null,
};

export default DqBadge;
