import Immutable from "seamless-immutable";
import { make_simple_selectors, make_reducer_n_actions } from "redux_helpers";

// -------
// Initial State
// --------

const initial_state = {
  claimProcessed: false,
  claimError: false,
  claimErrorMessage: "",
};

// -------
// Selectors
// --------

const BASE = "gift_info";
export { BASE as BASE_SELECTOR_PATH };

const simple_selectors = make_simple_selectors(initial_state, BASE);

export const selectors = {
  ...simple_selectors,
};

// ------------------------------------
// Reducer and Actions
// ------------------------------------
const action_types_prefix = "gift_info/";

const public_handlers = {
  reset: () => Immutable(initial_state),
};

const private_handlers = {
  setClaimSuccess: state =>
    state.merge({
      claimProcessed: true,
      claimError: false,
      claimErrorMessage: "",
    }),
  setClaimError: state => state.merge({ claimError: true }),
  setClaimErrorMessage: (state, { payload }) =>
    state.merge({ claimErrorMessage: payload, claimError: true }),
};

export const {
  reducer,
  private_actions,
  actions,
  ACTION_TYPES,
} = make_reducer_n_actions({
  public_handlers,
  private_handlers,
  action_types_prefix,
  initial_state,
  Immutable,
});
export default reducer;
