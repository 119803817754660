import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import SocialAuthButton from "./SocialAuthButton";

const SocialSignOn = props => (
  <div
    className={cn(
      "dq-flex dq-justify-between dq-flex-nowrap dq-mb-4 dq-space-x-2 sm:dq-space-x-3",
      props.className,
    )}
  >
    <SocialAuthButton
      type="google"
      full_width_button
      onSuccess={isNew => props.onSuccess("google", isNew)}
      onFailure={() => props.onFailure("google")}
    />
    <SocialAuthButton
      type="github"
      full_width_button
      onSuccess={isNew => props.onSuccess("github", isNew)}
      onFailure={() => props.onFailure("github")}
    />
    <SocialAuthButton
      type="linkedin"
      full_width_button
      onSuccess={isNew => props.onSuccess("linkedin", isNew)}
      onFailure={() => props.onFailure("linkedin")}
    />
  </div>
);

SocialSignOn.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SocialSignOn.defaultProps = {
  className: "",
};

export default SocialSignOn;
