import { dq_graphql } from "redux/utils/dq_fetch";

import { private_actions } from "./paywall_info_simple";

export const fetchPaywallSettings = () => (dispatch, _getState) => {
  const graphql = `{
    lesson_paywall {
      paywall_type,
      cta_text,
      cta_url,
      secondary_cta_text,
      secondary_cta_url,
      header,
      subheader,
      show_countdown_timer,
      features,
      end,
    }
  }`;

  return dq_graphql(graphql).then(data => {
    dispatch(
      private_actions.updateLessonPaywallSettings(
        data?.data?.lesson_paywall || [],
      ),
    );
  });
};
