import Immutable from "seamless-immutable";
import { createSelector } from "reselect";
import { make_simple_selectors, make_reducer_n_actions } from "redux_helpers";
import forEach from "lodash/forEach";
import defaultTo from "lodash/defaultTo";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import map from "lodash/map";
import pick from "lodash/pick";
import reduce from "lodash/reduce";

import mergeTestDataSteps from "helpers/merge_steps_data";
import { BETA_PATH_SEQUENCES } from "helpers/constants";

import cleanLessons, {
  clean_path_base,
  cleanLesson,
} from "redux/helpers/clean_lessons";
import { selectors as progress_selectors } from "../progress";

// -------
// Initial State
// --------

const initial_state = {
  full_directory: [],
  paths: [],
  course_for_page: {
    title: "",
    meta_title: "",
    description: "",
    meta_description: "",
    progress: 0,
    slug: "",
    in_paths: [],
    duration: null,
    rating: null,
    rating_count: null,
    skill_level: null,
    learn: [],
    missions: [],
  },
  course_not_found: false,
  path_for_page: {
    id: "",
    sequence: null,
    title: "",
    meta_title: "",
    description: "",
    meta_description: "",
    progress: 0,
    selected: false,
    beta: false,
    slug: "",
    duration: null,
    rating: null,
    rating_count: null,
    skill_level: null,
    type: "",
    learn: [],
    steps: [],
    skill_topics: [],
  },
  injectLesson: {},
  user_data_for_page: {},
  path_not_found: false,
  currentLesson: {
    sequence: "",
  },
  currentScreen: {},
};

// -------
// Selectors
// --------
const BASE = "catalog_info";
export { BASE as BASE_SELECTOR_PATH };

const simple_selectors = make_simple_selectors(initial_state, BASE);

const add_progress = progress_list => entry => {
  const [current_list, ...children] = progress_list;
  const result = {
    ...entry,
    progress: defaultTo(current_list.progresses[entry.sequence], 0),
  };
  if (children.length > 0) {
    result[children[0].name] = entry[children[0].name].map(
      add_progress(children),
    );
  }
  return result;
};

const full_directory = createSelector(
  simple_selectors.full_directory,
  progress_selectors.paths_progress,
  progress_selectors.path_steps_progress,
  progress_selectors.courses_progress,
  progress_selectors.lessonsProgress,
  (
    directory,
    paths_progress,
    path_steps_progress,
    courses_progress,
    lessonsProgress,
  ) =>
    // eslint-disable-line
    directory.map(
      add_progress([
        { name: "paths", progresses: paths_progress },
        { name: "steps", progresses: path_steps_progress },
        { name: "courses", progresses: courses_progress },
        { name: "missions", progresses: lessonsProgress },
      ]),
    ),
);

const all_paths = createSelector(
  simple_selectors.paths,
  progress_selectors.paths_progress,
  (paths, paths_progress) =>
    // eslint-disable-line
    paths.map(add_progress([{ name: "paths", progresses: paths_progress }])),
);

const course_for_page = createSelector(
  simple_selectors.course_for_page,
  progress_selectors.courses_progress,
  progress_selectors.lessonsProgress,
  (course, courses_progress, lessonsProgress) =>
    // eslint-disable-line
    add_progress([
      { name: "courses", progresses: courses_progress },
      { name: "missions", progresses: lessonsProgress },
    ])(course),
);

const all_courses = createSelector(
  full_directory,
  all => {
    const courses = {};
    forEach(all, path =>
      path.steps.forEach(step =>
        step.courses.forEach(course => {
          courses[course.slug] = course;
        }),
      ),
    );

    return map(courses);
  },
);

const next_child_key_name = {
  steps: "courses",
  courses: "missions",
};

const merge_user_data_in = (user_data, child_key) => (path, index) => {
  const this_user_data = user_data[index] || {};

  const merged = {
    ...path,
    is_behind_paywall: defaultTo(this_user_data.is_behind_paywall, false),
    is_hidden: defaultTo(this_user_data.is_hidden, false),
    estimated_completion_hours: defaultTo(
      this_user_data.estimated_completion_hours,
      2,
    ),
  };
  if (child_key != null && this_user_data[child_key] != null) {
    merged[child_key] = path[child_key]
      .map(
        merge_user_data_in(
          this_user_data[child_key],
          next_child_key_name[child_key],
        ),
      )
      .filter(ch => !ch.is_hidden);
  }
  return merged;
};

const path_for_page = createSelector(
  simple_selectors.path_for_page,
  simple_selectors.user_data_for_page,
  simple_selectors.injectLesson,
  progress_selectors.paths_progress,
  progress_selectors.path_steps_progress,
  progress_selectors.courses_progress,
  progress_selectors.lessonsProgress,
  (
    path,
    user_data,
    injectLesson,
    paths_progress,
    path_steps_progress,
    courses_progress,
    lessonsProgress,
  ) => {
    // eslint-disable-line
    let updatedPath = mergeTestDataSteps(injectLesson, path);
    updatedPath = merge_user_data_in([user_data], "steps")(updatedPath, 0);

    return add_progress([
      { name: "paths", progresses: paths_progress },
      { name: "steps", progresses: path_steps_progress },
      { name: "courses", progresses: courses_progress },
      { name: "missions", progresses: lessonsProgress },
    ])(updatedPath);
  },
);

const current_path_step_index = createSelector(
  path_for_page,
  simple_selectors.currentLesson,
  ({ steps }, { sequence }) => {
    if (steps.length === 0) return 0;
    if (!sequence) return 0;

    // find lesson
    let step_index;
    steps.forEach(({ courses }, index) => {
      if (step_index != null) return;

      courses.forEach(({ missions }) => {
        if (step_index != null) return;

        missions.forEach(lesson => {
          if (lesson.sequence === sequence) {
            step_index = index;
          }
        });
      });
    });
    return defaultTo(step_index, 0);
  },
);

const by_id = (results, lesson) => ({
  ...results,
  [lesson.sequence]: lesson,
});

const allLessonsById = createSelector(
  full_directory,
  paths =>
    reduce(
      flatMap(flatMap(flatMap(paths, "steps"), "courses"), "missions"),
      by_id,
      {},
    ),
);

export const selectors = {
  ...simple_selectors,
  paths: all_paths, // overwrites
  full_directory, // overwrites
  course_for_page, // overwrites
  path_for_page, // overwrites
  all_courses,
  current_path_step_index,
  allLessonsById,
};

// ------------------------------------
// Reducer and Actions
// ------------------------------------
function clean_course(course) {
  return {
    ...clean_path_base(course),
    missions: cleanLessons(course.missions, course.sequence),
    in_paths: defaultTo(course.in_paths, []),
    ...pick(course, ["duration", "skill_level", "learn"]),
  };
}

const count_if_types = types => (sum, e) =>
  sum + (includes(types, e.type) ? 1 : 0);
const count_types = (types, cleaned_courses) =>
  reduce(
    cleaned_courses,
    (sum, c) => reduce(c.missions, count_if_types(types), sum),
    0,
  );

const PROJECT_LIST_COUNT = 2;
const make_project_list = courses => {
  const projects = [];
  courses.forEach(course => {
    const course_projects = course.missions.filter(({ type }) =>
      includes(["project", "guided_project"], type),
    );
    if (course_projects[0]) {
      projects.push({
        ...course_projects[0],
        from_course: pick(course, ["title", "slug"]),
      });
    }
  });
  return projects.slice(0, PROJECT_LIST_COUNT);
};

function clean_path_steps(steps) {
  return map(steps, s => {
    const cleaned_courses = s.courses.map(clean_course);
    return {
      ...clean_path_base(s),
      courses: cleaned_courses,
      projects_count: count_types(
        ["project", "guided_project"],
        cleaned_courses,
      ),
      challenges_count: count_types(["challenge"], cleaned_courses),
      project_list: make_project_list(cleaned_courses),
    };
  });
}

function clean_only_path(path) {
  return {
    beta: BETA_PATH_SEQUENCES.indexOf(`${path.sequence}`) !== -1,
    ...clean_path_base(path),
    ...pick(path, [
      "duration",
      "rating",
      "rating_count",
      "skill_level",
      "skill_topics",
      "learn",
      "selected",
      "type",
    ]),
  };
}
function clean_path(path) {
  return {
    ...clean_only_path(path),
    steps: clean_path_steps(path.steps),
  };
}
function cleanCurrentLesson(lesson) {
  if (lesson == null) return initial_state.currentLesson;
  return cleanLesson(lesson);
}

const DEFAULT_PATH_SEQUENCE = "2";
function setup_paths(unclean_paths) {
  let paths = unclean_paths.map(clean_only_path);
  BETA_PATH_SEQUENCES.forEach(beta_sequence => {
    const betaIndex = findIndex(paths, { sequence: beta_sequence });
    if (betaIndex !== -1)
      paths = Immutable.setIn(paths, [betaIndex, "beta"], true);
  });
  if (!find(paths, "selected")) {
    const index = findIndex(paths, { sequence: DEFAULT_PATH_SEQUENCE });
    return Immutable.setIn(paths, [index, "selected"], true);
  }
  return paths;
}

const action_types_prefix = "catalog_info/";

const private_handlers = {
  set_paths: (state, { payload }) =>
    state.merge({
      paths: setup_paths(payload),
    }),
  set_active_path: (state, { payload }) =>
    state.merge({
      paths: state.paths.map(path =>
        path.merge({
          selected: path.sequence === payload,
        }),
      ),
    }),
  setCurrentLesson: (state, { payload }) =>
    state.merge({
      currentLesson: cleanCurrentLesson(payload),
    }),
  resetCurrentLesson: state =>
    state.merge({
      currentLesson: initial_state.currentLesson,
    }),
  setCurrentScreen: (state, { payload }) =>
    state.merge({
      currentScreen: payload,
    }),
  set_directory: (state, { payload }) =>
    state.merge({
      full_directory: payload.map(clean_path),
      paths: setup_paths(payload),
    }),
  set_path_not_found: state => state.merge({ path_not_found: true }),
  set_a_path: (state, { payload }) =>
    state.merge({
      path_for_page: clean_path(payload),
      path_not_found: false,
    }),
  set_a_path_user_data: (state, { payload }) =>
    state.merge({
      user_data_for_page: payload,
    }),
  set_a_path_from_directory: (state, { payload }) =>
    state.merge({
      path_for_page: payload,
      path_not_found: false,
    }),
  reset_a_path: state =>
    state.merge({
      path_for_page: initial_state.path_for_page,
      user_data_for_page: initial_state.user_data_for_page,
      path_not_found: false,
    }),
  set_course_not_found: state => state.merge({ course_not_found: true }),
  set_a_course: (state, { payload }) =>
    state.merge({
      course_for_page: clean_course(payload),
      course_not_found: false,
    }),
  reset_a_course: state =>
    state.merge({
      course_for_page: initial_state.course_for_page,
      course_not_found: false,
    }),
  injectLesson: (state, { payload }) =>
    state.merge({
      injectLesson: payload,
    }),
};
const public_handlers = {
  reset: () => Immutable(initial_state),
};

export const {
  reducer,
  private_actions,
  actions,
  ACTION_TYPES,
} = make_reducer_n_actions({
  public_handlers,
  private_handlers,
  action_types_prefix,
  initial_state,
  Immutable,
});
export default reducer;
