// @flow
import type { Node } from "react";
import React from "react";
import cn from "classnames";
import sanitizeHtml from "utils/sanitation";
import OptionCard from "./OptionCard";

type Props = {
  stepIdx: number,
  fullname: string,
  stepInfo: {
    title: string,
    help_text: string,
    choices_layout: string,
    choices: Array<{
      title: string,
      text: string,
      order: string,
      icon: string,
    }>,
    step_id: string,
  },
  handleOptionOnClick: Function,
};

const VERTICAL_LAYOUT = "VERTICAL";

const Step = (props: Props): Node => {
  const isVertical = props.stepInfo.choices_layout === VERTICAL_LAYOUT;
  const hasIcons = props.stepInfo.choices.filter(choice =>
    choice.icon?.includes("https://"),
  ).length;
  const choicesLength = React.useMemo(() => props.stepInfo.choices.length, [
    props.stepInfo.choices.length,
  ]);

  const renderFirstStepHeader = () => {
    if (props.stepIdx === 0) {
      return (
        <>
          <h3 className="dq-font-bold dq-text-xl dq-text-center">
            Hey{props.fullname && `, ${props.fullname}`}. Welcome to Dataquest!
          </h3>
          {props.stepInfo.help_text && (
            <h3
              className="dq-text-xl dq-font-bold dq-mb-4 2xl:dq-mb-6 dq-text-center dq-max-w-xl"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(props.stepInfo.help_text),
              }}
            />
          )}
        </>
      );
    }
  };

  return (
    <>
      {renderFirstStepHeader()}
      <div className="dq-max-w-lg dq-text-center">
        <h3 className="md:dq-text-3xl sm:dq-text-2xl dq-font-bold">
          {props.stepInfo.title}
        </h3>
        {props.stepInfo.help_text && props.stepIdx > 0 && (
          <p
            className="dq-mt-2 dq-text-lg"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(props.stepInfo.help_text),
            }}
          />
        )}
      </div>
      <div
        className={cn(
          "dq-mx-auto dq-mt-6 2xl:dq-mt-8 dq-grid dq-overflow-y-auto",
          {
            "dq-grid-cols-1 dq-gap-5 dq-max-w-7xl": isVertical,
            "dq-gap-4 dq-max-w-2xl": !isVertical,
            [`lg:dq-grid-cols-${Math.min(5, choicesLength)}`]: isVertical,
            [`md:dq-grid-cols-${Math.min(4, choicesLength)}`]: isVertical,
            [`sm:dq-grid-cols-${Math.min(3, choicesLength)}`]: isVertical,
            "dq-grid-cols-1": choicesLength < 5 && !isVertical,
            "xl:dq-grid-cols-2": choicesLength >= 5 && !isVertical,
            "md:dq-grid-cols-2":
              !isVertical &&
              ((choicesLength >= 5 && !hasIcons) ||
                (choicesLength >= 4 && choicesLength < 6 && hasIcons)),
            "md:dq-grid-cols-3": choicesLength >= 6 && !isVertical,
          },
        )}
        style={{
          maxHeight: "calc(100vh - 18rem)",
          minWidth: "10rem",
          // eslint-disable-next-line no-nested-ternary
          marginTop: choicesLength <= 2 ? "20vh" : "0",
        }}
        data-test-selector="OnboardingChoices"
      >
        {props.stepInfo.choices.map(choice => (
          <OptionCard
            key={choice.title}
            icon={choice.icon?.includes("https://") ? false : choice.icon}
            title={choice.title}
            description={choice.text}
            onClick={() =>
              props.handleOptionOnClick(
                `${props.stepInfo.step_id}-${choice.order}`,
              )
            }
            data-test-selector="NextButton"
            className={cn({
              "dq-flex-1 dq-mt-5": isVertical,
              "dq-flex dq-mt-2 dq-p-4 xl:dq-p-6": !isVertical,
            })}
          >
            {choice.icon?.includes("https://") && (
              <div className="dq-mb-2 2xl:dq-mb-6">
                <img
                  src={choice.icon}
                  alt=""
                  className="dq-w-11 dq-h-11 2xl:dq-w-20 2xl:dq-h-20"
                />
              </div>
            )}
          </OptionCard>
        ))}
      </div>
    </>
  );
};

export default Step;
