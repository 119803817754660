import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { userIsAuthenticated } from "routes/helpers";

const OnboardingLayout = props => {
  const containerClasses =
    "dq-flex dq-flex-row dq-bg-white dq-min-h-full dq-max-h-screen dq-overflow-hidden dq-antialiased";
  return (
    <div className={containerClasses} data-test-selector="OnboardingView">
      <div className="dq-flex dq-flex-col dq-flex-grow dq-max-w-full dq-p-4 md:dq-p-6 xl:dq-py-8 2xl:dq-py-16 2xl:dq-px-20">
        {(__DEV__ || __STAGE__) && props.skipOnboardingBtn}
        {props.children}
      </div>
    </div>
  );
};

OnboardingLayout.propTypes = {
  children: PropTypes.node,
  skipOnboardingBtn: PropTypes.node,
};

OnboardingLayout.defaultProps = {
  children: undefined,
  skipOnboardingBtn: undefined,
};

export default withRouter(userIsAuthenticated(OnboardingLayout));
