import * as React from "react";
import cn from "classnames";
import { IoEllipseSharp } from "react-icons/io5";

type Props = {
  /**
   * Add additional style classes
   */
  className?: string,
  wrapperClassName?: string,
};

/**
 * Animated spinner
 */
const DqBouncer = ({
  className,
  wrapperClassName,
  ...others
}: Props): React.Node => (
  <small className={cn("dq-flex dq-items-center", wrapperClassName)}>
    <IoEllipseSharp
      className={cn("dq-animate-bounce", className)}
      style={{ animationDelay: "0.1s" }}
      {...(others: $Rest<Props, {| className?: ?string |}>)}
    />
    <IoEllipseSharp
      className={cn("dq-animate-bounce", className)}
      style={{ animationDelay: "0.2s" }}
      {...(others: $Rest<Props, {| className?: ?string |}>)}
    />
    <IoEllipseSharp
      className={cn("dq-animate-bounce", className)}
      style={{ animationDelay: "0.3s" }}
      {...(others: $Rest<Props, {| className?: ?string |}>)}
    />
  </small>
);

DqBouncer.defaultProps = {
  className: "",
  wrapperClassName: "",
};

export default DqBouncer;
