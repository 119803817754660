import React from "react";
import { Stepper, Step } from "react-form-stepper";
import times from "lodash/times";

const Steps = ({ currentStep, total }) => (
  <Stepper
    className="dq-steps-onboarding !dq-items-center !dq-justify-center !dq-p-0"
    stepClassName="!dq-w-2.5 !dq-h-2.5 !dq-border-gray-100 dq-py-1"
    activeStep={currentStep}
    hideConnectors
  >
    {times(total, i => (
      <Step key={`Step-${i}`} />
    ))}
  </Stepper>
);

export default Steps;
