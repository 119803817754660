import { cloneDeep, cloneDeepWith } from "lodash/lang";
import some from "lodash/some";

export default (injectLesson, path) => {
  if (!injectLesson) return path;

  const steps = path.steps.map(step => ({
    ...step,
    courses: cloneDeepWith(step.courses, courses =>
      courses.map(c =>
        cloneDeepWith(c, course => {
          let missions;
          if (
            `${course.sequence}` === `${injectLesson.course}` &&
            !some(course.missions, injectLesson.lesson) &&
            (!injectLesson.path ||
              `${injectLesson.path}` === `${path.sequence}`)
          ) {
            missions = cloneDeep(course.missions);
            missions.splice(injectLesson.position, 0, injectLesson.lesson);
          }
          return {
            ...course,
            missions: missions || course.missions,
          };
        }),
      ),
    ),
  }));
  return {
    ...path,
    steps,
  };
};
