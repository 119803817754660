/* eslint-disable import/prefer-default-export */
export const parseURL = url => {
  const urlObj = new URL(window.location.origin + url);

  urlObj.search = urlObj.search.replace("?", "");
  return urlObj;
};

export const goToRoute = (history, url, state = {}) => {
  const parsedURL = parseURL(url);

  history.push({
    pathname: parsedURL.pathname,
    search: parsedURL.search,
    state,
  });
};
