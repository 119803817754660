import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import SocialSignOn from "components/auth/SocialSignOn";
import ReCAPTCHA from "components/auth/Recaptcha";
import DqButton from "components/common/elements/DqButton";
import DqInput from "components/common/forms/DqInput";
import withSignUp from "./withSignUp";

const SignUpForm = ({
  data,
  isReferral,
  handleOnSubmit,
  handleOnChange,
  recaptchaHandleOnChange,
  recaptchaHandleOnErrored,
  location,
  socialSignupSuccess,
  socialSignupFailure,
  renderGeneralErrors,
  loadReCAPTCHA,
  switchMode,
  title = "Sign up",
}) => {
  const renderInputField = ({ key, label, ...attr }) => {
    if (!data) return null;

    return (
      <DqInput
        label={label}
        id={key}
        onChange={handleOnChange}
        disabled={data.submitting}
        value={data.formValues[key]}
        {...attr}
      />
    );
  };

  return (
    <div
      className={cn(
        "dq-sign-up dq-max-w-full dq-flex dq-flex-col dq-items-stretch dq-my-0 dq-mx-auto dq-px-5 sm:dq-px-0",
      )}
    >
      {renderGeneralErrors()}
      {isReferral && (
        <h4 className="dq-w-full dq-mb-5 dq-text-left">{title}</h4>
      )}
      <div className="dq-flex dq-flex-col">
        {!isReferral && (
          <p className="dq-flex dq-w-full dq-text-center dq-m-0 dq-text-2xl sm:dq-text-xl dq-font-bold">
            {title}
          </p>
        )}
        {!isReferral && (
          <div className="dq-flex dq-justify-end dq-mt-2">
            <p className="dq-text-sm dq-leading-tight">
              Have an account?{" "}
              {switchMode ? (
                <DqButton
                  text="Login"
                  onClick={switchMode}
                  variant="text"
                  size="small"
                />
              ) : (
                <DqButton
                  text="Login"
                  to={`/login${location.search || ""}`}
                  variant="text"
                  size="small"
                  data-test-selector="LoginOrSignupSwitch"
                />
              )}
            </p>
          </div>
        )}
        {isReferral && (
          <div className="dq-flex dq-w-full dq--mb-4">
            <SocialSignOn
              onSuccess={socialSignupSuccess}
              onFailure={socialSignupFailure}
            />
          </div>
        )}
      </div>
      <form
        onSubmit={handleOnSubmit}
        id="authForm"
        className="dq-max-w-full dq-flex dq-flex-col dq-items-stretch dq-my-0 dq-mx-auto"
      >
        {!isReferral &&
          renderInputField({
            label: "Full Name",
            "data-test-selector": "full-name",
            key: "name",
            placeholder: "Full Name",
          })}
        {renderInputField({
          label: "Email",
          "data-test-selector": "email",
          type: "email",
          key: "email",
          placeholder: "Email",
        })}
        {renderInputField({
          label: "Password",
          "data-test-selector": "password",
          type: "password",
          key: "password",
          placeholder: "Password",
        })}
        <div>
          <DqButton
            data-test-selector="submit"
            type="submit"
            className="dq-mb-2 dq-self-center dq-w-full"
            size="large"
            loading={data.submitting}
            text={isReferral ? "Start now" : "Sign Up"}
          />
        </div>
        {!isReferral && (
          <>
            <p className="dq-text-lg dq-text-center dq-mb-2">
              Or sign up using an existing account
            </p>
            <SocialSignOn
              onSuccess={socialSignupSuccess}
              onFailure={socialSignupFailure}
            />
          </>
        )}
        {loadReCAPTCHA && (
          <ReCAPTCHA
            recaptchaRef={data.recaptchaRef}
            handleOnChange={recaptchaHandleOnChange}
            handleOnErrored={recaptchaHandleOnErrored}
          />
        )}
      </form>
    </div>
  );
};

SignUpForm.propTypes = {
  data: PropTypes.object.isRequired,
  isReferral: PropTypes.bool,
  handleOnSubmit: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  recaptchaHandleOnChange: PropTypes.func.isRequired,
  recaptchaHandleOnErrored: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  socialSignupSuccess: PropTypes.func.isRequired,
  socialSignupFailure: PropTypes.func.isRequired,
  renderGeneralErrors: PropTypes.func.isRequired,
  loadReCAPTCHA: PropTypes.bool.isRequired,
  switchMode: PropTypes.func,
  title: PropTypes.string,
};

SignUpForm.defaultProps = {
  isReferral: false,
  switchMode: null,
  title: "Create a free account and start learning today!",
};

export default withSignUp(SignUpForm);
