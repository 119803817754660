// @flow
import type { AbstractComponent, Node } from "react";
import React, { useEffect, useState } from "react";
// $FlowFixMe
import { useLazyQuery } from "@apollo/client";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectors as userInfoSelectors } from "redux/modules/user_info";
import { actions as planActions } from "redux/modules/plan_info";
import { PROMOTION_QUERY } from "queries/promotion/queries";
import { Promotion } from "./Promotion";

const selectors = {
  isLoggedIn: userInfoSelectors.is_logged_in,
  isSubscribed: userInfoSelectors.is_subscribed,
};

const actions = {
  updateDiscountCode: planActions.updateDiscountCode,
};

type ContextProps = {
  promoData: null | Object,
};

export const PromotionContext: Object = React.createContext<ContextProps>({
  promoData: null,
});

const mapStateToProps = createStructuredSelector(selectors);

type Props = {
  isLoggedIn: boolean,
  isSubscribed: boolean,
  updateDiscountCode: Function,
  children?: Node,
};

const PromotionProvider = ({
  isLoggedIn,
  isSubscribed,
  updateDiscountCode,
  children,
}: Props) => {
  const location = useLocation();
  const [promoData, setPromoData] = useState(null);
  const [
    getPromotion,
    { loading: loadingPromotion, data: dataPromotion },
  ] = useLazyQuery(PROMOTION_QUERY, {
    fetchPolicy: "network-only",
  });

  // Fetch promotion data in all pages only if:
  // - users is logged in
  // - does not have a subscription
  useEffect(() => {
    if (isLoggedIn && !isSubscribed) {
      getPromotion();
    }
  }, [isLoggedIn, isSubscribed]);

  // Fetch promotion data when location changes to payment
  // page and user does not have a subscription
  useEffect(() => {
    if (location.pathname.includes("/payment") && !isSubscribed) {
      getPromotion();
    }
  }, [location, isSubscribed]);

  useEffect(() => {
    if (!loadingPromotion) {
      const now = new Date();

      if (dataPromotion?.promo) {
        const promo = new Promotion(dataPromotion.promo);
        if (
          now.getTime() >= promo.startDate.getTime() &&
          now.getTime() < promo.endDate.getTime()
        ) {
          setPromoData(promo);
        }

        if (promo.default) {
          updateDiscountCode(promo.code);
        }
      }
    }
  }, [dataPromotion, loadingPromotion]);

  return (
    <PromotionContext.Provider
      value={{ promoData, loadingPromotion, getPromotion }}
    >
      {children}
    </PromotionContext.Provider>
  );
};

PromotionProvider.defaultProps = {
  children: null,
};

export default (connect(
  mapStateToProps,
  actions,
)(PromotionProvider): AbstractComponent<Props>);
