import trim from "lodash/trim";
import omit from "lodash/omit";
import join from "lodash/join";

import dq_fetch, { dq_graphql } from "redux/utils/dq_fetch";
import { escapeGraphQLString } from "utils/gql";
import { private_actions, selectors } from "./profile_info_simple";
import {
  actions as userActions,
  selectors as userSelectors,
} from "../user_info";

export const getProfile = ({ user_id, username, replace_url, setPublic }) => (
  dispatch,
  _getState,
) => {
  // for mocking
  // eslint-disable-next-line no-param-reassign
  if (replace_url == null) replace_url = url => window.location.replace(url);

  let url;
  if (user_id) {
    url = `/api/v1/accounts/users/${user_id}/`;
  } else {
    url = `/api/v1/accounts/public/users/?username=${encodeURIComponent(
      username,
    )}`;
  }
  const options = { resolveErr: true };

  return dq_fetch(url, options)
    .then(data => {
      if (setPublic) {
        dispatch(private_actions.setPublicProfile(data));
      } else {
        dispatch(
          private_actions.setProfile({
            ...data,
            featureFlags: userSelectors.featureFlags(_getState()),
          }),
        );
      }
      return data;
    })
    .catch(response => {
      const noProfile =
        response?.response?.status === 404 ||
        response?.response?.status === 500 ||
        response?.response?.status === 403 ||
        response?.status === 404 ||
        response?.status === 403;
      dispatch(private_actions.setLoading(false));
      dispatch(private_actions.setProfileNotFound(noProfile));
    });
};

const INTERNAL_ERROR = 500;
const translate_error = error => {
  if (error.response.status === INTERNAL_ERROR) {
    return null;
  }
  return error.response.json();
};

export const saveProfile = profile => (dispatch, getState) => {
  const profile_id = selectors.profile_id(getState());
  const user_id = selectors.user_id(getState());
  let url = `/api/v1/accounts/user_profiles/${profile_id}/`;

  const [first_name = "", ...last_name] = trim(profile.fullname).split(" ");

  const options = {
    post_obj: omit(profile, ["fullname", "email"]),
    type: "PATCH",
  };
  dispatch(private_actions.savingProfile());
  return dq_fetch(url, options)
    .then(() => {
      url = `/api/v1/accounts/users/${user_id}/`;
      options.post_obj = {
        email: profile.email,
        first_name,
        last_name: join(last_name, " "),
      };
      return dq_fetch(url, options);
    })
    .then(() => dispatch(private_actions.updateProfile()))
    .then(() => dispatch(userActions.refresh_user()))
    .then(() => getProfile({ user_id, setPublic: true })(dispatch, getState))
    .then(() => undefined)
    .catch(translate_error)
    .then(data => {
      if (data == null) return;
      dispatch(private_actions.setSaveErrors(data));
    })
    .catch(console.warn);
};

/**
 * User starts product tour
 */
export const startProductTour = () => (dispatch, _getState) => {
  const startTourQ = `mutation {
    start_product_tour
        {success}
  }`;

  return dq_graphql(startTourQ).then(() => {
    dispatch(
      private_actions.setProductTourInProgress({
        productTourInProgress: true,
      }),
    );
  });
};

/**
 * User finished product tour
 */
export const finishProductTour = data => (dispatch, _getState) => {
  dispatch(private_actions.setProductTourShown());

  // Note that the step text is escaped using a helper function.
  // This is specifically to avoid cases where the step text
  // contains a `"` character, which would close the mutation
  // string and break the query.
  // In my opinion, this escaping sould happen for every operation
  // and variable replacement at higher level.
  const finishTourQ = `mutation {
    finish_product_tour(step_id: "${
      data.stepId
    }", step_text: "${escapeGraphQLString(data.stepText)}", skipped: ${
    data.skipped
  })
        {success}
    }`;

  return dq_graphql(finishTourQ).then(() => {
    dispatch(
      private_actions.setProductTourInProgress({
        productTourInProgress: false,
      }),
    );
    dispatch(getProfile({ user_id: data.userId }));
  });
};
