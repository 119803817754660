import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import uniq from "lodash/uniq";
import get from "lodash/get";
import intersection from "lodash/intersection";
import cloneDeep from "lodash/cloneDeep";
import { IoArrowBackSharp } from "react-icons/io5";
import OnboardingLayout from "layouts/OnboardingLayout";
import DqButton from "components/common/elements/DqButton";
import DqLogo from "components/common/brand/DqLogo";
import DqLoading from "components/common/feedback/DqLoading";
import Steps from "components/onboarding/Steps";
import Step from "components/onboarding/Step";
import Recommendation from "components/onboarding/Recommendation";

import catalog from "images/onboarding/catalog.svg";

import {
  selectors as user_selectors,
  actions as user_actions,
} from "redux/modules/user_info";

import {
  useOnboardingQuery,
  useOnboardingSkipMutation,
  useOnboardingFinishMutation,
  useOnboardingCompleteQuestionMutation,
} from "queries/onboarding/hooks";
import OnboardingTracking from "./tracking";

export const selectors = { ...user_selectors };
export const actions = {
  refreshUser: user_actions.refresh_user,
};

const mapStateToProps = createStructuredSelector(selectors);

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,

  // user_info
  fullname: PropTypes.string,
  featureFlags: PropTypes.shape({}).isRequired,

  refreshUser: PropTypes.func.isRequired,
};

const defaultProps = {
  fullname: "",
};

const rsplit = (string, sep, maxsplit) => {
  const split = string.split(sep);
  return maxsplit
    ? [split.slice(0, -maxsplit).join(sep)].concat(split.slice(-maxsplit))
    : split;
};

const OnboardingView = props => {
  const {
    loading: onboardingLoading,
    data: onboardingData,
  } = useOnboardingQuery();

  const [completeOnboardingQuestion] = useOnboardingCompleteQuestionMutation();
  const [skipOnboardingMutation] = useOnboardingSkipMutation();
  const [finishOnboarding] = useOnboardingFinishMutation();

  const stepsLine = ["Sign up", "About you", "Start learning"];

  const skipOnboarding = useCallback(() => {
    skipOnboardingMutation().then(() => {
      props.refreshUser().finally(() => {
        props.history.push("/dashboard");
      });
    });
  }, [skipOnboardingMutation, props.refreshUser, props.history]);

  const skipOnboardingBtn = React.useMemo(
    () => (
      <DqButton
        variant="text"
        text="SKIP"
        className="dq-absolute dq-right-10"
        onClick={skipOnboarding}
      />
    ),
    [skipOnboarding],
  );

  const onboardingSteps = React.useMemo(
    () => get(onboardingData, "steps", []),
    [onboardingData],
  );
  const onboardingRecs = React.useMemo(
    () => get(onboardingData, "recommendations", []),
    [onboardingData],
  );

  const rootPathName = props.location.pathname
    .split("/")
    .filter(elt => elt.length)[0];
  const getInitialChoices = () => {
    if (props.match.params.step)
      return props.match.params.step.split("/").filter(elt => elt.length);
    return [];
  };

  const getStepIdArray = choices =>
    uniq(choices.map(choice => choice.substring(0, choice.lastIndexOf("-"))));

  const getStepIdx = (choices, steps) => {
    let newIdx = 0;
    const lastChoice = choices[choices.length - 1];
    if (!lastChoice) return newIdx;
    steps.forEach((step, i) => {
      if (lastChoice.slice(0, lastChoice.lastIndexOf("-")) === step.step_id) {
        newIdx = i + 1;
      }
    });
    let nextStep = steps[newIdx];
    while (nextStep !== undefined && nextStep.required_choices) {
      const requiredChoices = nextStep.required_choices.map(
        choice => `${choice.step_id}-${choice.order}`,
      );
      // List of steps that influence choices
      const uniqueSteps = getStepIdArray(requiredChoices);
      // List of steps that were selected by a learner
      const choicesSteps = getStepIdArray(choices);
      // If required steps are more than selected steps, we shouldn't skip a step
      if (
        uniqueSteps.length > choicesSteps.length &&
        intersection(uniqueSteps, choicesSteps).length === choicesSteps.length
      ) {
        break;
      }

      // If all required steps selected and choice is not a part of the required choice, we skip a step
      if (
        intersection(requiredChoices, choices).length !== uniqueSteps.length
      ) {
        newIdx += 1;
        nextStep = steps[newIdx];
      } else {
        break;
      }
    }
    return newIdx;
  };

  const [choices, setChoices] = useState(getInitialChoices);
  const [idx, setIdx] = useState(null);
  const [stepChoice, setStepChoice] = useState(null);

  const recommendations = React.useMemo(() => {
    let recommendationsClone = [];

    if (!onboardingRecs || !choices) return recommendationsClone;

    recommendationsClone = cloneDeep(onboardingRecs);
    recommendationsClone = recommendationsClone.filter(recommendation =>
      new RegExp(recommendation.pattern).test(choices.join("-")),
    );

    recommendationsClone = recommendationsClone.map(recommendation => ({
      ...recommendation,
      starting_points: recommendation.starting_points.filter(startingPoint => {
        const requiredChoices = startingPoint.required_choices
          ? startingPoint.required_choices.map(
              choice => `${choice.step_id}-${choice.order}`,
            )
          : [];
        const requiredLength = uniq(
          requiredChoices.map(choice =>
            choice.substring(0, choice.lastIndexOf("-")),
          ),
        ).length;
        return intersection(requiredChoices, choices).length === requiredLength;
      }),
    }));

    recommendationsClone = recommendationsClone.filter(
      recommends => recommends.starting_points.length,
    );

    return recommendationsClone;
  }, [onboardingRecs, choices]);

  useEffect(() => {
    if (idx === 0 && onboardingSteps.length) {
      const { featureFlags } = props;
      OnboardingTracking.startOnboarding({ featureFlags });
    }
    if (onboardingSteps.length && idx === null) {
      setIdx(getStepIdx(choices, onboardingSteps));
    }
  }, [onboardingSteps]);

  // Programatically trigger goForward for onboarding test
  // after updating selected choice
  useEffect(() => {
    if (stepChoice) {
      goForward();
    }
  }, [goForward, stepChoice]);

  const goBack = () => {
    setChoices(prevChoices => {
      setStepChoice(prevChoices.pop());
      setIdx(getStepIdx(prevChoices, onboardingSteps));
      props.history.push({
        pathname: `/${rootPathName}/${prevChoices.join("/")}`,
      });
      return prevChoices;
    });
  };

  const goForward = () => {
    setChoices(prevChoices => {
      const [questionId, selectedId] = rsplit(stepChoice, "-", 1);
      const questionData = onboardingSteps.find(
        elt => elt.step_id === questionId,
      );
      const answerData = {
        questionText: questionData.title,
        choiceText: questionData.choices[parseInt(selectedId, 10)].title,
        choiceId: selectedId,
      };
      const newChoices = prevChoices.concat([stepChoice]);

      completeOnboardingQuestion(questionId, answerData);
      props.history.push({
        pathname: `/${rootPathName}/${newChoices.join("/")}`,
      });
      setIdx(getStepIdx(newChoices, onboardingSteps));
      return newChoices;
    });
    setStepChoice(null);
  };

  const recordOnboarding = React.useCallback(
    (startingSequence, startingPath, recommendationsJSON) =>
      finishOnboarding(
        startingSequence,
        startingPath,
        recommendationsJSON,
        props.featureFlags,
      ).then(() => props.refreshUser()),
    [finishOnboarding, props.refreshUser, props.featureFlags],
  );

  const stepInfo = onboardingSteps[idx];

  const handleFinishOnboarding = (startingPath, startingSequence) => {
    finishOnboarding(startingSequence, startingPath, "", props.featureFlags)
      .then(() => props.refreshUser())
      .then(() => {
        props.history.push("/dashboard");
      });
  };

  const handleCatalogOnClick = () => {
    finishOnboarding(undefined, undefined, "", props.featureFlags).then(() => {
      window.location.href = "https://www.dataquest.io/data-science-courses/";
    });
  };

  const handleGenAIOnClick = () => {
    finishOnboarding(undefined, undefined, "", props.featureFlags).then(() => {
      window.location.href =
        "https://www.dataquest.io/path/generative-ai-fundamentals-skill-track/";
    });
  };

  const renderContentVariant = () => {
    const numSteps = uniq(onboardingSteps.map(s => s.step_number)).length;
    const isLastStep = idx === onboardingSteps.length;

    return (
      <OnboardingLayout>
        <div className="dq-flex dq-justify-between">
          <DqLogo className="dq-w-32" />
        </div>
        <div className="dq-flex dq-flex-col dq-flex-grow dq-items-center">
          {isLastStep ? (
            <Recommendation
              choices={choices}
              steps={onboardingSteps}
              recommendations={recommendations}
              handleCatalogOnClick={handleCatalogOnClick}
              handleFinishOnboarding={handleFinishOnboarding}
            />
          ) : (
            <Step
              stepInfo={stepInfo}
              stepIdx={idx}
              stepChoice={stepChoice}
              handleOptionOnClick={selectedChoice => {
                setStepChoice(selectedChoice);
              }}
              steps={onboardingSteps}
              fullname={props.fullname}
            />
          )}
        </div>
        <div className="dq-flex dq-flex-col dq-fixed dq-bottom-0 dq-right-0 dq-left-0 dq-m-4">
          <div className="dq-flex dq-justify-between">
            <DqButton
              text="Back"
              startIcon={<IoArrowBackSharp />}
              variant="text"
              color="gray"
              onClick={goBack}
              disabled={idx <= 0}
            />
            {!isLastStep && (
              <Steps
                currentStep={stepInfo?.step_number || idx}
                total={numSteps}
              />
            )}
            <div>
              {isLastStep && (
                <DqButton
                  data-test-selector="OnboardingDirectory"
                  text="Explore the catalog"
                  variant="text"
                  onClick={handleCatalogOnClick}
                />
              )}
              <DqButton
                text="Skip"
                variant="text"
                color="gray"
                onClick={skipOnboarding}
                className={cn({
                  "dq-invisible": !(__DEV__ || __STAGE__),
                })}
              />
            </div>
          </div>
        </div>
      </OnboardingLayout>
    );
  };

  if (
    idx === null ||
    (props.askingForUser ||
      (idx === onboardingSteps.length && onboardingLoading)) ||
    !props.featureFlagsRetrieved
  )
    return <DqLoading fullScreen />;

  return renderContentVariant();
};

OnboardingView.propTypes = propTypes;
OnboardingView.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  actions,
)(OnboardingView);
