import get_uuid from "helpers/get_uuid";
import default_feature_config from "./feature_config";

/**
 * Consumer-agnostic class for evaluating AB test data
 *
 * Feature values should always be in integers so we can
 * gracefully manage multi-variate testing
 *
 * `0` is assumed to be the control group (or feature disabled)
 * `1` is the test group in an A/B test and is the assumed desired state when opting users in to features
 *
 */
export class DqFeatureFlagService {
  /**
   * Unique id useful for debugging purposes
   * @type string
   */
  client_id = null;

  /**
   * Map of active features and their parameters
   * @type {{}}
   */
  feature_flag = {};

  constructor(feature_config) {
    this.client_id = get_uuid();
    this.feature_config = feature_config;
    this.evaluation_cache = {};
  }

  /**
   * Logic for interfacing with Google Optimize as an experiment provider.
   *
   * @param experiment
   * @returns {*}
   * @private
   */
  evaluate_google_optimize(experiment) {
    if (!experiment.google_optimize_key) {
      return 0;
    }

    // No Google Optimize? Default value
    if (!window.google_optimize) {
      return 0;
    }

    // Google optimize value
    try {
      return window.google_optimize.get(experiment.google_optimize_key);
    } catch (e) {
      console.error(
        "Error fetching experiment from Google Optimize",
        this.client_id,
        e,
      );
    }

    return 0;
  }

  /**
   *
   * Checks in order:
   *
   * 1) Has the feature been defined?
   * 2) Is the user in the allow_list?
   * 3) Is the user in the block list?
   * 4) Is there a custom evaluator for this experiment
   * 5) Hand-off to Google Optimize
   * 6) Fall back to default value} feature_name
   * @param {string} feature_name
   * @param {ABTestUserData} user_data
   * @returns {number}
   */
  // eslint-disable-next-line complexity
  evaluate_feature_flag(feature_name, user_data) {
    let feature_status = null;

    // Allow global overrides via localStorage to take precedent for debugging and triage
    if (
      feature_status === null &&
      localStorage?.getItem(feature_name) !== null
    ) {
      feature_status = Number(localStorage?.getItem(feature_name));
    }

    // Experiment does not exist? False
    if (feature_status === null && !(feature_name in this.feature_config)) {
      feature_status = 0;
    }

    const feature = this.feature_config[feature_name];

    const user_id = parseInt(user_data.user_id, 10);

    // User in allowed_list? True
    if (
      feature_status === null &&
      feature.allowed_users &&
      feature.allowed_users.indexOf(user_id) >= 0
    ) {
      feature_status = 1;
    }

    // User in blocked_list? False
    if (
      feature_status === null &&
      feature.blocked_users &&
      feature.blocked_users.indexOf(user_id) >= 0
    ) {
      feature_status = 0;
    }

    // User in custom case?
    if (
      feature_status === null &&
      (feature.custom_case && feature.custom_case(user_data))
    ) {
      feature_status = 1;
    }

    // Google Optimize
    if (feature_status === null && feature.google_optimize_key) {
      feature_status = this.evaluate_google_optimize(feature);
    }

    if (feature_status === null) {
      // Fallback to default value
      feature_status = "default_value" in feature ? feature.default_value : 0;
    }

    return feature_status;
  }

  /**
   * Get feature_flag status
   *
   * @param {string} feature_name
   * @param {ABTestUserData} user_data
   * @returns {number}
   */
  get(feature_name, user_data) {
    if (
      Object.prototype.hasOwnProperty.call(this.evaluation_cache, feature_name)
    ) {
      return this.evaluation_cache[feature_name];
    }

    const feature_status = this.evaluate_feature_flag(feature_name, user_data);

    this.evaluation_cache[feature_name] = feature_status;

    return feature_status;
  }
}

/**
 * Factory method for creating feature flag service with standard config
 * @returns {DqFeatureFlagService}
 */
export function make_feature_flag_service_from_defaults() {
  return new DqFeatureFlagService(default_feature_config);
}
