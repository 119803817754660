import React from "react";
import PropTypes from "prop-types";
import { loadStripe } from "@stripe/stripe-js/pure";

export const StripeContext = React.createContext({
  load: () => console.warn("Stripe loading stub function!"),
  stripePromise: null,
});

const StripeProvider = ({ children }) => {
  const [stripePromise, setStripePromise] = React.useState(null);
  const load = React.useCallback(() => {
    if (stripePromise) return;
    // STRIPE_PK is injected by webpack during build from frontend/framework/webpack/helpers/globals.js
    // if you want to change the value, you need to change it in globals.js and restart the FE build
    // because webpack changes are not live-reloaded
    // STRIPE_PK is set to test key in all environments beside production (where live key is used)
    const promise = loadStripe(process.env.STRIPE_PK);
    setStripePromise(promise);
  }, [stripePromise]);

  return (
    <StripeContext.Provider value={{ load, stripePromise }}>
      {children}
    </StripeContext.Provider>
  );
};

StripeProvider.propTypes = {
  children: PropTypes.element,
};

StripeProvider.defaultProps = {
  children: null,
};

export default StripeProvider;
