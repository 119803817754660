import React from "react";
import cn from "classnames";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import DqCard from "components/common/layout/DqCard";
import { selectors as userStorySelectors } from "redux/modules/user_story_info";

export const selectors = {
  signupProof: userStorySelectors.signupProof,
};
export const actions = {};

const mapStateToProps = createStructuredSelector(selectors);

const MARKETING_PROPS = [
  {
    quantity: "97%",
    text: "of learners recommend Dataquest for career advancement",
  },
  { quantity: "4.9", text: "Dataquest rating on G2Crowd and SwitchUp" },
  {
    quantity: "$30k",
    text: "average salary boost for learners who complete a path",
  },
];

export const SocialProof = ({
  // eslint-disable-next-line react/prop-types
  signupProof,
}) => (
  <div
    className={cn("dq-py-10 dq-flex dq-flex-col dq-text-white dq-bg-gray-800")}
  >
    <div className="dq-max-w-6xl dq-mx-auto dq-space-y-16 dq-text-lg">
      <h3 className="dq-font-bold">
        Grow your career with
        <br />
        <span className="dq-text-purple">Dataquest</span>
      </h3>
      <div className="dq-grid dq-grid-cols-3 dq-gap-5 dq-my-5">
        {MARKETING_PROPS.map(prop => (
          <div key={prop.text} className="dq-text-center dq-space-y-2 dq-px-8">
            <p className="dq-text-purple dq-text-4xl dq-font-bold">
              {prop.quantity}
            </p>
            <p>{prop.text}</p>
          </div>
        ))}
      </div>
      <div className="dq-grid dq-grid-cols-1 lg:dq-grid-cols-3 dq-gap-5 dq-my-5">
        {/* eslint-disable-next-line react/prop-types */}
        {Object.keys(signupProof)?.map(name => {
          const story = signupProof[name];
          return (
            <DqCard
              key={name}
              className="dq-space-y-4 dq-p-8 dq-w-full dq-h-full dq-flex-grow dq-text-gray-800"
            >
              <div className="dq-text-center dq-flex dq-items-center dq-flex-col dq-space-y-2">
                <img
                  src={story?.img_name}
                  alt={name}
                  className="dq-rounded-full dq-w-20 dq-h-20"
                />
                <p className="dq-font-bold">{story?.name}</p>
                <p className="dq-text-purple">{story?.title}</p>
              </div>
              <p>&quot;{story?.quote}&quot;</p>
            </DqCard>
          );
        })}
      </div>
    </div>
  </div>
);

export default connect(
  mapStateToProps,
  actions,
)(SocialProof);
