import React from "react";
import cn from "classnames";

import shareIcon from "images/referral/referral-recommend.svg";
import rewardIcon from "images/referral/referral-earn.svg";
import selectPathIcon from "images/referral/select-path.svg";
import discountIcon from "images/referral/referral-discount.svg";

const signupSteps = [
  {
    title: "Step 1: Create an account",
    icon: shareIcon,
    description:
      "Your free account gives you access to free lessons and practice.",
  },
  {
    title: "Step 2: Choose a learning path",
    icon: selectPathIcon,
    description:
      "Follow a proven learning path: Data Analysis, Data Engineering, SQL, Business Analysis, Web Development and many more.",
  },
  {
    title: "Step 3: Save 20%",
    icon: discountIcon,
    description:
      "When you enroll in Premium, you'll save an additional 20% on top of any other site promotion.",
  },
];

const referrerSteps = [
  {
    title: "Step 1: Recommend a Friend",
    icon: shareIcon,
    description:
      "Invite friends to Dataquest through email, with your own personal referral link, or by spreading the word on social or online communities & forums.",
  },
  {
    title: "Step 2: They Save, You Earn",
    icon: discountIcon,
    description:
      "It's as simple as it sounds. When you recommend a friend to Dataquest, they enjoy a 20% discount on their subscription, and you receive a $20 bonus. It's a win-win!",
  },
  {
    title: "Step 3: Accumulate & Redeem",
    icon: rewardIcon,
    description:
      "You can accumulate your rewards and choose how to use them. You can redeem your points for over 1,000+ digital gift cards, prepaid cards, or make charitable donations. The choice is yours!",
  },
];

// eslint-disable-next-line react/prop-types
const ReferralPitch = ({ newSignup = false }) => {
  const header = newSignup
    ? "Accepting your recommendation"
    : "Recommend Dataquest, You Earn, They Save";

  const steps = newSignup ? signupSteps : referrerSteps;
  return (
    <>
      <h3 className="dq-font-bold dq-mb-4">{header}</h3>
      <div
        className={cn(
          "dq-w-full dq-flex dq-flex-col md:dq-flex-row dq-space-x-5",
        )}
      >
        {steps.map(step => (
          <div
            key={step.title}
            className={cn(
              "dq-flex dq-flex-col dq-justify-center dq-items-center",
              "dq-text-center dq-p-5",
              {
                // "dq-bg-white dq-ring-inset dq-ring-1 dq-ring-gray-100": !newSignup,
              },
            )}
          >
            <img
              src={step.icon}
              alt={step.title}
              className="dq-w-24 dq-h-24 dq-text-purple"
            />
            <p className="dq-font-bold dq-my-4">{step.title}</p>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
    </>
  );
};

ReferralPitch.defaultProps = {
  newSignup: false,
};

export default ReferralPitch;
