import React, { useMemo } from "react";
import { IoTimeSharp } from "react-icons/io5";
import capitalize from "lodash/capitalize";
import cn from "classnames";
import DqButton from "components/common/elements/DqButton";
import DqCard from "components/common/layout/DqCard";
import DqSkillLevel from "components/common/catalog/DqSkillLevel";
import DqBadge from "../common/elements/DqBadge";

const inject = (str, obj) => str.replace(/\${(.*?)}/g, (x, g) => obj[g]);

const Recommendation = ({
  steps,
  choices,
  recommendations,
  handleFinishOnboarding,
  handleCatalogOnClick,
}) => {
  const choicesDict = useMemo(() => {
    const choicesObj = {};
    choices.forEach(choice => {
      const stepId = choice.slice(0, choice.lastIndexOf("-"));
      const choiceId = choice.slice(choice.lastIndexOf("-") + 1);
      const choiceData = steps.find(elt => elt.step_id === stepId).choices[
        parseInt(choiceId, 10)
      ];
      choicesObj[stepId] = choiceData.value || choiceData.title;
    });
    return choicesObj;
  }, [steps, choices]);

  const handleOnClick = recommendation => {
    handleFinishOnboarding(
      recommendation.starting_points[0].path_sequence,
      recommendation.starting_points[0].mission_sequence,
    );
  };

  if (!recommendations.length) return null;

  return (
    <>
      <div className="dq-flex dq-flex-col dq-space-y-5 dq-max-w-7xl dq-mx-auto dq-mt-4 dq-mb-8 sm:dq-flex-row sm:dq-space-x-5 sm:dq-space-y-0">
        {recommendations.map(recommendation => (
          <DqCard key={recommendation.title}>
            <div className="dq-flex dq-flex-col dq-items-center dq-max-w-2xl dq-h-full dq-relative">
              <p className="dq-mb-1 dq-text-lg dq-font-bold dq-text-purple">
                {capitalize(recommendation.path_type)} path
              </p>
              {recommendation.tag && (
                <DqBadge
                  color="blue"
                  size="md"
                  text={recommendation.tag}
                  className="dq-absolute dq-top-0 dq-right-0 dq-justify-center"
                />
              )}
              <h2 className="dq-mb-8 dq-font-bold dq-text-center">
                {inject(recommendation.title, choicesDict)}
              </h2>

              <p className="dq-mb-8 dq-text-xl dq-text-center">
                {inject(recommendation.description, choicesDict)}
              </p>
              <div className="dq-mt-auto dq-text-center">
                <p className="dq-mb-10 dq-text-lg dq-text-purple">
                  <DqSkillLevel level={recommendation.skill_level} /> friendly
                  {recommendation.starting_points[0].courses_count && (
                    <>
                      <span className="dq-px-4">&middot;</span>{" "}
                      {recommendation.starting_points[0].courses_count} courses{" "}
                    </>
                  )}
                  {recommendation.starting_points[0].duration && (
                    <>
                      <span className="dq-px-4">&middot;</span>{" "}
                      <IoTimeSharp className="dq-inline dq--mt-0.5" />{" "}
                      {recommendation.starting_points[0].duration} hours
                    </>
                  )}
                </p>
                <DqButton
                  text="Enroll for free"
                  onClick={() => handleOnClick(recommendation)}
                  data-test-selector="NextButton"
                />
              </div>
            </div>
          </DqCard>
        ))}
      </div>
    </>
  );
};

export default Recommendation;
