import { inject_reducers } from "store/inject_reducers";
import plan_info, {
  actions as simple_actions,
  private_actions,
  selectors,
  BASE_SELECTOR_PATH,
  ACTION_TYPES,
} from "./plan_info_simple";
import * as thunk_actions from "./plan_info_thunks";

export const actions = { ...simple_actions, ...thunk_actions };

export default inject_reducers({ plan_info });

export { selectors, BASE_SELECTOR_PATH, ACTION_TYPES, private_actions };
