// @flow
import type { Node } from "react";
import React from "react";
import cn from "classnames";

type DqCardProps = {
  /**
   * The content of the card.
   */
  children: Node,
  /**
   * Add additional style classes
   */
  className?: string,
  /**
   * Whether to use the compact size of the card or not. Default is false.
   */
  compact?: boolean,
  /**
   * Whether to add a shadow to the card or not. Default is false.
   */
  shadow?: boolean,
  /**
   * Whether to hide border and background
   */
  transparent?: ?boolean,
};

/**
 * DqCard is a card component.
 */
const DqCard = ({
  children,
  className = "",
  compact = false,
  shadow = false,
  transparent = false,
  ...others
}: DqCardProps): Node => {
  const styles = transparent
    ? {
        "dq-mb-6": true,
      }
    : {
        "dq-bg-white dark:dq-bg-gray-800 dark:dq-text-white": true,
        "dq-p-3": compact && className.indexOf("dq-p-") === -1,
        "dq-p-6": !compact && className.indexOf("dq-p-") === -1,
        "dq-shadow-lg": shadow,
        "dq-ring-inset dq-ring-1 dq-ring-gray-100 dark:dq-ring-0":
          className.indexOf("dq-ring") === -1,
      };

  return (
    <div {...others} className={cn("dq-relative", styles, className)}>
      {children}
    </div>
  );
};

DqCard.defaultProps = {
  className: "",
  compact: false,
  shadow: false,
  transparent: false,
};

export type { DqCardProps };

export default DqCard;
