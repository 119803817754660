import React, { lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import lazyRetry from "helpers/lazyRetry";
import RedirectWithParams from "./RedirectWithParams";

const LessonView = lazy(() =>
  lazyRetry(() => import("views/LessonView"), "LessonView"),
);
const LessonEndView = lazy(() =>
  lazyRetry(() => import("views/LessonView/LessonEndView"), "LessonEndView"),
);
const AssessmentIntroView = lazy(() =>
  lazyRetry(
    () => import("views/AssessmentView/AssessmentIntroView"),
    "AssessmentIntroView",
  ),
);
const AssessmentResultsView = lazy(() =>
  lazyRetry(
    () => import("views/AssessmentView/AssessmentResultsView"),
    "AssessmentResultsView",
  ),
);

const PageNotFoundView = lazy(() =>
  lazyRetry(() => import("views/PageNotFoundView"), "PageNotFoundView"),
);
const TakeawaysView = lazy(() =>
  lazyRetry(() => import("views/TakeawaysView"), "TakeawaysView"),
);

const LessonRoutes = () => (
  <Switch>
    <RedirectWithParams
      from="/mission(s?)/:mission_num/:title?/:step_num?/:subtitle?"
      to="/m/:mission_num/:title?/:step_num?/:subtitle?"
    />

    <Route path="/c/">
      <Switch>
        <Route
          exact
          path="/c/:course_num/m/assessment-intro/:mission_num/"
          component={AssessmentIntroView}
        />
        <Route
          exact
          path="/c/:course_num/m/:mission_num/:title/:step_num/takeaways"
          component={TakeawaysView}
        />
        <Route
          exact
          path="/c/:course_num/m/:mission_num/:title/:step_num/the-end"
          component={LessonEndView}
        />
        <Route
          exact
          path="/c/:course_num/m/:mission_num/:title?"
          component={LessonView}
        />
        <Route
          path="/c/:course_num/m/:mission_num/:title/:step_num/assessment-results"
          component={AssessmentResultsView}
        />
        <Route
          path="/c/:course_num/m/:mission_num/:title/:step_num/:subtitle?"
          component={LessonView}
        />
        <Route path="*" component={PageNotFoundView} />
      </Switch>
    </Route>

    <Route path="/m">
      <Switch>
        <Redirect exact from="/m" to="/dashboard" />
        <Route
          exact
          path="/m/assessment-intro/:mission_num/"
          component={AssessmentIntroView}
        />
        <Route
          exact
          path="/m/:mission_num/:title/:step_num/takeaways"
          component={TakeawaysView}
        />
        <Route
          exact
          path="/m/:mission_num/:title/:step_num/the-end"
          component={LessonEndView}
        />
        <Route exact path="/m/:mission_num/:title?" component={LessonView} />
        <Route
          path="/m/:mission_num/:title/:step_num/assessment-results"
          component={AssessmentResultsView}
        />
        <Route
          path="/m/:mission_num/:title/:step_num/:subtitle?"
          component={LessonView}
        />
        <Route path="*" component={PageNotFoundView} />
      </Switch>
    </Route>
  </Switch>
);
export default LessonRoutes;
