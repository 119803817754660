// @flow
import * as React from "react";
import cn from "classnames";
import DqSpinner from "components/common/feedback/DqSpinner";
import DqBouncer from "components/common/feedback/DqBouncer";

type Props = {
  /**
   * Add additional style classes
   */
  className?: string,
  /**
   * Enable to add a backdrop and cover entire parent container
   */
  fullScreen?: boolean,
  /**
   * Enable to hide spinner
   */
  hideSpinner?: boolean,
  /**
   * Enable to display bouncing dots instead of spinner
   */
  dots?: boolean,
  /**
   * Add a custom message next to the spinner
   */
  message?: any,
  /**
   * Add a custom comment under the message to share more context
   */
  comment?: any,
  /**
   * Add a custom class name for the spinner
   */
  spinnerClassName?: string,
};

/**
 * Loading indicator
 */
const DqLoading = ({
  className,
  fullScreen,
  message,
  comment,
  hideSpinner,
  spinnerClassName,
  dots,
}: Props): React.Node => (
  <div
    className={cn(
      "dq-flex dq-items-center dq-justify-center dq-text-3xl",
      {
        "dq-absolute dq-top-0 dq-left-0 dq-h-full dq-w-full dq-bg-gray-100/80": fullScreen,
      },
      className,
    )}
  >
    {!hideSpinner && !dots && <DqSpinner className={spinnerClassName} />}
    {!hideSpinner && dots && <DqBouncer wrapperClassName={spinnerClassName} />}
    {message && (
      <div className="dq-ml-1">
        {message}
        {comment && (
          <div className="dq-block dq-mt-2 dq-text-sm">{comment}</div>
        )}
      </div>
    )}
  </div>
);

DqLoading.defaultProps = {
  className: "",
  dots: false,
  fullScreen: false,
  hideSpinner: false,
  message: "",
  comment: "",
  spinnerClassName: "",
};

export default DqLoading;
