import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import NotThereSplash from "components/marketing/NotThereSplash";
import DqFooter from "components/unique/DqFooter";

import { selectors as user_selectors } from "redux/modules/user_info";

export const selectors = {
  is_logged_in: user_selectors.is_logged_in,
};

export const actions = {};

const mapStateToProps = createStructuredSelector(selectors);

export class PageNotFoundView extends React.PureComponent {
  static propTypes = {
    is_logged_in: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <div>
        <NotThereSplash is_logged_in={this.props.is_logged_in} missing_page />
        <DqFooter />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  actions,
)(PageNotFoundView);
