import Immutable from "seamless-immutable";
import { make_simple_selectors, make_reducer_n_actions } from "redux_helpers";
import query_string from "query-string";
import pickBy from "lodash/pickBy";

// -------
// Initial State
// --------

const initial_state = {
  codes: {},
};

// -------
// Selectors
// --------
const BASE = "utm_codes";
export { BASE as BASE_SELECTOR_PATH };

const simple_selectors = make_simple_selectors(initial_state, BASE);

export const selectors = {
  ...simple_selectors,
};

// ------------------------------------
// Reducer and Actions
// ------------------------------------
const action_types_prefix = "utm_codes/";

const prefix = "utm_";
const public_handlers = {
  save_utm_codes: (state, { payload }) => {
    try {
      const search_params = query_string.parse(payload);
      let codes = pickBy(
        search_params,
        (value, key) => key.substr(0, prefix.length) === prefix,
      );
      if (Object.keys(codes).length)
        localStorage?.setItem("dq_utm_codes", JSON.stringify(codes));
      else {
        const storedCodes = JSON.parse(localStorage?.getItem("dq_utm_codes"));
        if (storedCodes) codes = storedCodes;
      }
      return state.merge({ codes });
    } catch (error) {
      return state; // this is to help renders like google
    }
  },
};

const private_handlers = {};

export const {
  reducer,
  private_actions,
  actions,
  ACTION_TYPES,
} = make_reducer_n_actions({
  public_handlers,
  private_handlers,
  action_types_prefix,
  initial_state,
  Immutable,
});
export default reducer;
