import React from "react";
import { makePrettyNumber } from "../../../helpers/price_helpers";

const getRewardRefereeText = rewardReferee => {
  let rewardText = `$${makePrettyNumber(rewardReferee?.units)}`;
  if (rewardReferee?.type === "discount_percent") {
    rewardText = `${makePrettyNumber(rewardReferee?.units)}%`;
  }
  return rewardText;
};
// eslint-disable-next-line import/prefer-default-export
export const PITCHES = {
  free_days: {
    header: reward => "Share Dataquest & Get Free Premium Days",
    signupHeader: () => "",
    subheader: reward =>
      "Invite your friends and get free days of Premium access!",
    body: reward => (
      <p>
        When your friends use your link to subscribe, they get{" "}
        {getRewardRefereeText(reward?.rewardReferee)} their purchase ⁠— and you
        get{" "}
        <span className="dq-font-bold">
          {reward?.rewardReferrer?.units} free days of Premium access
        </span>{" "}
        for being such a good friend.
      </p>
    ),
  },
  lifetime_access: {
    header: reward => "Get Free Lifetime Access",
    signupHeader: reward =>
      `Start learning free and save ${getRewardRefereeText(
        reward?.rewardReferee,
      )} when you upgrade`,
    subheader: reward =>
      "We want you to have unlimited access to everything you've ever wanted to learn!",
    body: reward => (
      <p>
        It&apos;s easy. Share your referral link with friends (
        <span className="dq-font-bold">
          they get {getRewardRefereeText(reward?.rewardReferee)}!
        </span>
        ) and when you&apos;ve reached {reward?.rewardReferrer?.requiredSubs}{" "}
        annual subscriptions sold through your link, your account will be
        automatically upgraded to unlimited{" "}
        <span className="dq-font-bold">Premium access ⁠— forever!</span>
      </p>
    ),
  },
  cash_equivalent: {
    header: reward =>
      `Give ${getRewardRefereeText(
        reward?.rewardReferee,
      )}, Get $${makePrettyNumber(reward?.rewardReferrer?.units)}`,
    signupHeader: reward =>
      `Start learning free and save ${getRewardRefereeText(
        reward?.rewardReferee,
      )} when you upgrade`,
    subheader: reward =>
      `Send your friend a ${getRewardRefereeText(
        reward?.rewardReferee,
      )} discount and enjoy a $${makePrettyNumber(
        reward?.rewardReferrer?.units,
      )} bonus in return. Start recommending now and share the power of Dataquest with your community.`,
    body: reward => (
      <p>
        It&apos;s as simple as it sounds. When you invite a friend to Dataquest,
        they get{" "}
        <span className="dq-font-bold">
          &nbsp;a {getRewardRefereeText(reward?.rewardReferee)} discount on
          their subscription, and you earn a $
          {makePrettyNumber(reward?.rewardReferrer?.units)} bonus.
        </span>
        &nbsp;But it&apos;s more than a reward; it&apos;s a chance to share a
        powerful tool that can unlock opportunities.
      </p>
    ),
  },
};
