/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { private_actions } from "./user_story_info_simple";

// eslint-disable-next-line import/prefer-default-export
export const fetch_user_story = story_name => (dispatch, _getState) =>
  new Promise(resolve => {
    require.ensure(
      [],
      () => {
        try {
          const story = require(`./stories/${story_name.split("-")[0]}`)
            .default;
          dispatch(
            private_actions.set_story({
              [story_name]: { html: story },
            }),
          );
        } catch (e) {
          console.warn(`User story for ${story_name} is not created yet`);
        }
        resolve();
      },
      "user-stories",
    );
  });
