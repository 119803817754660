import { gql } from "@apollo/client";

export const ONBOARDING_QUERY = gql`
  query Onboarding {
    steps {
      step_number
      step_id
      title
      help_text
      choices_layout
      choices {
        order
        title
        text
        icon
        value
      }
      required_choices {
        step_id
        order
      }
    }

    recommendations {
      path_type
      title
      tag
      description
      skill_level
      pattern
      skill_topics
      starting_points {
        mission_sequence
        path_sequence
        projects_count
        missions_count
        courses_count
        duration
        required_choices {
          step_id
          order
          title
        }
      }
    }
  }
`;

export const ONBOARDING_COMPLETE_STEP_MUTATION = gql`
  mutation OnboardingCompleteStep(
    $questionId: String!
    $questionText: String!
    $choiceText: String!
    $choiceId: String!
  ) {
    complete_onboarding_question(
      question_id: $questionId
      answer_data: {
        question_text: $questionText
        choice_text: $choiceText
        choice_id: $choiceId
      }
    ) {
      success
    }
  }
`;

export const ONBOARDING_FINISH_MUTATION = gql`
  mutation OnboardingFinish(
    $recommendation_sequence: Int
    $recommendation_path: Int
    $recommendation_json: String!
  ) {
    finish_onboarding(
      recommendation_sequence: $recommendation_sequence
      recommendation_path: $recommendation_path
      recommendation_json: $recommendation_json
    ) {
      success
      path_sequence
      path_name
    }
  }
`;

export const ONBOARDING_SKIP_MUTATION = gql`
  mutation SkipOnboarding(
    $reason: String
    $source: String
    $fromIframe: Boolean
  ) {
    skip_onboarding(
      reason: $reason
      source: $source
      iframe_source: $fromIframe
    ) {
      success
    }
  }
`;
