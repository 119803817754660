import {
  connectedRouterRedirect,
  connectedReduxRedirect,
} from "redux-auth-wrapper/history4/redirect";
import { createStructuredSelector } from "reselect";

import {
  UNAUTHORIZED_USER_REDIRECT_URL,
  MARKETING_SITE_URL,
} from "helpers/constants";
import { selectors } from "redux/modules/user_info";

const userSelectors = createStructuredSelector(selectors);

const commonOpts = {
  redirectPath: UNAUTHORIZED_USER_REDIRECT_URL,
  authenticatingSelector: state => userSelectors(state).askingForUser,
  authenticatedSelector: state => userSelectors(state).is_logged_in,
  // Better for react devtools
  wrapperDisplayName: "UserIsAuthenticated",
  redirectQueryParamName: "target-url",
};

// docs: https://mjrussell.github.io/redux-auth-wrapper/
export const userIsAuthenticated = connectedRouterRedirect(commonOpts);

// Mapping for static route SEO title and descriptions
export const ROUTE_TITLE_MAPPING = {
  "/onboarding": { title: "Onboarding", description: "" },
  "/dashboard": { title: "Dashboard", description: "" },
  "/learning/path": { title: "Learning Path", description: "" },
  "/catalog": {
    title: "Dataquest Content And Catalog",
    description:
      "View all Dataquest paths, courses and lessons. " +
      "Master Python, Spark, machine learning, R, and more by analyzing real-world data.",
  },
  "/practice": { title: "Practice Your Skills" },
  "/start-premium": {
    title: "Start Premium",
    description: "Sign up to Dataquest and start learning data science today.",
  },
  "/finish-signup": {
    title: "Sign Up",
    description: "Sign up to Dataquest and start learning data science today.",
  },
  "/account/current-plan": { title: "Your Current Subscription Plan" },
  "/account/settings": { title: "Account Settings" },
  "/account/share": { title: "Refer Friends" },
  "/team/accept-invitation": { title: "Accept a team invitation" },
  "/team/members": { title: "Team members list" },
  "/team/invitations": { title: "Pending team invitations" },
};
