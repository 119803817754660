import React from "react";
import Emoji from "react-emoji-render";
import cn from "classnames";
import DqCard from "components/common/layout/DqCard";

const OptionCard = ({
  children,
  icon,
  title,
  description,
  className,
  ...otherProps
}) => (
  <DqCard
    className={cn(
      "dq-flex dq-flex-col dq-items-center dq-cursor-pointer",
      className,
    )}
    {...otherProps}
  >
    {children}
    <h4 className="dq-mb-1 dq-text-center dq-text-blue hover:dq-text-blue-hover">
      {icon && <Emoji text={icon} className="dq-pr-2" />}
      {title}
    </h4>
    {description && <p>{description}</p>}
  </DqCard>
);

export default OptionCard;
