/* eslint-disable import/prefer-default-export */
import { MARKETING_SITE_URL, REDIRECT_AFTER_LOGOUT_URL } from "./constants";

const window_location = {
  redirect: path => window.location.replace(path),

  redirect_to_marketing_site: (path = "") => {
    window.location.replace(MARKETING_SITE_URL + path);
  },

  get_query_variable: variable => {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i += 1) {
      const pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    // if (__DEV__) console.warn("Query variable %s not found", variable);
    return null;
  },

  get_path_and_query: () => window.location.pathname + window.location.search,

  redirect_grandparent: url => {
    window.parent.parent.location = url;
  },
  redirect_after_logout: () => {
    window.location.href = REDIRECT_AFTER_LOGOUT_URL;
  },

  isIframe: () => window.location.pathname === "#iframe",
};

export { window_location };
