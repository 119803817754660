// @flow
import * as React from "react";
import cn from "classnames";
import { VscLoading } from "react-icons/vsc";

type Props = {
  /**
   * Add additional style classes
   */
  className?: string,
};

/**
 * Animated spinner
 */
const DqSpinner = ({ className, ...others }: Props): React.Node => (
  <VscLoading
    className={cn("dq-spinner dq-animate-spin", className)}
    {...(others: $Rest<Props, {| className?: ?string |}>)}
  />
);

DqSpinner.defaultProps = {
  className: "",
};

export default DqSpinner;
