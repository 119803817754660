/* eslint-disable global-require */
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";
import getStore from "store/getStore";
import history from "store/history";

const store = getStore();

// https://developers.google.com/search/docs/guides/debug-rendering
// window.addEventListener('error', function (e) {
//   var errorText = [
//     'version: ' + __VERSION__,
//     e.message,
//     'URL: ' + e.filename,
//     'Line: ' + e.lineno + ', Column: ' + e.colno,
//     'Stack: ' + ((e.error && e.error.stack) || '(no stack trace)'),
//   ].join('\n')

//   // Example: log errors as visual output into the host page.
//   // Note: you probably don’t want to show such errors to users, or
//   //       have the errors get indexed by Googlebot however, it may
//   //       be a useful feature while actively debugging the page.
//   var DOM_ID = 'rendering-debug-pre'
//   if (!document.getElementById(DOM_ID)) {
//     var log = document.createElement('pre')
//     log.id = DOM_ID
//     log.style.whiteSpace = 'pre-wrap'
//     log.textContent = errorText
//     if (!document.body) document.body = document.createElement('body')
//     document.body.insertBefore(log, document.body.firstChild)
//   } else {
//     document.getElementById(DOM_ID).textContent += '\n\n' + errorText
//   }
// })

// import(/* webpackMode: "eager" */ 'entry/Root').then((mod) => {
// const Root = mod.default
import Root from "entry/Root";

window.React = React; // needed for graphiql

// ========================================================
// Render Setup
// ========================================================
// TODO use https://github.com/gaearon/react-hot-loader

const MOUNT_NODE = document.getElementById("root");

if (__PROD__) {
  window.SENTRY_RELEASE = __VERSION__;
  Sentry.init({
    release: __VERSION__,
    dsn: process.env.SENTRY_DSN,
    // This initializes Replay without starting any session
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: ["/api/v2/code/", "/api/graphql"],
      }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new ExtraErrorDataIntegration({ depth: 9 }),
    ],
    normalizeDepth: 10,
    environment: __PROD__ ? "production" : "stage",
    tracesSampleRate: 0,
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      "Mixpanel error",
      "recaptcha",
      "Failed to fetch",
      "segment performance",
      "Failed to load Analytics.js",
      "_refersion",
      "gtm",
      "Loading CSS chunk",
      `Non-Error promise rejection captured with value: Load failed`,
      "Jv().getRestrictions is not a function",
      "Iv().getRestrictions is not a function",
      "ResizeObserver loop limit exceeded",
    ],
    allowUrls: [/https?:\/\/((app|www|preview)\.)?dataquest\.io/],
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error && error.message) {
        if (error.message.match(/reading 'parentNode'/i)) {
          return null;
        }
        if (error.message.match(/Worker was terminated/i)) {
          const stackTrace = event.exception.values[0].stacktrace;
          if (
            stackTrace.frames.some(frame => frame.filename.includes("pdf.js"))
          ) {
            return null;
          }
        }
      }
      return event;
    },
  });
}

let render = () => {
  ReactDOM.render(<Root store={store} history={history} />, MOUNT_NODE);
};

if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render;
    const renderError = error => {
      const RedBox = require("redbox-react").default;

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
    };

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp();
      } catch (error) {
        renderError(error);
      }
    };

    // Setup hot module replacement
    module.hot.accept("./routes", () =>
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render();
      }),
    );
  }
}

// ========================================================
// Go!
// ========================================================
render();
// })
