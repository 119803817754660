function processFeatures(features) {
  const highlightRegex = /\[\[([^[\]]*)\]\]/g;
  if (!features) return features;

  return features.map((featureText: string) => {
    const highlights = [...featureText.matchAll(highlightRegex)].map(
      match => match[1], // get regex search term result (without brackets)
    );
    const newText = featureText.replace(highlightRegex, "");
    return {
      text: newText,
      highlights,
    };
  });
}
export default processFeatures;
