import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

// https://stackoverflow.com/questions/43399740/react-router-redirect-drops-param
import pathToRegexp from "path-to-regexp";

const RedirectWithParams = ({ exact, from, push, to }) => {
  // eslint-disable-line
  const pathTo = pathToRegexp.compile(to);
  return (
    <Route
      exact={exact}
      path={from}
      component={({ match: { params } }) => (
        <Redirect to={pathTo(params)} push={push} />
      )}
    />
  );
};

RedirectWithParams.propTypes = {
  exact: PropTypes.bool,
  push: PropTypes.func,
  from: PropTypes.string,
  to: PropTypes.string,
};

RedirectWithParams.defaultProps = {
  exact: false,
  push: undefined,
  from: "",
  to: "",
};

export default RedirectWithParams;
