import { inject_reducers } from "store/inject_reducers";
import reducer, {
  actions as simple_actions,
  private_actions,
  selectors,
  BASE_SELECTOR_PATH,
  ACTION_TYPES,
} from "./progress_simple";
import * as thunk_actions from "./progress_thunks";

export const actions = { ...simple_actions, ...thunk_actions };

export default inject_reducers({ [BASE_SELECTOR_PATH]: reducer });

export { selectors, BASE_SELECTOR_PATH, ACTION_TYPES, private_actions };
