import { inject_reducers } from "store/inject_reducers";
import utm_codes, {
  actions as simple_actions,
  private_actions,
  selectors,
  BASE_SELECTOR_PATH,
  ACTION_TYPES,
} from "./utm_codes_simple";
// import * as thunk_actions from './utm_codes_thunks'

export const actions = simple_actions;

export default inject_reducers({ utm_codes });

export { selectors, BASE_SELECTOR_PATH, ACTION_TYPES, private_actions };
