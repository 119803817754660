import { useQuery, useMutation } from "@apollo/client";
import OnboardingTracking from "views/OnboardingView/tracking";
import {
  ONBOARDING_QUERY,
  ONBOARDING_COMPLETE_STEP_MUTATION,
  ONBOARDING_SKIP_MUTATION,
  ONBOARDING_FINISH_MUTATION,
} from "queries/onboarding/queries";

export const useOnboardingQuery = (opts = {}) =>
  useQuery(ONBOARDING_QUERY, opts);

export const useOnboardingCompleteQuestionMutation = (opts = {}) => {
  const [onboardingCompleteStepMutation, status] = useMutation(
    ONBOARDING_COMPLETE_STEP_MUTATION,
    opts,
  );

  const completeStep = (questionId, answerData) => {
    const { questionText, choiceText, choiceId } = answerData;
    return onboardingCompleteStepMutation({
      ...opts,
      variables: { questionId, questionText, choiceText, choiceId },
    });
  };

  return [completeStep, status];
};

export const useOnboardingSkipMutation = (opts = {}) =>
  useMutation(ONBOARDING_SKIP_MUTATION, opts);

export const useOnboardingFinishMutation = (opts = {}) => {
  const [finishOnboardingMutation, status] = useMutation(
    ONBOARDING_FINISH_MUTATION,
    opts,
  );

  const finishOnboarding = (
    sequence,
    path,
    recommendationsJSON,
    featureFlags,
  ) =>
    finishOnboardingMutation({
      ...opts,
      variables: {
        recommendation_sequence: sequence,
        recommendation_path: path,
        recommendation_json: recommendationsJSON,
      },
    })
      .then(response => {
        const { data } = response;
        OnboardingTracking.completeOnboarding({
          mission_sequence: sequence,
          path_sequence: data.finish_onboarding.path_sequence || path,
          path_name: sequence ? data.finish_onboarding.path_name : "Catalog",
          featureFlags,
        });
        return data;
      })
      .catch(err => {
        console.warn(err);
        OnboardingTracking.completeOnboarding({
          mission_sequence: sequence,
          path_sequence: path,
          path_name: sequence ? null : "Catalog",
          featureFlags,
        });
      });

  return [finishOnboarding, status];
};
