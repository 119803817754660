import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import lazyRetry from "helpers/lazyRetry";
import LoginView from "views/LoginView";
import SignUpView from "views/SignUpView";
import ReferralSignUpView from "views/ReferralSignUpView";

import LessonRoutes from "routes/LessonRoutes";
import NonLandingRoutes from "routes/NonLandingRoutes";
import { MARKETING_SITE_CATALOG_URL } from "helpers/constants";
import { window_location } from "helpers/window_location";

import {
  selectors as userSelectors,
  actions as user_actions,
} from "redux/modules/user_info";
import TeamRoutes from "./teamRoutes";
import RedirectWithParams from "./RedirectWithParams";
import DqLoading from "../components/common/feedback/DqLoading";

const CertificateView = lazy(() =>
  lazyRetry(() => import("views/CertificateView"), "CertificateView"),
);
const SignUpSubscribeView = lazy(() =>
  lazyRetry(() => import("views/SignUpSubscribeView"), "SignUpSubscribeView"),
);
const LogoutView = lazy(() =>
  lazyRetry(() => import("views/LogoutView"), "LogoutView"),
);
const ResetPasswordView = lazy(() =>
  lazyRetry(() => import("views/ResetPasswordView"), "ResetPasswordView"),
);
const ForgotPasswordView = lazy(() =>
  lazyRetry(() => import("views/ForgotPasswordView"), "ForgotPasswordView"),
);
const CoreLayout = lazy(() =>
  lazyRetry(() => import("layouts/CoreLayout"), "CoreLayout"),
);

export const actions = {
  try_login_via_cookie: user_actions.try_login_via_cookie,
};

const selectors = {
  featureFlags: userSelectors.featureFlags,
};

const DirectoryRedirect = () => {
  window_location.redirect(MARKETING_SITE_CATALOG_URL);
  return null;
};

// eslint-disable-next-line react/prefer-stateless-function
export class LandingRoutes extends React.Component {
  // cannot be Pure or router stops
  static propTypes = {
    // redux
    try_login_via_cookie: PropTypes.func.isRequired,
    featureFlags: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loginLoading: true,
    };
  }

  componentDidMount() {
    this.props
      .try_login_via_cookie()
      .finally(() => this.setState({ loginLoading: false }));
  }

  render() {
    if (this.state.loginLoading) return <DqLoading fullScreen />;
    return (
      <Suspense fallback={<DqLoading fullScreen />}>
        <Switch>
          <Route path="/logout" component={LogoutView} />

          <Redirect from="/accounts/login" to="/login" />
          <Route path="/login" component={LoginView} />

          <Redirect from="/accounts/signup" to="/signup" />
          <Route path="/signup" component={SignUpView} />
          <Route
            path="/referral-signup/:referralCode/"
            component={ReferralSignUpView}
          />
          <Redirect from="/accounts/reset" to="/reset-password" />
          <RedirectWithParams
            from="/accounts/password_reset_confirm/:uid/:token"
            to="/reset-password/:uid/:token"
          />
          <Route
            path="/reset-password/:uid/:token"
            component={ResetPasswordView}
          />
          <Route path="/reset-password" component={ForgotPasswordView} />
          <Route
            path="/start-premium"
            component={props => <SignUpSubscribeView partnership {...props} />}
          />
          <Route
            path="/start-scholarship"
            component={props => <SignUpSubscribeView scholarship {...props} />}
          />

          <Route path="/catalog" component={DirectoryRedirect} />
          <Route path="/view_cert/:certificateId" component={CertificateView} />

          <Route path="/test-iframe-signup">
            <div>
              <h1>Test Signup in iFrame</h1>
              <iframe
                title="Test Signup iFrame"
                width="550"
                height="600"
                src="/test-iframe-lightbox"
              />
            </div>
          </Route>
          <Route path="/go">
            <div />
          </Route>
          <Route path="/test-iframe-lightbox">
            <iframe
              title="Test Lightbox iFrame"
              width="100%"
              height="100%"
              src="/signup#iframe"
            />
          </Route>

          <Route>
            <CoreLayout>
              <Switch>
                <Route
                  exact
                  path="/"
                  component={props => {
                    props.history.push("/dashboard");
                    return null;
                  }}
                />

                <Route
                  path="/team*"
                  component={props => (
                    <TeamRoutes
                      {...props}
                      featureFlags={this.props.featureFlags}
                    />
                  )}
                />
                <Route path="/m(ission)?" component={LessonRoutes} />
                <Route path="/c" component={LessonRoutes} />
                <Route component={NonLandingRoutes} />
              </Switch>
            </CoreLayout>
          </Route>
        </Switch>
      </Suspense>
    );
  }
}

export default connect(
  createStructuredSelector(selectors),
  actions,
)(LandingRoutes);
