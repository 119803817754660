import { inject_reducers } from "store/inject_reducers";
import catalog_info, {
  actions as simple_actions,
  private_actions,
  selectors,
  BASE_SELECTOR_PATH,
  ACTION_TYPES,
} from "./catalog_info_simple";
import * as thunk_actions from "./catalog_info_thunks";

export const actions = { ...simple_actions, ...thunk_actions };
export { ACTION_TYPES }; // for listeners

export default inject_reducers({ catalog_info });

// for testing
export { selectors, BASE_SELECTOR_PATH, private_actions };
