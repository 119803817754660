import React from "react";
import PropTypes from "prop-types";

import AuthLayout from "layouts/AuthLayout";
import SignUpForm from "components/auth/SignUp/SignUpForm";
import SignUpIframeForm from "components/auth/SignUp/SignUpIframeForm";
import SEO from "components/marketing/SEO";

import { window_location } from "helpers/window_location";

export class SignUpView extends React.PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      hash: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string,
        uid: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  static getQueryParam(queryParam) {
    return window_location.get_query_variable(queryParam);
  }

  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Sign up",
      pageDescription:
        "Create a free account at Dataquest and start learning data science today.",
      pageImageUrl: "",
    };
  }

  render() {
    const { pageTitle, pageDescription, pageImageUrl } = this.state;
    const signUpProps = {
      history: this.props.history,
    };

    if (window_location.isIframe()) {
      return (
        <div className="dq-text-center">
          <div className="dq-flex">
            <SEO
              title={pageTitle}
              description={pageDescription}
              imageUrl={pageImageUrl}
            />
            <SignUpIframeForm
              {...signUpProps}
              getQueryParam={SignUpView.getQueryParam}
            />
          </div>
        </div>
      );
    }

    return (
      <AuthLayout signUp>
        <SEO
          title={pageTitle}
          description={pageDescription}
          imageUrl={pageImageUrl}
        />
        <SignUpForm {...signUpProps} />
      </AuthLayout>
    );
  }
}

export default SignUpView;
