import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

export class MarketingInfoBlock extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    centered: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    children: undefined,
    centered: false,
    className: "",
    style: undefined,
  };

  render() {
    const { centered, className, children, ...pass } = this.props;

    return (
      <div
        className={cn(
          "dq-marketing-infoblock dq-flex dq-flex-col ",
          centered
            ? "dq-items-center dq-mx-auto dq-text-center md:dq-max-w-sm"
            : "dq-w-full dq-items-start md:dq-max-w-2xl ",
          className,
        )}
        style={this.props.style}
        {...pass}
      >
        {children}
      </div>
    );
  }
}

export default MarketingInfoBlock;
