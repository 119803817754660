import { gql } from "@apollo/client";

export const SAVE_PROGRESS_NOTIFICATION_SHOWN_QUERY = gql`
  query SaveProgressNotificationShown {
    cachedSettings @client {
      saveProgressNotificationShown
    }
  }
`;

export const USER_SETTINGS_QUERY = gql`
  query UserSettingsQuery {
    settings {
      autocomplete
      text_theme
      editor_theme
      sound_notifications
      appstream_notifications
      remote_app_sessions
      display_answers
      display_solutions
      content_skip
    }
  }
`;

export const USER_SETTINGS_MUTATION = gql`
  mutation UserSettingsMutation(
    $autocomplete: Boolean
    $text_theme: Theme
    $editor_theme: Theme
    $sound_notifications: Boolean
    $appstream_notifications: Boolean
    $remote_app_sessions: Boolean
  ) {
    update_settings(
      settings_data: {
        autocomplete: $autocomplete
        text_theme: $text_theme
        editor_theme: $editor_theme
        sound_notifications: $sound_notifications
        appstream_notifications: $appstream_notifications
        remote_app_sessions: $remote_app_sessions
      }
    ) {
      success
    }
  }
`;
