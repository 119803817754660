const toCamelCase = s =>
  s.replace(/([-_][a-z])/gi, $1 =>
    $1
      .toUpperCase()
      .replace("-", "")
      .replace("_", ""),
  );

export const pickCamelCase = (obj, keys) => {
  const newObj = {};
  keys.forEach(key => {
    newObj[toCamelCase(key)] = obj[key];
  });
  return newObj;
};

export const camelToSnakeCase = str =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const objToSnakeCase = obj => {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    newObj[camelToSnakeCase(key)] = obj[key];
  });
  return newObj;
};

export default toCamelCase;
