import React from "react";
import { Link } from "react-router-dom";
import map from "lodash/map";
import DqButton from "components/common/elements/DqButton";
import DqLogo from "components/common/brand/DqLogo";

const footer_links = {
  left: [
    {
      name: "Help",
      to: "https://community.dataquest.io",
      target: "_blank",
    },
    {
      name: "Blog",
      to: "https://www.dataquest.io/blog",
      target: "_blank",
    },
    { name: "Catalog", to: "/catalog" },
    {
      name: "Community",
      to: "https://community.dataquest.io",
      target: "_blank",
    },
  ],
  center: [
    {
      name: "Pricing",
      to: "/payment",
      source: "footer_pricing",
    },
    {
      name: "About Us",
      to: "https://www.dataquest.io/were-hiring/",
      target: "_blank",
    },
    {
      name: "Legals",
      to: "https://www.dataquest.io/terms/",
      target: "_blank",
    },
    {
      name: "Privacy",
      to: "https://www.dataquest.io/privacy/",
      target: "_blank",
    },
  ],
  right: [
    {
      name: "Facebook",
      to: "https://www.facebook.com/dataquestio",
      target: "_blank",
    },
    {
      name: "Twitter",
      to: "https://twitter.com/dataquestio",
      target: "_blank",
    },
    {
      name: "LinkedIn",
      to: "https://www.linkedin.com/company/dataquest-io",
      target: "_blank",
    },
  ],
};

function renderLink(info) {
  if (info.target)
    return (
      <DqButton
        href={info.to}
        key={info.name}
        target={info.target}
        text={info.name}
        variant="text"
        color="white"
        size="xsmall"
      />
    );

  return (
    <DqButton
      key={info.name}
      to={info.to}
      source={info.source}
      text={info.name}
      variant="text"
      color="white"
      size="xsmall"
    />
  );
}

export default function DqFooter() {
  const year = new Date().getFullYear();
  const { left, center, right } = footer_links;

  return (
    <footer className="dq-flex dq-flex-wrap dq-bg-gray-800 dq-text-white dq-text-xs dq-p-8">
      <div className="dq-w-full lg:dq-w-2/3 dq-pr-12">
        <Link to="/dashboard" title="Dashboard" aria-label="Dashboard">
          <DqLogo className="dq-text-white hover:dq-text-white dq-w-36" />
        </Link>
        <p className="dq-text-xs dq-mt-6 dq-leading-5">
          All rights reserved &copy; {year} <br />
          Dataquest Labs, Inc. <br />
          We are committed to protecting your personal information and your
          right to privacy. Privacy Policy last updated June 13th, 2019 –{" "}
          <DqButton
            className="!dq-text-xs"
            href="/privacy"
            target="_blank"
            text="review here."
            variant="text"
            color="white"
            disableTitleCase
          />
        </p>
      </div>
      <div className="dq-w-full lg:dq-w-1/3 dq-flex dq-mt-6 lg:dq-mt-0">
        <div className="dq-flex dq-flex-col dq-items-start dq-space-y-4 dq-w-1/3">
          {map(left, renderLink)}
        </div>
        <div className="dq-flex dq-flex-col dq-items-start dq-space-y-4 dq-w-1/3">
          {map(center, renderLink)}
        </div>
        <div className="dq-flex dq-flex-col dq-items-start dq-space-y-4 dq-w-1/3">
          {map(right, renderLink)}
        </div>
      </div>
    </footer>
  );
}
