export default {
  patterns: ["progress/", "container_status_info/", "weekly_goals_info/"],
  exact: [
    "steps_info/reset_code",
    "steps_info/persist_code",
    "steps_info/save_code",
    "steps_info/run_code",
    "steps_info/reset_code",
    "steps_info/keep_alive",
    "steps_info/start_answer_checking",
    "steps_info/fail_checking",
  ],
};
