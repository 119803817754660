/* eslint-disable max-classes-per-file */
// sentry
import * as Sentry from "@sentry/react";

// react
import React from "react";
import PropTypes from "prop-types";
// router
import { Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { withRouter } from "react-router";

// redux
import { Provider as ReduxProvider, connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// apollo
import { ApolloProvider } from "@apollo/client";
import createApolloClient from "queries/apollo";

// react-loading-skeleton
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import ResizeObserver from "resize-observer-polyfill";

// TODO verify that this different import doesn't break rest of the apollo usage
// utils
import find from "lodash/findKey";
import SEO from "components/marketing/SEO";
import ReplayMonitoring from "helpers/ReplayMonitoring";

// styles
import "../styles/tailwind.css";

// CASL
import { AbilityContext } from "providers/casl/Can";
import defineAbility from "providers/casl/defineAbility";

import Routes from "routes/routes";
import history from "store/history";
import ABTestProvider from "components/ab_tests";
import StripeProvider from "components/payment/StripeProvider";

import { selectors as profileSelectors } from "redux/modules/profile_info";
import {
  selectors as userSelectors,
  actions as user_actions,
} from "redux/modules/user_info";
import { selectors as teamSelectors } from "redux/modules/team_info";
import { selectors as paymentSelectors } from "redux/modules/payment_info";
import { actions as utm_actions } from "redux/modules/utm_codes";
import PromotionProvider from "providers/PromotionProvider";
import UserSettingsProvider from "providers/UserSettingsProvider";
import { ROUTE_TITLE_MAPPING } from "../routes/helpers";
import { MARKETING_SITE_URL } from "../helpers/constants";

const selectors = {
  is_logged_in: userSelectors.is_logged_in,
  is_teammember: userSelectors.is_teammember,
  hasIndividualPlan: userSelectors.hasIndividualPlan,
  hasAnySubscription: paymentSelectors.hasAnySubscription,
  is_subscribed: userSelectors.is_subscribed,
  canEditProfile: state => profileSelectors.requestedProfile(state).canEdit,
  isTeamOwner: teamSelectors.isOwner,
  isTeamAdmin: teamSelectors.isAdmin,
  isLifetimeSubscriber: userSelectors.isLifetimeSubscriber,
  hasUnpaidPremium: userSelectors.hasUnpaidPremium,
};

export const actions = {
  ...user_actions,
  save_utm_codes: utm_actions.save_utm_codes,
};

window.ResizeObserver = window.ResizeObserver || ResizeObserver;

export const client = createApolloClient();

const handleBrokenDomain = () => {
  if (/.io.$/.test(window.location.host)) {
    const hostname = window.location.host.replace(".io.", ".io");
    const { protocol, pathname } = window.location;
    window.location.replace(`${protocol}//${hostname}${pathname}`);
  }
};

const handleMarketingRedirect = () => {
  if (window.location.pathname.startsWith("/go/")) {
    const nextRoutePathname = window.location.pathname.replace("/go/", "");
    const nextRoute = `${MARKETING_SITE_URL}/${nextRoutePathname}?${window.location.search}`;
    window.location.replace(nextRoute);
  }
};

const SEOWrapper = props => {
  const titleKeys = Object.keys(ROUTE_TITLE_MAPPING);
  const titleIndex = find(
    Object.keys(ROUTE_TITLE_MAPPING),
    o => props.location.pathname.indexOf(o) !== -1,
  );
  if (!titleIndex) return null;
  const titleInfo = ROUTE_TITLE_MAPPING[titleKeys[titleIndex]];

  return (
    <SEO
      title={titleInfo.title}
      canonical={titleKeys[titleIndex]}
      description={titleInfo.description}
    />
  );
};
SEOWrapper.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const SEOComponent = withRouter(SEOWrapper);

export class Root extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    // redux
    save_utm_codes: PropTypes.func.isRequired,
    document_search_params: PropTypes.func,

    setUniqueSessionId: PropTypes.func.isRequired,
  };

  static defaultProps = {
    document_search_params: () => document.location.search,
  };

  constructor(props) {
    handleBrokenDomain();

    super(props);
    // This sets a unique ID for a session to use in the tracking calls
    props.setUniqueSessionId();
  }

  componentDidMount() {
    // startup actions
    this.props.save_utm_codes(this.props.document_search_params());
    handleMarketingRedirect();
  }

  render() {
    const {
      store,
      is_logged_in,
      is_teammember,
      hasIndividualPlan,
      hasAnySubscription,
      is_subscribed,
      canEditProfile,
      isTeamOwner,
      isTeamAdmin,
      isLifetimeSubscriber,
      hasUnpaidPremium,
    } = this.props;

    return (
      <ApolloProvider client={client}>
        <ReduxProvider store={store}>
          <ABTestProvider>
            <StripeProvider>
              <ConnectedRouter history={history}>
                <ReplayMonitoring />
                <PromotionProvider>
                  <UserSettingsProvider>
                    <AbilityContext.Provider
                      value={defineAbility({
                        isLoggedIn: is_logged_in,
                        isTeammember: is_teammember,
                        isSubscribed: is_subscribed,
                        hasIndividualPlan,
                        canEditProfile,
                        isTeamOwner,
                        isTeamAdmin,
                        hasAnySubscription,
                        isLifetimeSubscriber,
                        hasUnpaidPremium,
                      })}
                    >
                      <SEOComponent />
                      <Switch>
                        <SkeletonTheme borderRadius={0}>
                          <div className="dq-min-h-full">
                            <Routes />
                          </div>
                        </SkeletonTheme>
                      </Switch>
                    </AbilityContext.Provider>
                  </UserSettingsProvider>
                </PromotionProvider>
              </ConnectedRouter>
            </StripeProvider>
          </ABTestProvider>
        </ReduxProvider>
      </ApolloProvider>
    );
  }
}

export default Sentry.withProfiler(
  connect(
    createStructuredSelector(selectors),
    actions,
  )(Root),
);
