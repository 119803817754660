import get from "lodash/get";
import find from "lodash/find";

const authenticationValidator = excludeList => store => next => action => {
  if (get(store.getState(), "user_info", null)) {
    const isAuthenticated =
      Boolean(get(store.getState(), "user_info.username", null)) &&
      !get(store.getState(), "user_info.askingForUser", false);

    if (!isAuthenticated) {
      if (
        excludeList.exact.indexOf(action.type) !== -1 ||
        find(excludeList.patterns, pattern => action.type.includes(pattern))
      ) {
        return;
      }
    }
  }

  return next(action);
};

export default authenticationValidator;
