// https://www.codemzy.com/blog/fix-chunkloaderror-react
/**
 * Asynchronously loads a component, and retries if the initial import fails.
 * @param {Function} componentImport - A function that returns a Promise that resolves to the component to load.
 * @param {string} name - A unique name for the component, used to store retry state in sessionStorage.
 * @returns {Promise} A Promise that resolves to the loaded component, or rejects with the last error if retries were exhausted.
 */
const lazyRetry = (componentImport, name) =>
  new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false",
    );
    // try to import the component
    componentImport()
      .then(component => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });

export default lazyRetry;
