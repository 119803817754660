import { gql } from "@apollo/client";

export const PROMOTIONS_QUERY = gql`
  query Promotion {
    promos {
      label
      code
      start
      end
      show_countdown_timer
      tagline
      cta_text
      page_tagline
      personalized_end
      features
      banner_color
      use_text_only_timer
    }
  }
`;

export const PROMOTION_QUERY = gql`
  query Promotion {
    promo {
      label
      code
      start
      end
      show_countdown_timer
      tagline
      callout
      cta_text
      page_tagline
      personalized_end
      features
      default
      show_banner
      banner_color
      use_text_only_timer
    }
  }
`;
