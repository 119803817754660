import Immutable from "seamless-immutable";
import dayjs from "dayjs";
import { make_simple_selectors, make_reducer_n_actions } from "redux_helpers";

import processFeatures from "redux/helpers/processFeatures";

// -------
// Initial State
// --------

export const initial_state = {
  lessonPaywallLoaded: false,
  lessonPaywalls: [],
};

// -------
// Selectors
// --------
const BASE = "paywall_info";
export { BASE as BASE_SELECTOR_PATH };

// $FlowFixMe
const simpleSelectors = make_simple_selectors(initial_state, BASE);

export const selectors = {
  ...simpleSelectors,
};

const ALL_LESSONS_TYPE = "ALL_LESSONS";
const FREE_LESSONS_TYPE = "FREE_LESSON";
const PAYWALL_LESSONS_TYPE = "LOCKED_LESSON";

// ------------------------------------
// Reducer and Actions
// ------------------------------------
const action_types_prefix = "paywall_info/";

const private_handlers = {
  updateLessonPaywallSettings: (state, { payload }) => {
    const lessonPaywalls = [];
    payload.forEach(paywallSettings => {
      if (dayjs(paywallSettings.end) > dayjs(Date.now()))
        lessonPaywalls.push({
          ...paywallSettings,
          features: processFeatures(paywallSettings.features),
          allLessons: paywallSettings.paywall_type === ALL_LESSONS_TYPE,
          freeLessons: paywallSettings.paywall_type === FREE_LESSONS_TYPE,
          paywallLessons: paywallSettings.paywall_type === PAYWALL_LESSONS_TYPE,
        });
    });

    return state.merge({
      lessonPaywallLoaded: true,
      lessonPaywalls,
    });
  },
};

export const {
  reducer,
  private_actions,
  actions,
  ACTION_TYPES,
} = make_reducer_n_actions({
  private_handlers,
  action_types_prefix,
  initial_state,
  Immutable,
});
export default reducer;
