import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import SocialSignOn from "components/auth/SocialSignOn";
import ReCAPTCHA from "components/auth/Recaptcha";
import DqButton from "components/common/elements/DqButton";
import DqInput from "components/common/forms/DqInput";
import withSignUp from "./withSignUp";

const SignUpIframeForm = ({
  data,
  getQueryParam,
  handleOnSubmit,
  handleOnChange,
  recaptchaHandleOnChange,
  recaptchaHandleOnErrored,
  socialSignupSuccess,
  socialSignupFailure,
  renderGeneralErrors,
  updateMfaToken,
  displayMFARequest,
  loadReCAPTCHA,
}) => {
  const [loadedQuery, setLoadedQuery] = useState(false);
  const [signupCTAText, setSignupCTAText] = useState("Sign Up");
  const [signupCTAColor, setSignupCTAColor] = useState("blue");
  const [signupCTAStyle, setSignupCTAStyle] = useState({});
  const [inputStyle, setInputStyle] = useState({});
  const [inputClassName, setInputClassName] = useState("");
  const [formWrapperClassName, setFormWrapperClassName] = useState("");
  const [formWrapperStyle, setFormWrapperStyle] = useState({});
  const [formWrapperColor, setFormWrapperColor] = useState("");
  const [ssoAbovePlacement, setSsoAbovePlacement] = useState(false);

  const parseStyleOrClass = param => {
    if (param.indexOf(":") === -1) return [{}, param.split(",").join(" ")];
    const style = {};
    const keyValuePairs = param.split(",");
    keyValuePairs.forEach(pair => {
      const [key, value] = pair.split(":");
      style[key] = value;
    });
    return [style, ""];
  };

  const parseStyleOrColor = param => {
    if (param.startsWith("#")) return [{ backgroundColor: param }, ""];
    return [{}, param];
  };

  useEffect(() => {
    const signupCTATextQ = getQueryParam("signupCTAText");
    const signupCTAColorQ = getQueryParam("signupCTAColor");
    const formColorQ = getQueryParam("formColor");
    const formStyleQ = getQueryParam("formStyle");
    const inputStyleQ = getQueryParam("inputStyle");
    const ssoAbovePlacementQ = getQueryParam("ssoAbovePlacement");

    if (signupCTATextQ) setSignupCTAText(signupCTATextQ);
    if (signupCTAColorQ) {
      const [style, color] = parseStyleOrColor(signupCTAColorQ);
      setSignupCTAStyle(style);
      setSignupCTAColor(color);
    }
    if (inputStyleQ) {
      const [style, className] = parseStyleOrClass(inputStyleQ);
      setInputStyle(style);
      setInputClassName(className);
    }
    if (formColorQ) {
      const [style, color] = parseStyleOrColor(formColorQ);
      setFormWrapperColor(color ? `dq-bg-${color}` : "");
      setFormWrapperStyle(style);
    }
    if (formStyleQ) {
      const [style, className] = parseStyleOrClass(formStyleQ);
      setFormWrapperClassName(className);
      setFormWrapperStyle(style);
    }
    if (ssoAbovePlacementQ) setSsoAbovePlacement(true);

    setLoadedQuery(true);
  }, []);

  const renderField = ({ key, ...attr }) => (
    <DqInput
      {...attr}
      id={key}
      value={data.formValues[key]}
      onChange={handleOnChange}
      disabled={data.submitting}
      style={inputStyle}
      inputClassName={inputClassName}
    />
  );

  if (!loadedQuery) return null;

  if (displayMFARequest) {
    return (
      <div className="dq-sign-up dq-max-w-full dq-flex dq-flex-col dq-items-stretch dq-my-0 dq-mx-auto dq-pt-10 dq-px-1">
        <h2 className="dq-text-3xl dq-mb-4">2-Step Verification</h2>
        <p>
          Please launch Google Authenticator application and enter the displayed
          code.
        </p>
        {renderGeneralErrors()}
        <form
          onSubmit={handleOnSubmit}
          className="dq-max-w-full dq-flex dq-flex-col dq-items-stretch dq-my-0 dq-mx-auto"
        >
          <div className="dq-flex dq-flex-col">
            <DqInput
              label="Code"
              id="token"
              data-test-selector="token"
              placeholder="--- ---"
              disabled={data.submitting}
              value={data.mfaToken}
              onChange={updateMfaToken}
              style={inputStyle}
              inputClassName={inputClassName}
            />
          </div>
        </form>
        <div>
          <DqButton
            data-test-selector="submit"
            type="submit"
            className="dq-w-full dq-uppercase"
            style={signupCTAStyle}
            color={signupCTAColor}
            loading={data.submitting}
            onClick={handleOnSubmit}
            text="Verify"
          />
        </div>
      </div>
    );
  }
  return (
    <div
      className={cn(
        "dq-sign-up dq-max-w-full dq-flex dq-flex-col dq-items-stretch dq-my-0 dq-mx-auto dq-pt-10 dq-px-1",
        formWrapperClassName,
        formWrapperColor,
      )}
      style={formWrapperStyle}
    >
      {renderGeneralErrors()}
      <form
        onSubmit={handleOnSubmit}
        className="dq-max-w-full dq-flex dq-flex-col dq-items-stretch dq-my-0 dq-mx-auto"
      >
        {ssoAbovePlacement && (
          <>
            <SocialSignOn
              className="!dq-m-0"
              onSuccess={socialSignupSuccess}
              onFailure={socialSignupFailure}
            />
            <p className="dq-lowercase dq-text-lg dq-m-0">Or</p>
          </>
        )}
        {renderField({
          "data-test-selector": "email",
          type: "email",
          key: "email",
          placeholder: "Email",
        })}
        {renderField({
          "data-test-selector": "password",
          type: "password",
          key: "password",
          placeholder: "Password",
        })}
        <div>
          <DqButton
            data-test-selector="submit"
            type="submit"
            className="dq-w-full dq-uppercase"
            color={signupCTAColor}
            style={signupCTAStyle}
            loading={data.submitting}
            text={signupCTAText}
          />
        </div>
        {!ssoAbovePlacement && (
          <>
            <p className="dq-lowercase dq-text-lg dq-m-0">Or</p>
            <SocialSignOn
              onSuccess={socialSignupSuccess}
              onFailure={socialSignupFailure}
            />
          </>
        )}
        {loadReCAPTCHA && (
          <ReCAPTCHA
            recaptchaRef={data.recaptchaRef}
            handleOnChange={recaptchaHandleOnChange}
            handleOnErrored={recaptchaHandleOnErrored}
          />
        )}
      </form>
    </div>
  );
};

SignUpIframeForm.propTypes = {
  data: PropTypes.shape({
    recaptchaRef: PropTypes.node,
    submitting: PropTypes.bool,
    mfaToken: PropTypes.string,
    formValues: PropTypes.shape({}),
  }).isRequired,
  getQueryParam: PropTypes.bool.isRequired,
  displayMFARequest: PropTypes.bool.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  recaptchaHandleOnChange: PropTypes.func.isRequired,
  recaptchaHandleOnErrored: PropTypes.func.isRequired,
  socialSignupSuccess: PropTypes.func.isRequired,
  socialSignupFailure: PropTypes.func.isRequired,
  renderGeneralErrors: PropTypes.func.isRequired,
  updateMfaToken: PropTypes.func.isRequired,
  loadReCAPTCHA: PropTypes.bool.isRequired,
};

export default withSignUp(SignUpIframeForm);
