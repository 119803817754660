/* eslint-disable no-underscore-dangle */
import createStore from "./createStore";

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__;
window.__STORE__ = createStore(initialState);
if (__TEST__) {
  window.__STORE__ = {
    asyncReducers: {},
    getState: () => ({}),
    replaceReducer: () => {},
    dispatch: () => {},
    subscribe: () => {},
  };
}

export default () => window.__STORE__;
