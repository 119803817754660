/* eslint-disable no-console */
import { dq_graphql, dq_graphql_all } from "redux/utils/dq_fetch";
import { private_actions, selectors } from "./catalog_info_simple";

import { actions as progress_actions } from "../progress";

export const fetchCurrentLesson = (slugs = {}) => (dispatch, _getState) => {
  dispatch(private_actions.resetCurrentLesson());

  let graphql = `{
    current_mission {
      name,
      slug,
      sequence,
      course_sequence,
    }
  }`;

  if (slugs.course_slug) {
    graphql = `{ course(slug: "${slugs.course_slug}")${graphql} }`;
  } else if (slugs.path_slug) {
    graphql = `{ path(slug: "${slugs.path_slug}")${graphql} }`;
  } else {
    graphql = `{ path(selected: true)${graphql} }`;
  }
  return dq_graphql(graphql)
    .then(data => {
      let lessonData = null;
      if (data.data) {
        if (slugs.course_slug && data.data.course) {
          lessonData = data.data.course.current_mission;
        } /* slugs.path_slug */ else if (data.data.path) {
          lessonData = data.data.path.current_mission;
        }
      }
      dispatch(private_actions.setCurrentLesson(lessonData));
      return data;
    })
    .catch(console.warn);
};

const graphql_course_details = `
  sequence
  title: name
  description
  slug
  meta_title
  meta_description
  skill_level
  learn {
    text
  }
`;

const graphqlABLesson = sequence => `{
  mission(sequence: ${sequence}) {
    sequence
    title: name
    description
    slug
    screen_count
    type
  }
}`;

export const fetchAbLessonData = lessonInfo => (dispatch, getState) =>
  dq_graphql(graphqlABLesson(lessonInfo.lesson))
    .then(({ data }) => {
      dispatch(
        private_actions.injectLesson({
          ...lessonInfo,
          lesson: {
            ...data.mission,
            beta: true,
            optional: true,
          },
        }),
      );
    })
    .catch(console.warn);

const grapqhMyPathDataOnly = `{
  path(selected: true) {
    sequence
    title: name
    slug
    type
  }
}`;

export const fetchMyPathData = () => (dispatch, getState) =>
  dq_graphql(grapqhMyPathDataOnly)
    .then(({ data }) => {
      dispatch(private_actions.set_a_path(data.path));
    })
    .catch(console.warn);

const graph_a_course = (slug, sequence) => {
  const graph_course_squence = sequence ? `, sequence: ${sequence}` : "";

  return `{
    course(slug: "${slug}" ${graph_course_squence}) {
      ${graphql_course_details}
      in_paths {
        title: name
        slug
      }
      missions {
        title: name,
        type
        sequence
        description
        is_new
        is_behind_paywall
        is_behind_paywall_current_path
        learn {
          text
          icon
        }
        screen_count
      }
    }
  }`;
};

export const fetch_a_course = (slug, sequence) => (dispatch, getState) => {
  if (selectors.course_for_page(getState()).slug !== slug) {
    dispatch(private_actions.reset_a_course());
  }

  // For assessments there might not be a course sequence or slug
  if (slug || sequence) {
    dispatch(progress_actions.getCourse({ slug, sequence }));
  }

  return dq_graphql(graph_a_course(slug, sequence))
    .then(data => {
      dispatch(private_actions.set_a_course(data.data.course));
      return data;
    })
    .catch(() => {
      dispatch(private_actions.set_course_not_found());
    });
};
// This query is cached in the backend. The exact query string is used to retrieve the cache
// Please update the key in the `graphql_cache_utils.py` accordingly in case if this query changes
const graphql_directory = `{
  paths {
    sequence
    title: name
    description
    slug
    skill_level
    learn {
      text
    }
    type
    steps {
      sequence
      courses {
        sequence
        title: name
        slug
        skill_level
        learn {
          text
        }
      }
    }
  }
}`;

const graphql_directory_selected = `{
  paths {
    sequence
    selected
  }
}`;

export const fetch_directory = () => (dispatch, _getState) => {
  dispatch(progress_actions.getDirectory());
  return dq_graphql_all([graphql_directory, graphql_directory_selected])
    .then(data => dispatch(private_actions.set_directory(data.data.paths)))
    .catch(console.warn);
};

export const select_path = (id, version = null, deselect = false) => (
  dispatch,
  getState,
) => {
  const mutation = `mutation {
    select_path(path_sequence:${id}, path_version: ${version}, deselect:${deselect}) {
      success
    }
  }`;

  // Progress state should be forced back to initial on path change,
  // otherwise stale progress is displayed until corresponding progress data is fetched and calculated
  dispatch(progress_actions.reset());
  if (!deselect) dispatch(private_actions.set_active_path(id));
  dispatch(private_actions.reset_a_path());

  return dq_graphql(mutation).catch(e => console.warn(e));
};

export const selectCourse = (id, deselect = false) => (dispatch, getState) => {
  const mutation = `mutation {
    select_course(sequence:${id}, deselect:${deselect}) {
      success
    }
  }`;

  // Progress state should be forced back to initial on path change,
  // otherwise stale progress is displayed until corresponding progress data is fetched and calculated
  dispatch(progress_actions.reset());
  dispatch(private_actions.reset_a_path());

  return dq_graphql(mutation).catch(e => console.warn(e));
};
