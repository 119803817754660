// @flow
import type { AbstractComponent, Node } from "react";
import React, { useMemo } from "react";

import SignUpForm from "components/auth/SignUp/SignUpForm";
import SEO from "components/marketing/SEO";
import { withRouter } from "react-router";
import { window_location } from "helpers/window_location";

import DqCard from "components/common/layout/DqCard";

import { useReferralRewardsQuery } from "queries/referral/hooks";

import referralImage from "images/referral/referral-social-new.png";
import referralImageOld from "images/referral/referral-social.png";
import ReferralPitch from "components/account/ReferralProgram/ReferralPitch";
import SocialProof from "components/marketing/SocialProof/SocialProof";
import { PITCHES } from "components/account/ReferralProgram/constants";

type Props = {
  match: {
    params: {
      referralCode?: string,
    },
  },
  history: {
    replace: any,
  },
};

export const ReferralSignUpView = ({ history, match }: Props): Node => {
  const {
    data: rewardsData,
    loading: rewardsDataLoading,
  } = useReferralRewardsQuery({
    fetchPolicy: "cache-and-network",
  });

  const getQueryParam = queryParam =>
    window_location.get_query_variable(queryParam);

  const pageTitle = "Learn Data Science 4x Faster with Dataquest";
  const pageDescription =
    "Become a (Python, SQL, Power BI, Machine Learning) pro. Become job-ready in months. No prerequisites. Start learning data science for free!";

  const signUpProps = {
    history,
    signupReferralCode: match.params.referralCode,
    signupReferralEmail: getQueryParam("email"),
    isReferral: true,
  };

  const rewardPitch = useMemo(() => {
    if (!rewardsData?.rewards?.rewardReferrer?.type) return null;
    const pitch = PITCHES[(rewardsData?.rewards?.rewardReferrer?.type)];
    return {
      header: pitch?.signupHeader(rewardsData?.rewards),
    };
  }, [rewardsData]);

  const isNewProgram = useMemo(
    () => rewardsData?.rewards?.rewardReferrer?.type === "cash_equivalent",
    [rewardsData],
  );

  if (rewardsDataLoading) return null;

  return (
    <div>
      <SEO
        title={pageTitle}
        description={pageDescription}
        imageUrl={isNewProgram ? referralImage : referralImageOld}
      />

      <div className="dq-max-w-6xl dq-mx-auto dq-py-5 dq-flex dq-flex-col dq-justify-between lg:dq-flex-row lg:dq-py-10">
        <div className="dq-text-lg dq-space-y-5 dq-my-5 lg:dq-max-w-lg">
          <h1 className="dq-font-bold dq-mb-5">
            <span className="dq-text-purple">
              Learn technical skills 4x faster
            </span>{" "}
            with Dataquest
          </h1>
          <p>
            Learn Python, SQL, Power BI, Excel, and R faster with
            Dataquest&apos;s real-world projects and exercises. Skip the boring
            videos and start coding today.
          </p>
          <p>
            Featured learning paths includes:
            <br />
            Data Scientist in Python, Business Analyst with Power BI, Web
            Development, and more!
          </p>
        </div>

        <DqCard shadow>
          <SignUpForm {...signUpProps} title={rewardPitch?.header} />
        </DqCard>
      </div>
      {isNewProgram && (
        <>
          <div className="dq-max-w-6xl dq-mx-auto dq-pb-10">
            <ReferralPitch newSignup />
          </div>
          <SocialProof />
        </>
      )}
    </div>
  );
};
export default (withRouter(ReferralSignUpView): AbstractComponent<Props>);
