// @flow
import type { Node } from "react";
import React from "react";
import cn from "classnames";

export type dqBackdropT = {
  /**
   * Add additional style classes
   */
  className?: string,
  /**
   * Make the backdrop invisible. It can be used when rendering
   * multiple backdrops to prevent a darker background
   */
  invisible?: boolean,
  /**
   * Skip backdrop rendering
   */
  enabled?: boolean,
  /**
   * Function to execute when clicking the backdrop
   */
  onClick?: Function,
  /**
   * The content of the component
   */
  children: Node,
  /**
   * Optional style for the component
   */
  style?: any,
};

const DqBackdrop = ({
  className = "",
  style = null,
  invisible = false,
  enabled = true,
  onClick,
  children,
  ...rest
}: dqBackdropT): Node =>
  enabled ? (
    // $FlowFixMe
    <div
      role="presentation"
      style={style || {}}
      className={cn(
        "dq-fixed dq-inset-0 dq-z-50",
        {
          "dq-bg-gray-800/80": !invisible,
        },
        className,
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  ) : (
    children
  );

DqBackdrop.defaultProps = {
  className: "",
  style: null,
  invisible: false,
  enabled: true,
  onClick: () => {},
};

export default DqBackdrop;
