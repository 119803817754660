import { selectors as user_selectors } from "redux/modules/user_info";

/**
 * Structured data for evaluating AB tests with user data.
 *
 * TODO: Make this more extensible as we decouple from Redux
 */
export class ABTestUserData {
  is_staff = false;

  user_name = null;

  user_id = null;

  constructor(is_staff, user_name, user_id) {
    this.is_staff = is_staff;
    this.user_name = user_name;
    this.user_id = user_id;
  }
}

export function make_from_redux(getState) {
  const is_staff = user_selectors.is_staff(getState());
  const user_name = user_selectors.user_name(getState());
  const user_id = user_selectors.user_id(getState());

  return new ABTestUserData(is_staff, user_name, user_id);
}
