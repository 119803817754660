import Joi from "joi";

const genericMessages = {
  string: {
    empty: "This field may not be blank.",
    email: "Enter a valid email address.",
    uri: "Enter a valid URL.",
  },
};

const emailSchema = Joi.string()
  .email({ tlds: false })
  .required()
  .messages({
    "string.empty": "Email may not be blank.",
    "string.email": genericMessages.string.email,
  });

const passwordSchema = Joi.string()
  .min(8)
  .max(255)
  .required()
  .messages({
    "string.empty": "Password may not be blank.",
    "string.min":
      "This password is too short. It must contain at least 8 characters.",
    "string.max": "Incorrect password.",
    "any.required": "Password may not be blank.",
  });

const nameSchema = Joi.string()
  .required()
  .messages({
    "string.empty": "Please enter your name.",
  });

const loginPasswordSchema = Joi.string()
  .min(8)
  .max(255)
  .required()
  .messages({
    "string.empty": "Please enter your password.",
    "string.min": "Incorrect password.",
    "string.max": "Incorrect password.",
  });

const SignUpSchema = Joi.object({
  name: nameSchema,
  email: emailSchema,
  password: passwordSchema,
});

export const validateSignUp = values =>
  SignUpSchema.validate(values, { abortEarly: false });

const SignUpIframeSchema = Joi.object({
  email: emailSchema,
  password: passwordSchema,
  // The iframe Sign Up form do not display these fields,
  // so they are empty.
  name: Joi.string().allow(""),
});

export const validateSignUpIframe = values =>
  SignUpIframeSchema.validate(values, { abortEarly: false });

const LoginSchema = Joi.object({
  email: emailSchema,
  password: loginPasswordSchema,
});

export const validateLogin = values =>
  LoginSchema.validate(values, { abortEarly: false });

const ForgotPasswordSchema = Joi.object({
  email: emailSchema,
});

export const validateForgotPassword = values =>
  ForgotPasswordSchema.validate(values, { abortEarly: false });

const ResetPasswordSchema = Joi.object({
  password: passwordSchema,
});

export const validateResetPassword = values =>
  ResetPasswordSchema.validate(values, { abortEarly: false });

const ReferralInviteSchema = Joi.object({
  email: emailSchema,
  name: nameSchema,
  message: Joi.string().allow(""),
});

export const validateReferralInvite = values =>
  ReferralInviteSchema.validate(values, { abortEarly: false });

const ProfileSchema = Joi.object({
  fullname: Joi.string().allow(""),
  email: Joi.string()
    .email({ tlds: false })
    .required()
    .messages({
      "string.empty": genericMessages.string.empty,
      "string.email": genericMessages.string.email,
    }),
  location: Joi.string().allow(""),
  githubUrl: Joi.string()
    .uri()
    .allow("")
    .pattern(/github.com/)
    .messages({
      "string.uri": genericMessages.string.uri,
      "string.pattern.base": "URL isn't on github.com.",
    }),
  linkedinUrl: Joi.string()
    .uri()
    .allow("")
    .pattern(/linkedin.com/)
    .messages({
      "string.uri": genericMessages.string.uri,
      "string.pattern.base": "URL isn't on linkedin.com.",
    }),
  websiteUrl: Joi.string()
    .uri()
    .allow("")
    .messages({
      "string.uri": genericMessages.string.uri,
    }),
  isPublic: Joi.bool(),
  workStatus: Joi.string().allow(""),
  universityName: Joi.string().allow(""),
  companyName: Joi.string().allow(""),
  jobTitle: Joi.string().allow(""),
  timezone: Joi.string().allow(""),
});

export const validateProfile = values =>
  ProfileSchema.validate(values, { abortEarly: false });

const AccountPasswordResetSchema = Joi.object({
  old_password: passwordSchema,
  new_password1: passwordSchema,
  new_password2: Joi.string()
    .required()
    .valid(Joi.ref("new_password1"))
    .messages({
      "string.empty": "Password may not be blank.",
      "any.only": "Two passwords that you entered don't match",
    }),
});

export const validateAccountPasswordReset = values =>
  AccountPasswordResetSchema.validate(values, { abortEarly: false });
