// @flow
import type { Node } from "react";
import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import DqLoading from "components/common/feedback/DqLoading";
import DqLogo from "components/common/brand/DqLogo";
// $FlowFixMe
import signupLoginImage from "images/user_stories/Signup - Login.jpeg";

import {
  useValuePropositionsLoginQuery,
  useValuePropositionsSignupQuery,
} from "queries/valuePropositions/hooks";

type Props = {
  defaultValuePropositions: {
    backgroundImageUrl: string,
    backgroundImageAltText: string,
    backgroundImageHeader: string,
    backgroundImageSubheader: string,
    imageOverlay: boolean,
    hideLogo: boolean,
  },
  signUp: boolean,
  children: Node,
};

const AuthLayout = ({
  defaultValuePropositions,
  signUp = false,
  children,
}: Props): Node => {
  const { loading, data } = useValuePropositionsLoginQuery({
    fetchPolicy: "network-only",
    skip: signUp, // Do not fetch data if fetching sign up value props
  });
  const {
    loading: loadingSignup,
    data: dataSignup,
  } = useValuePropositionsSignupQuery({
    fetchPolocy: "network-only",
    skip: !signUp, // Do not fetch data if fetching login value props
  });

  const [valuePropositions, setValuePropositions] = useState({
    backgroundImageUrl: signupLoginImage,
    backgroundImageAltText: "Sign up/login image",
    backgroundImageHeader: "",
    backgroundImageSubheader: "",
    hideLogo: true,
    imageOverlay: false,
    ...defaultValuePropositions,
  });

  // Update login value proposition
  useEffect(() => {
    if (data?.login_value_proposition) {
      setValuePropositions({
        backgroundImageUrl: data.login_value_proposition.image_url,
        backgroundImageAltText: data.login_value_proposition.alt_text,
        backgroundImageHeader: data.login_value_proposition.header,
        backgroundImageSubheader: data.login_value_proposition.subheader,
        hideLogo: data.login_value_proposition.hide_logo,
        imageOverlay: !data.login_value_proposition.image_url,
      });
    }
  }, [data]);

  // Update sign up value propositions
  useEffect(() => {
    if (dataSignup?.signup_value_proposition) {
      setValuePropositions({
        backgroundImageUrl: dataSignup.signup_value_proposition.image_url,
        backgroundImageAltText: dataSignup.signup_value_proposition.alt_text,
        backgroundImageHeader: dataSignup.signup_value_proposition.header,
        backgroundImageSubheader: dataSignup.signup_value_proposition.subheader,
        hideLogo: dataSignup.signup_value_proposition.hide_logo,
        imageOverlay: !dataSignup.signup_value_proposition.image_url,
      });
    }
  }, [dataSignup]);

  const backgroundImageStyle = useMemo(
    () =>
      valuePropositions.imageOverlay
        ? `linear-gradient(143deg, rgba(37,56,104,.9) 0%, rgba(78,126,222,.9) 100%), url('${valuePropositions.backgroundImageUrl}')`
        : `url('${valuePropositions.backgroundImageUrl}')`,
    [valuePropositions],
  );

  if (loading || loadingSignup) return <DqLoading fullScreen />;

  return (
    <div className="dq-flex dq-min-h-screen">
      <div
        className="dq-relative dq-hidden dq-bg-cover dq-bg-right dq-text-center lg:dq-flex lg:dq-w-1/2 lg:dq-items-center lg:dq-justify-center xl:dq-bg-center"
        style={{
          backgroundImage: backgroundImageStyle,
          backgroundBlendMode: "multiply",
        }}
      >
        <div className="dq-flex dq-items-center dq-justify-center dq-flex-col">
          <img
            data-test-selector="authLayoutImg"
            src={valuePropositions.backgroundImageUrl}
            style={{ width: 0 }}
            alt={valuePropositions.backgroundImageAltText}
          />

          {!valuePropositions.hideLogo && (
            <DqLogo
              className={cn("dq-h-16 dq-w-auto dq-text-white", {
                "dq-mb-4":
                  valuePropositions.backgroundImageHeader ||
                  valuePropositions.backgroundImageSubheader,
              })}
              data-test-selector="authLayoutDqLogo"
            />
          )}
          {valuePropositions.backgroundImageHeader && (
            <h2
              className={cn("dq-text-gray-50", {
                "dq-mb-2": valuePropositions.backgroundImageSubheader,
              })}
            >
              {valuePropositions.backgroundImageHeader}
            </h2>
          )}
          {valuePropositions.backgroundImageSubheader && (
            <h4 className="dq-text-xl dq-text-gray-50">
              {valuePropositions.backgroundImageSubheader}
            </h4>
          )}
        </div>
      </div>
      <div className="dq-flex dq-items-center dq-justify-center dq-w-full dq-flex-col lg:dq-w-1/2">
        <DqLogo className="dq-w-52 dq-mx-auto dq-mb-12 dq-text-gray-800 lg:dq-hidden" />
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
